import batteryIcon from "assets/devices-icons/battery.png";
import rayIcon from "assets/devices-icons/sensor.png";
import soapIcon from "assets/devices-icons/soap-dispenser.png";
import tempIcon from "assets/devices-icons/temp-sensor.png";
import thermalIcon from "assets/devices-icons/thermal.png";
import toiletPIcon from "assets/devices-icons/toilet-paper-dispenser.png";
import towelIcon from "assets/devices-icons/towelDispenser.png";
import checkIcon from "assets/icons/check.svg";
import collapseIcon from "assets/icons/collapse.svg";
import historyIcon from "assets/icons/history.svg";
const icons = {
  batteryIcon,
  soapIcon,
  tempIcon,
  toiletPIcon,
  checkIcon,
  collapseIcon,
  towelIcon,
  rayIcon,
  thermalIcon,
  historyIcon,
};

export const getIcon = (iconName) => icons?.[iconName] ?? icons.checkIcon;
const iconMap = {
  battery: "batteryIcon",
  collapse: "collapseIcon",
  "toilet-paper-dispenser": "toiletPIcon",
  "towel-dispenser": "towelIcon",
  "soap-dispenser": "soapIcon",
  "temp-sensor": "tempIcon",
  counter: "rayIcon",
  ray: "rayIcon",
  thermal: "thermalIcon",
};

export function getIconByDeviceType(deviceType) {
  if (deviceType in iconMap) {
    return icons?.[iconMap[deviceType]];
  } else {
    return checkIcon;
  }
}
