import { FlagIcon } from "@heroicons/react/20/solid";
import Backdrop from "components/UI/Modals/Backdrop";
import { useEffect, useState } from "react";
import {
  handleSaveTaskChanges,
  onPriorityChange,
  onTaskStatusChange,
  toggleClosed,
} from "utils/functions/taskDetailedHandlers";
import { categories, taskPriorities, task_statuses, tasks, users_roles } from "../../dummyData";
import { useUpdateUserMutation } from "../../features/api/usersSlice";
import { Task, UserType } from "../../types/typings";
import { Option } from "../../types/ui-types";
import { filterByKeys } from "../../utils/helpers";
import TaskDetailed from "../Task/TaskDetailed";
import BtnPrimary from "../UI/Buttons/BtnPrimary";
import CheckBox from "../UI/CheckBox";
import { initialData, taskData } from "../UI/Modals/TaskModal";
import SearchBar from "../UI/SearchBar";
import SearchTabs from "../UI/SearchTabs";
import CategorySelect from "../UI/Selects/CategorySelect";
import SettingsAboutHeader from "./SettingsAboutHeader";

type Props = {
  show: boolean;
  closeModal: () => void;
  userData: UserType;
};
const searchAssigned = (id: string) =>
  Object.values(tasks.value)
    .flat()
    .filter((task: Task) => task.asigneeList.includes(parseInt(id)));
export default function UserSettingsEditModal({ userData, show, closeModal }: Props) {
  const [filterRole, setFilterRole] = useState<Option>(
    users_roles.find((role) => userData.role === role.value) || users_roles.at(-1) || users_roles[0]
  );
  const [updateUser] = useUpdateUserMutation();

  const [tab, setTab] = useState<Option>(task_statuses[0]);
  const [searchStr, setSearchStr] = useState<string>("");
  const [isPicked, setIsPicked] = useState<boolean>(false);
  const [assignedTasks, setAssignedTasks] = useState<Task[]>(searchAssigned(userData.id));
  const clearModalState = () => {
    taskData.value = initialData;
    setSearchStr("");
    setTab(task_statuses[0]);
  };
  useEffect(() => {
    setAssignedTasks(
      Object.values(tasks.value)
        .flat()
        .filter((task: Task) => task.asigneeList.includes(parseInt(userData.id)))
    );
    clearModalState();
    setFilterRole(users_roles.find((role) => userData.role === role.value) || users_roles[0]);
  }, [userData]);
  useEffect(() => {
    setAssignedTasks(searchAssigned(userData.id));
  }, [tasks.value]);
  const filteredTasks = filterByKeys(
    searchStr,
    ["title", "description"],
    assignedTasks.filter((task) => task.task_status === tab.value)
  );
  const handleSaveClick = async () => {
    await updateUser({ ...userData, role: filterRole.value as string });

    closeModal();
    clearModalState();
  };

  return (
    <Backdrop show={show} closeModal={closeModal}>
      <div className={`flex w-max transition-all  gap-4  `}>
        <div className="flex-shrink-0 blurred-dark rounded-3xl w-[500px]">
          <SettingsAboutHeader item={userData} hideDrop={true} />
          <div className="p-4 pb-6 border-b border-b-white flex flex-col w-full gap-2">
            <p>Change status</p>
            <CategorySelect
              category={filterRole}
              setCategory={setFilterRole}
              options={users_roles}
            />
          </div>
          <div className="py-6 p-4">
            <SearchTabs tabs={task_statuses} value={tab.value} setTab={setTab} />
          </div>
          <div className="w-full px-4 pb-4 grid grid-cols-[1fr,max-content] gap-4">
            <div className="px-2 w-full blurred-light select rounded-2xl">
              <SearchBar placeholder="Search tasks..." value={searchStr} setValue={setSearchStr} />
            </div>
            <CategorySelect category={categories[0]} setCategory={() => {}} />
          </div>
          <div className="flex flex-col h-[260px] overflow-y-auto px-4 gap-3">
            {filteredTasks?.length > 0 ? (
              filteredTasks.map((task) => (
                <div
                  onClick={() => {
                    setIsPicked(true);
                    taskData.value = task;
                  }}
                  key={task.id}
                  className={`grid grid-cols-[max-content,1fr,max-content] p-3 gap-4 blurred-dark task rounded-xl   `}>
                  <CheckBox
                    title={"Select Task"}
                    id={task.id}
                    isChecked={false}
                    toggleSelect={() => {}}
                  />
                  <p className="ml-1 whitespace-nowrap text-ellipsis overflow-hidden text-left">
                    {task.title}
                  </p>
                  <FlagIcon
                    className={`w-4 self-center ${
                      taskPriorities?.[task.priority] ?? "text-green-400"
                    }`}
                  />
                </div>
              ))
            ) : (
              <p className="text-center">No assigned tasks</p>
            )}
          </div>
          <div className="flex-shrink-0 p-4">
            <BtnPrimary onClick={handleSaveClick} className="w-full">
              Save Changes
            </BtnPrimary>
          </div>
        </div>
        <div
          style={{
            opacity: isPicked ? 1 : 0,
            maxWidth: isPicked ? "900px" : "0",
            // Initial max-width set to 0
            transition: isPicked
              ? "max-width 0.5s ease, opacity 0.05s 0.3s ease"
              : "max-width 0.5s ease, opacity 0.5s  ease",
          }}
          className="w-[500px] h-auto  overflow-x-hidden whitespace-nowrap">
          <TaskDetailed
            taskData={taskData.value}
            handleClose={() => {
              setIsPicked(false);
              handleSaveTaskChanges();
              taskData.value = initialData;
            }}
            toggleClosed={toggleClosed}
            onTaskStatusChange={onTaskStatusChange}
            onTaskPriorityChange={onPriorityChange}
          />
        </div>
      </div>
    </Backdrop>
  );
}
