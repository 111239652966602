type Props = {
  category: string;
  model: string;
};

export default function DeviceCatAndType({ category, model }: Props) {
  return (
    <>
      <p className="text-white/70">Category</p>
      <p className="capitalize">{category}</p>
      <p className="text-white/70">Type</p>
      <p className="capitalize">{model}</p>
    </>
  );
}
