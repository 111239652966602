import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { Option } from "../../../types/ui-types";

type Props = {
  placeholder?: string;
  options: Option[];
  option: Option | null;
  onOptionChange: Dispatch<SetStateAction<Option>>;
};
export default function ComboboxTemplate({ placeholder, options, option, onOptionChange }: Props) {
  const [query, setQuery] = useState("");

  const filteredPeople =
    query === ""
      ? options
      : options.filter((person) =>
          person.label
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <Combobox value={option} onChange={onOptionChange}>
      <div className="relative mt-1">
        <div className="relative w-full cursor-default overflow-hidden rounded-2xl blurred-light select text-left  focus:outline-none  sm:text-sm">
          <Combobox.Input
            className="w-full border-none py-[14px] px-6 text-sm leading-5 text-whie focus:ring-0 outline-none bg-transparent"
            displayValue={(person: any) => person?.label ?? ""}
            onChange={(event) => setQuery(event.target.value)}
            placeholder={placeholder ?? "Choose Type"}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}>
          <Combobox.Options className="absolute mt-2 max-h-60 w-full overflow-auto rounded-2xl bg-gray-500/90  text-base focus:outline-none sm:text-sm text-white z-10">
            {filteredPeople.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none py-2 px-4 text-white">
                Nothing found.
              </div>
            ) : (
              filteredPeople.map((option) => (
                <Combobox.Option
                  key={`${option.label}_${option.value}`}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-8 pr-4 ${
                      active ? "bg-white/20 " : ""
                    }`
                  }
                  value={option}>
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
                        {option.label}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? "text-white" : ""
                          }`}>
                          <CheckIcon className="h-4 w-4" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
