import { CounterSensor } from "../../types/typings";
import { getIconByDeviceType } from "../../utils/iconGetter";
import DeviceBadge from "./DeviceBadge";

type Props = {
  device: CounterSensor;
};

export default function DeviceCounterSensor({ device }: Props) {
  return (
    <DeviceBadge model={device.__model}>
      <img
        className="w-full h-auto object-contain"
        src={getIconByDeviceType(device?.__model)}
        alt={device.name}
      />

      {/* <EntriesExits
        entries={device.statistics?.entries as number}
        exits={device.statistics?.exits as number}
      /> */}
    </DeviceBadge>
  );
}
