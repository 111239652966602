import { createSlice } from "@reduxjs/toolkit";
import { initial_header_tabs } from "../components/Header/header_tabs";
import { initialStatBlocks } from "../components/Widgets/widgets";
import { Device as DeviceType, GroupType } from "../types/typings";
import { HeaderTabType, WidgetType } from "../types/ui-types";

export interface UiState {
  header_tabs: HeaderTabType[];
  widgets: WidgetType[];
  pickedDevice: DeviceType | null;
  pickedArea: GroupType | null;
  snackbar:
    | undefined
    | {
        message: string;
        type?: string;
      };
}
export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    header_tabs: [...initial_header_tabs],
    widgets: [...initialStatBlocks],
    pickedDevice: null,
    pickedArea: null,
    snackbar: undefined,
  } as UiState,
  reducers: {
    showSnackBar(state, action) {
      const { type, message } = action.payload;
      state.snackbar = { type, message };
    },
    changeHomeTabName(state, action) {
      state.header_tabs = [...state.header_tabs]?.map((tab) =>
        tab.id === "home" ? { ...tab, label: action?.payload ?? tab.label } : tab
      );
    },
    changeHeaderTabs(state, action) {
      state.header_tabs = [...action.payload];
    },
    changeWidgetTab(state, action) {
      state.widgets = [...state.widgets].map((widget: any) =>
        widget.id === action?.payload?.id ? { ...widget, data: action.payload.data } : widget
      );
    },
    changeWidgets(state, action) {
      state.widgets = [...action.payload];
    },
    pickDeviceFromSearch(state, action) {
      if (state.pickedDevice?.id !== action.payload?.id) {
        state.pickedDevice = action.payload;
        state.pickedArea = null;
        return;
      } else {
        state.pickedDevice = null;
      }
    },
    pickArea(state, action) {
      if (state.pickedArea?.id !== action.payload?.id) {
        state.pickedArea = action.payload;
        state.pickedDevice = null;
        return;
      } else {
        state.pickedArea = null;
      }
    },
  },
});

export const {
  changeHeaderTabs,
  changeWidgets,
  pickDeviceFromSearch,
  pickArea,
  changeHomeTabName,
  changeWidgetTab,
  showSnackBar,
} = uiSlice.actions;
