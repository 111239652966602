import { taskData } from "../UI/Modals/TaskModal";

export default function TaskDescription() {
  return (
    <textarea
      value={taskData.value.description}
      onChange={(e) => {
        taskData.value = { ...taskData.value, description: e.target.value };
      }}
      placeholder="Write some task description..."
      rows={4}
      className="w-full rounded-xl border border-gray-500 bg-transparent caret-sakura-100 text-sm ring-none outline-none focus:outline-none py-3 px-4"></textarea>
  );
}
