import { Key, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Option } from "types/ui-types";
import { bigAddItems } from "../../../dummyData";
import Dropdown from "./DropdownTemplate";

type Props = {
  category?: Option;
  setCategory?: React.Dispatch<React.SetStateAction<Option>>;
};
function BigAddButton() {
  return (
    <button className=" h-14 w-14 flex justify-center items-center transition-all  rounded-2xl bg-sakura-100 text-sakura-900  hover:bg-sakura-900 hover:text-sakura-100 group">
      <svg
        className="fill-current transition-all group-hover:rotate-90"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none">
        <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="current" />
      </svg>
    </button>
  );
}
export default function BigAddDropdown({ category, setCategory }: Props) {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  return (
    <div className="w-max">
      <Dropdown
        collapse={!expanded}
        toggleMenu={(val: boolean) => {
          setExpanded(val);
        }}
        triggerEl={<BigAddButton />}
        direction="up">
        <div className="flex flex-col">
          {bigAddItems.map((option) => (
            <Link
              to={option?.to ?? "/"}
              className="py-2.5 px-2.5 cursor-pointer hover:bg-white/5"
              key={option?.value as Key}
              onClick={() => {
                setExpanded(false);
              }}>
              {option.label}
            </Link>
          ))}
        </div>
      </Dropdown>
    </div>
  );
}
