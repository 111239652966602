import Logo from "assets/ismart-logo.svg";
import Header from "components/Header/Header";
import BigAddDropdown from "components/UI/Dropdowns/BigAddDropdown";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import { AllViews } from "../components/Models/AllViews";
import LocationsDropdown from "../components/UI/Dropdowns/LocationsDropdown";
import SnackBar from "../components/UI/SnackBar/SnackBar";
import {
  ADD_HEADER_TABS_PAGE,
  ADD_NEW_DEVICE_PAGE,
  ADD_WIDGETS_PAGE,
  DEVICE_CATALOGUE_PAGE,
  SETTINGS_PAGE,
} from "../constants/routes";
import { getIcon } from "../utils/iconGetter";
import styles from "./MainLayout.module.scss";
const commonPages = [ADD_NEW_DEVICE_PAGE, ADD_HEADER_TABS_PAGE, ADD_WIDGETS_PAGE, SETTINGS_PAGE];
export default function MainLayout() {
  const dispatch = useDispatch();
  const page_location = useLocation();
  const locationRoute = `/${page_location.pathname.substring(1).split("/")[0]}`;
  const showDevCat = !commonPages.includes(locationRoute);
  const showPlus = ![...commonPages, DEVICE_CATALOGUE_PAGE].includes(locationRoute);
  const { picked_location } = useSelector((state) => state.auth);
  const deviceCatLink =
    page_location.pathname === DEVICE_CATALOGUE_PAGE ? "/" : DEVICE_CATALOGUE_PAGE;

  const backHome = [DEVICE_CATALOGUE_PAGE, SETTINGS_PAGE].includes(page_location.pathname);

  const resetBtnClick = () => {
    const resetBtn = document.getElementById("reset-position");
    if (resetBtn) {
      resetBtn.click();
    }
  };

  useEffect(() => {
    console.log("Layout Rendered");
  }, []);

  return (
    <div
      className={`${styles.mainLayout} ${
        ![DEVICE_CATALOGUE_PAGE].includes(page_location.pathname) ? "pointer-events-none" : ""
      }`}>
      <img
        className="w-24 absolute top-4 left-4  contrast-0 grayscale invert"
        src={Logo}
        alt="ISmart"
      />
      <svg className="w-0 h-0 absolute top-0 left-0 pointer-events-none">
        <filter id="round">
          <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 19 -9"
            result="goo"
          />
          <feComposite in="SourceGraphic" in2="goo" operator="atop" />
        </filter>
        <filter id="round2">
          <feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 19 -9"
            result="goo"
          />
          <feComposite in="SourceGraphic" in2="goo" operator="atop" />
        </filter>
      </svg>
      <AllViews />
      {true && (
        <div
          className={`${styles.container} ${
            ![DEVICE_CATALOGUE_PAGE].includes(page_location.pathname) ? "pointer-events-none" : ""
          }`}>
          <div className={`${styles.innerLayout} pointer-events-none`}>
            <Header />
            <div
              className={`OUTLET flex-grow h-1/2 relative ${
                backHome ? "" : "pointer-events-none"
              }`}>
              <Outlet></Outlet>
            </div>
            {showDevCat && (
              <div className="absolute z-10 bottom-0 left-0">
                <Link
                  to={deviceCatLink}
                  className="w-max rounded-2xl blurred-light pointer-events-auto z-20 flex gap-2 p-4  ">
                  Device Catalogue <img src={getIcon("collapseIcon")} alt="collapse" />
                </Link>
              </div>
            )}
            <button
              style={{ zIndex: 10, position: "absolute" }}
              id="reset-position-button"
              onClick={resetBtnClick}
              className="absolute bottom-20 left-0  z-30 pointer-events-auto blurred-light select p-4 rounded-2xl ">
              Reset position
            </button>
            <div className="flex absolute bottom-3 right-3 gap-3 items-center justify-end w-max pointer-events-auto z-10">
              {showPlus && <LocationsDropdown />}
              {showPlus && <BigAddDropdown />}
            </div>
          </div>
        </div>
      )}
      <SnackBar />
    </div>
  );
}
