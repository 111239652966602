import { Device, ThermalSensor } from "../types/typings";

export const getFillLevel = ({
  min,
  max,
  measurment,
}: {
  min: number;
  max: number;
  measurment: number;
}) => {
  return ((1 - Math.min((measurment - max) / (min - max), 1)) * 100).toFixed(0);
};
function isThermalSensor(obj: any): obj is ThermalSensor {
  return (
    obj &&
    obj.statistics &&
    obj.statistics.last_occupied_on !== undefined &&
    obj.statistics.total_occupancy !== undefined
  );
}
export const getGroupDevicesStats = (devices: Device[]) => {
  const gatheredData: {
    last_occupied_on?: Date;
    total_occupancy?: number;
    total_heat_signatures?: number;
  } = {};
  devices?.forEach((d: any) => {
    if (isThermalSensor(d)) {
      gatheredData.last_occupied_on = d.statistics.last_occupied_on;
      gatheredData.total_occupancy = d.statistics.total_occupancy;
      gatheredData.total_heat_signatures = d.statistics.blobs.length;
    }
  });
  return gatheredData;
};
const qualityRanges = [
  { min: 230, max: 255, category: "excellent" },
  { min: 190, max: 229, category: "good" },
  { min: 120, max: 189, category: "moderate" },
  { min: 20, max: 119, category: "poor" },
];
export function checkInstallQualityRange(installationQuality: number) {
  for (const range of qualityRanges) {
    if (installationQuality >= range.min && installationQuality <= range.max) {
      return range.category;
    }
  }
  return "no signal"; // Return unknown if the quality is outside defined ranges
}
