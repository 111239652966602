import { createSlice } from "@reduxjs/toolkit";
import { Device } from "../types/typings";

interface AreaStateInterface {
  editMode: boolean;
  isDotDragging: boolean;
  areaId: string | null;
  dots: { id: string; x: number; y: number; z: number }[];
  devicesInside: Device[];
}

export const areaSlice = createSlice({
  name: "area",
  initialState: {
    editMode: false,
    areaId: null,
    isDotDragging: false,
    dots: [],
    devicesInside: [],
  } as AreaStateInterface,
  reducers: {
    switchIsDotDragging(state, action) {
      state.isDotDragging = action.payload;
    },
    switchEditMode(state, action) {
      const { areaId } = action.payload || {};
      if (areaId) {
        state.areaId = areaId;
        state.editMode = true;
      } else {
        state.editMode = action.payload;
      }
    },
    setDots(state, action) {
      state.dots = action.payload;
    },
    setDevicesInside(state, action) {
      state.devicesInside = action.payload;
    },
    saveArea(state, action) {
      state.dots = [];
      state.editMode = false;
      state.isDotDragging = false;
      state.areaId = null;
    },
  },
});
export const { setDots, switchEditMode, switchIsDotDragging, saveArea, setDevicesInside } =
  areaSlice.actions;
