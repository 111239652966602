import { useThree } from "@react-three/fiber";
import { useDrag } from "@use-gesture/react";
import { ReactElement, RefObject, useRef, useState } from "react";
import * as THREE from "three";
import { BufferGeometry, Material, Mesh, NormalBufferAttributes, Object3DEventMap } from "three";

type Props = {
  children: ReactElement | ReactElement[];
  position: number[];
  item: any;
  handleUpdate: (pos: any) => void;
  handleDragStart: (boolean: boolean) => void;
};
export default function DraggableInstance({
  position,
  item,
  children,
  handleUpdate,
  handleDragStart,
}: Props) {
  const { camera, raycaster, mouse, scene, setFrameloop, controls } = useThree();
  const [pos, setPos] = useState(new THREE.Vector3(...position));

  const meshRef: RefObject<
    Mesh<BufferGeometry<NormalBufferAttributes>, Material | Material[], Object3DEventMap>
  > =
    useRef<Mesh<BufferGeometry<NormalBufferAttributes>, Material | Material[], Object3DEventMap>>(
      null
    );

  const bind = useDrag(
    async (state) => {
      const { active, timeStamp, dragging: dragDragging, tap, timeDelta } = state || {};
      if (!timeDelta && !tap) {
        console.log("DRAG START");
        handleDragStart(true);
      }

      if (active) {
        setFrameloop("always");
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(scene.children);

        if (intersects?.length > 0) {
          for (let i = 0; i < intersects.length; i++) {
            if (intersects[i].object.name) {
              const { x, y, z } = intersects[i].point;
              // meshRef.current?.position.set(
              //   parseFloat(x.toFixed(6)),
              //   pos.y,
              //   parseFloat(z.toFixed(6))
              // );
              setPos(new THREE.Vector3(parseFloat(x.toFixed(6)), pos.y, parseFloat(z.toFixed(6))));
              handleUpdate(
                new THREE.Vector3(parseFloat(x.toFixed(6)), pos.y, parseFloat(z.toFixed(6)))
              );
              break; // No need to continue the loop if we found a ground wall
            }
          }
        }
      }
      setFrameloop("demand");
      if (dragDragging) {
        //@ts-ignore
        if (controls?.enablePan) controls.enablePan = false;
      }
      if (!dragDragging) {
        //@ts-ignore
        controls.enablePan = true;
        handleDragStart(false);
        console.log("not dragging");

        setFrameloop("demand");
      }

      return timeStamp;
    },
    { delay: false, filterTaps: true }
  );

  return (
    //@ts-ignore
    <mesh userData={{ id: item.id }} ref={meshRef} {...bind()} position={pos}>
      {children}
    </mesh>
  );
}
