import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useGetDevicesQuery } from "../../features/api/deviceSlice";
import { getStateLocationID } from "../../features/authSlice";
import { changeWidgetTab } from "../../features/uiSlice";
import useTabFocus from "../../hooks/useTabFocus";
import { Device as DeviceType } from "../../types/typings";
import DeviceInstance from "../DeviceInstance";
type Props = {};
const emptyArray: DeviceType[] = [];
export default function DevicesLayer({}: Props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const windowFocus = useTabFocus();
  const locationID = useSelector(getStateLocationID);
  const { devices, isLoading, isSuccess } = useGetDevicesQuery(`${locationID}_getDevices`, {
    selectFromResult: ({ data, isLoading, isSuccess }) => ({
      devices: data ?? emptyArray,
      isLoading,
      isSuccess,
    }),
    pollingInterval: 5000,
    skip: !windowFocus || location.pathname.includes("device-catalogue"),
  });
  useEffect(() => {
    if (devices) {
      dispatch(changeWidgetTab({ id: "widget-devices", data: devices?.length }));
    }
  }, [devices?.length]);
  return (
    !isLoading &&
    isSuccess &&
    devices.map((device: DeviceType, i: number) => {
      return <DeviceInstance key={device.id} id={device.id} />;
    })
  );
}
