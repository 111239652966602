import { EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { filteredDevicesList, selectedDevicesIds } from "./DeviceCatalogue";

type Props = {};

export default function DeviceListActions({}: Props) {
  return (
    <div className="flex gap-4 w-full">
      <button
        onClick={() => {
          selectedDevicesIds.value = filteredDevicesList.value.map((device) => device.id);
        }}
        className="flex items-center gap-2 hover:bg-white/10 rounded-xl text-xs p-3 px-4 ">
        <div className="w-6 h-6 bg-sakura-100 rounded-full"></div>
        Select All
      </button>
      <button className="flex items-center gap-2 hover:bg-white/10 rounded-xl text-xs p-3 flex-grow justify-center">
        <PencilIcon className="w-6 text-white" />
        Edit
      </button>
      <button className="flex items-center gap-2 hover:bg-white/10 rounded-xl text-xs p-3 flex-grow justify-center">
        <EyeIcon className="w-6 text-white" />
        Show on map
      </button>
      <button className="flex items-center gap-2 hover:bg-white/10 rounded-xl text-xs p-3 flex-grow justify-center">
        <TrashIcon className="w-6 text-white" />
        Delete
      </button>
    </div>
  );
}
