import { useSelector } from "react-redux";
import { useGetGroupsQuery } from "../../features/api/groupSlice";
import { getStateLocationID } from "../../features/authSlice";
import { GroupType } from "../../types/typings";
import DevicesArea from "./DevicesArea";

type Props = {};

export default function GroupsLayer({}: Props) {
  const { areaId } = useSelector((state: any) => state.area);
  const locationID = useSelector(getStateLocationID);
  const {
    data: groups,
    isLoading: isGroupsLoading,
    isSuccess,
  } = useGetGroupsQuery(`${locationID}_getGroups`);
  // console.log(groups, locationID);
  return (
    !isGroupsLoading &&
    isSuccess &&
    groups?.length &&
    groups.map((area: GroupType) =>
      area.id.toString() !== areaId ? <DevicesArea key={area.id} area={area} /> : null
    )
  );
}
