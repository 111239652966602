import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  MouseSensor,
  TouchSensor,
  defaultDropAnimation,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { all_header_tabs } from "../components/Header/header_tabs";
import ExistingMenuItems from "../components/HeaderTabsPageDnD/ExistingMenuItems";
import MenuSortableItem from "../components/HeaderTabsPageDnD/MenuSortableItem";
import OtherMenuItems from "../components/HeaderTabsPageDnD/OtherMenuItems";
import BtnOutlined from "../components/UI/Buttons/BtnOutlined";
import BtnPrimary from "../components/UI/Buttons/BtnPrimary";
import { changeHeaderTabs } from "../features/uiSlice";
import { HeaderTabType } from "../types/ui-types";
import { extractDragDetails } from "../utils/helpers";

export default function AddHeaderTabs() {
  const header_tabs: HeaderTabType[] = useSelector((state: any) => state.ui.header_tabs);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [draggedItem, setDraggedItem] = useState<HeaderTabType | null>(null);

  const [existingTabs, setExistingTabs] = useState<HeaderTabType[]>(header_tabs);
  const header_tabs_ids = [...existingTabs].map((tab) => tab.id);
  const [otherTabs, setOtherTabs] = useState<HeaderTabType[]>(
    all_header_tabs.filter((tab) => !header_tabs_ids.includes(tab.id))
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lists = [
    {
      id: "existing_menu_items",
      list: existingTabs,
      setState: setExistingTabs,
    },
    {
      id: "other_menu_items",
      list: otherTabs,
      setState: setOtherTabs,
    },
  ];
  const handleDragOver = (event: DragOverEvent) => {
    const { sourceContainerId, destContainerId, activeIdx, overIdx } = extractDragDetails(event);
    const source = lists.find((list) => list.id === sourceContainerId);
    const destination = lists.find((list) => list.id === destContainerId);
    if (!source || !destination || sourceContainerId === destContainerId) return;

    const updatedSourceList = [...source.list];
    const draggedItem = updatedSourceList.splice(activeIdx, 1)[0];

    destination.setState((prevList) => {
      const updatedDestList = [...prevList];
      updatedDestList.splice(overIdx, 0, draggedItem);
      return updatedDestList;
    });

    source.setState(updatedSourceList);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { sourceContainerId, destContainerId, activeIdx, overIdx } = extractDragDetails(event);
    const source = lists.find((list) => list.id === sourceContainerId);
    const destination = lists.find((list) => list.id === destContainerId);
    if (!source || !destination || sourceContainerId !== destContainerId) return;

    const updatedSourceList = [...(source?.list ?? [])];
    const updatedList = arrayMove(updatedSourceList, activeIdx, overIdx);
    source?.setState([...updatedList]);
  };
  const handleSave = () => {
    dispatch(changeHeaderTabs(existingTabs));
    navigate("/", { replace: true });
  };

  const dropAnimation: DropAnimation = {
    ...defaultDropAnimation,
  };
  return (
    <div className="w-full h-full p-3 py-4 rounded-2xl blurred-dark overflow-hidden gap-x-6 grid grid-cols-[2.5fr,1fr] pointer-events-auto z-10">
      <div className="w-full h-full overflow-hidden flex flex-col gap-3">
        <DndContext
          sensors={sensors}
          onDragStart={({ active }: DragStartEvent) => {
            const item = all_header_tabs.find((item) => item.id === (active.id as string)) ?? null;
            setDraggedItem(item);
          }}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}>
          <p className="text-white/50 text-lg ">Menu Elements</p>
          <ExistingMenuItems items={existingTabs} />
          <p className="text-white/50 text-lg ">Custom Menu Elements or Shortcuts</p>
          <div className="">
            <OtherMenuItems items={otherTabs} />
          </div>
          <DragOverlay dropAnimation={dropAnimation}>
            {draggedItem ? <MenuSortableItem tab={draggedItem} notDraggable={false} /> : null}
          </DragOverlay>
        </DndContext>
      </div>
      <div className="w-full flex flex-col items-end gap-4">
        <BtnPrimary onClick={handleSave}>Save and Exit</BtnPrimary>
        <div className="blurred-dark relative z-0 w-full h-1/2 flex-grow rounded-lg p-4 flex flex-col gap-4 ">
          <div className="header grid grid-cols-[1fr,max-content] grid-rows-2">
            <p className="font-medium">Performance</p>
            <button className="w-7 h-7 flex row-span-2  self-center rounded-md hover:bg-white/10">
              <EllipsisVerticalIcon className="w-6 h-6 m-auto  text-white/90" />
            </button>
            <p className="text-sm">Devices</p>
          </div>
          <div className="w-full h-1/2 flex-grow overflow-y-auto"></div>
          <div className="footer flex items-center justify-end gap-4">
            <BtnOutlined>Edit</BtnOutlined>
            <BtnPrimary>Add Menu Element</BtnPrimary>
          </div>
        </div>
      </div>
    </div>
  );
}
