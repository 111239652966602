import addIcon from "assets/icons/add.svg";
import HeaderTab from "components/UI/HeaderTab";
import { useSelector } from "react-redux";
import { HeaderTabType } from "../../types/ui-types";

export default function HeaderTabs() {
  const header_tabs: HeaderTabType[] = useSelector((state: any) => state.ui.header_tabs);
  return (
    <div className="HeaderTabs flex gap-4 items-center w-1/2 flex-grow">
      {header_tabs.map((link) => (
        <HeaderTab key={link.label} {...link} />
      ))}
      <HeaderTab id={"add"} to="/add-header-tabs" iconSrc={addIcon} label="Add" addClass="add" />
    </div>
  );
}
