import { settings } from "components/SettingsPage/settings";
import { Outlet } from "react-router-dom";
import SettingsTab from "../components/SettingsPage/SettingsTab";

export default function SettingsPage() {
  return (
    <div className="blurred-dark pointer-events-auto w-full h-full rounded-3xl py-3 px-4 grid grid-cols-[minmax(max-content,22%),1fr] gap-6 ">
      <div className="w-full h-full blurred-dark  z-[1] rounded-2xl p-3 text-sm">
        <p className="p-4">Settings</p>
        <p className="p-4">{settings[0].label}</p>
        {settings[0].items.map((item) => (
          <SettingsTab key={item.to} tab={item} />
        ))}
      </div>
      <Outlet></Outlet>
    </div>
  );
}
