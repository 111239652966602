import { WidgetType } from "types/ui-types";
import styles from "./StatBlock.module.scss";
type Props = {
  widget: WidgetType;
  isDragging?: boolean;
};
export default function WidgetBlock({ widget, isDragging }: Props) {
  const { name, label, iconSrc, data } = widget || {};
  // const { data: devices } = hook("getDevices", {
  //   selectFromResult: ({
  //     data,
  //     isError,
  //     isLoading,
  //   }: {
  //     data: any;
  //     isError: boolean;
  //     isLoading: boolean;
  //   }) => {
  //     if (data && !isLoading && !isError) {
  //       return { data };
  //     }
  //     return { data: [] };
  //   },
  // });

  return (
    <div className={`${styles.block} ${styles?.[name]} ${isDragging ? "cursor-grabbing" : ""}`}>
      <span className={styles.label}>{label}</span>
      {data && <span className={styles.data}>{data}</span>}
      <div className={styles.icon}>
        <img className="h-full w-auto" src={iconSrc} alt={label} />
      </div>
    </div>
  );
}
