import { useMemo, useState } from "react";
import { users_roles_filter } from "../../dummyData";
import { UserType } from "../../types/typings";
import { Option } from "../../types/ui-types";
import { filterByCategory, filterByKeys } from "../../utils/helpers";
import SearchBar from "../UI/SearchBar";
import CategorySelect from "../UI/Selects/CategorySelect";
import SettingsListItem from "./SettingsListItem";

type Props = {
  list: UserType[];
  isLoading: boolean;
  isError: boolean;
  onItemClick: (item: any) => void;
  selectedItemId: string;
};

export default function SettingsSearchList({
  list,
  isLoading,
  isError,
  onItemClick,
  selectedItemId,
}: Props) {
  const [searchStr, setSearchStr] = useState<string>("");
  const [filterRole, setFilterRole] = useState<Option>(users_roles_filter[0]);
  const filtered = useMemo(
    () =>
      filterByKeys(
        searchStr,
        ["firstName", "lastName"],
        filterByCategory(filterRole.value as string, "role", list ?? [])
      ),
    [searchStr, list, filterRole.value]
  );

  return (
    <div className="w-full h-full overflow-hidden flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div className="blurred-light select px-2 rounded-2xl w-full">
          <SearchBar value={searchStr} setValue={setSearchStr} placeholder={"Search users"} />
        </div>
        <div className="w-max flex-shrink-0">
          <CategorySelect
            category={filterRole}
            setCategory={setFilterRole}
            options={users_roles_filter}
          />
        </div>
      </div>
      <div className="w-full h-1/2 flex-grow overflow-y-auto flex flex-col gap-3">
        {!isLoading &&
          !isError &&
          filtered?.length > 0 &&
          filtered.map((item) => (
            <SettingsListItem
              isSelected={selectedItemId === item.id}
              key={item.id}
              item={item}
              onItemClick={() => {
                onItemClick(item);
              }}
            />
          ))}

        {!isLoading && isError && (
          <p className="my-20 text-center px-10">Something Wrong with Database </p>
        )}
      </div>
    </div>
  );
}
