import AlertsPage from "pages/AlertsPage";
import NotificationsPage from "pages/NotificationsPage";
import TasksPage from "pages/TasksPage";
import { Route, Routes } from "react-router-dom";
import "./App.scss";
import PrivateRoutes from "./components/PrivateRoutes";
import TaskModal from "./components/UI/Modals/TaskModal";
import {
  ADD_HEADER_TABS_PAGE,
  ADD_NEW_DEVICE_PAGE,
  ADD_WIDGETS_PAGE,
  ALERTS_PAGE,
  CREATE_OR_EDIT_AREA_PAGE,
  DEVICE_CATALOGUE_PAGE,
  NOTIFICATIONS_PAGE,
  PICK_LOCATION_PAGE,
  RELOGIN_PAGE,
  SETTINGS_DEVICES_PAGE,
  SETTINGS_PAGE,
  SETTINGS_USERS_PAGE,
  SIGNIN_PAGE,
  TASKS_PAGE,
} from "./constants/routes";
import MainLayout from "./layouts/MainLayout";
import SignInLayout from "./layouts/SignInLayout";
import AddNewDevicePage from "./pages/AddDevicePage";
import AddHeaderTabs from "./pages/AddHeaderTabs";
import AddWidgetPage from "./pages/AddWidgetPage";
import CreateAreaPage from "./pages/CreateAreaPage";
import DeviceCataloguePage from "./pages/DeviceCataloguePage";
import EditAreaPage from "./pages/EditAreaPage";
import HomePage from "./pages/HomePage";
import LogOutPage from "./pages/LogoutPage";
import PickLocationPage from "./pages/PickLocationPage";
import ReLoginPage from "./pages/ReLoginPage";
import SettingsPage from "./pages/SettingsPage";
import SettingsDevicesPage from "./pages/SettingsPages/SettingsDevicesPage";
import SettingsUsersPage from "./pages/SettingsPages/SettingsUsersPage";
import SigninPage from "./pages/SigninPage";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={SIGNIN_PAGE} element={<SigninPage />} />
        <Route
          path={RELOGIN_PAGE}
          element={
            <SignInLayout>
              <ReLoginPage />
            </SignInLayout>
          }
        />
        <Route
          path={"/logout"}
          element={
            <SignInLayout>
              <LogOutPage />
            </SignInLayout>
          }
        />
        <Route element={<PrivateRoutes />}>
          <Route
            path={PICK_LOCATION_PAGE}
            element={
              <SignInLayout>
                <PickLocationPage />
              </SignInLayout>
            }
          />
          <Route element={<MainLayout />}>
            <Route index element={<HomePage />} />
            <Route path={NOTIFICATIONS_PAGE} element={<NotificationsPage />} />
            <Route path={ALERTS_PAGE} element={<AlertsPage />} />
            <Route path={TASKS_PAGE} element={<TasksPage />}>
              <Route path=":taskId" element={<TaskModal />} />
            </Route>
            <Route path={ADD_NEW_DEVICE_PAGE} element={<AddNewDevicePage />} />
            <Route path={ADD_HEADER_TABS_PAGE} element={<AddHeaderTabs />} />
            <Route path={ADD_WIDGETS_PAGE} element={<AddWidgetPage />} />
            <Route path={DEVICE_CATALOGUE_PAGE} element={<DeviceCataloguePage />} />
            <Route path={SETTINGS_PAGE} element={<SettingsPage />}>
              <Route path={SETTINGS_USERS_PAGE} element={<SettingsUsersPage />} />
              <Route path={SETTINGS_DEVICES_PAGE} element={<SettingsDevicesPage />} />
            </Route>
            <Route path={CREATE_OR_EDIT_AREA_PAGE} element={<CreateAreaPage />} />
            <Route path={`${CREATE_OR_EDIT_AREA_PAGE}/:areaId`} element={<EditAreaPage />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
