import styles from "./SearchTabs.module.scss";
export default function SearchTabs({ tabs, value, setTab }) {
  const handleTabClick = (tab) => {
    setTab(tab);
  };
  return (
    <div className={styles.container}>
      {tabs.map((tab) => (
        <button
          onClick={() => {
            handleTabClick(tab);
          }}
          className={`${styles.tab} ${tab.value === value ? styles.current : ""}`}
          key={tab?.value}
        >
          {tab?.iconSrc && <img src={tab.iconSrc} alt={tab.label} />}
          {tab?.label}
        </button>
      ))}
    </div>
  );
}
