import { XMarkIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import BtnBlurred from "../components/UI/Buttons/ButtonBlurred";
import {
  useDeleteGroupMutation,
  useGetGroupQuery,
  useUpdateGroupMutation,
} from "../features/api/groupSlice";
import { saveArea, setDevicesInside, setDots, switchEditMode } from "../features/areaSlice";
import { Device } from "../types/typings";
type Props = {};

export default function EditAreaPage({}: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { areaId } = useParams();
  const { dots, devicesInside } = useSelector((state: any) => state?.area);
  const { data: area, isLoading, isError } = useGetGroupQuery(areaId);
  const state = useSelector((state: any) => state?.api?.queries['getDevices("getDevices")']);
  const [updateGroup] = useUpdateGroupMutation();
  const [deleteGroup] = useDeleteGroupMutation();

  useEffect(() => {
    if (!isLoading && !isError) {
      dispatch(switchEditMode({ areaId }));
      dispatch(setDots([...area.map?.area?.dots].map((d) => ({ ...d, id: uuidv4() })) ?? []));
      dispatch(setDevicesInside(area?.devices ?? []));
    }
  }, [isLoading, isError]);
  const handleSave = async () => {
    try {
      await updateGroup({
        id: area?.id,
        metadata: [],
        name: area.name,
        map: {
          area: {
            dots: [...dots].map((d: any) => ({ x: d.x, y: d.y, z: d.z })),
            color: "#fff",
          },
        },
        devices: [...devicesInside].map((d) => d.id),
      }).unwrap();
      dispatch(saveArea(true));
      navigate("/");
    } catch (err) {
      console.error("Failed to update AREA ", err);
      alert("Failed to update AREA");
    }
  };

  const handleCancelClick = () => {
    dispatch(saveArea(true));
    navigate("/");
  };
  const handleDeleteClick = async () => {
    try {
      await deleteGroup(areaId!).unwrap();
      dispatch(saveArea(true));
      navigate("/");
    } catch (err) {
      console.error("Failed to delete AREA ", err);
      alert("Failed to delete AREA");
    }
  };
  return (
    <div className="blurred-light w-1/3 min-w-[300px] max-w-[450px] pointer-events-auto h-full rounded-2xl p-4 flex flex-col gap-2">
      <p className="text-xl"> Name:{area?.name}</p>

      <p className="text-xl"> Dots:</p>
      <div className="flex flex-col gap-1">
        {dots.map((dot: any, i: number) => (
          <div key={`${dot.id}`} className="flex items-center">
            <span className="font-medium">{i + 1}.</span>
            <p>
              x:{dot.x} - z:{dot.z}
            </p>
            <button
              onClick={() => {
                dispatch(setDots(dots.filter((d: any) => d.id !== dot.id)));
              }}>
              <XMarkIcon className="w-4  text-white stroke-slate-50" />
            </button>
          </div>
        ))}
      </div>
      <p className="text-xl"> Devices:</p>
      <div className="flex flex-col gap-1">
        {devicesInside.map((d: Device) => (
          <div key={d.id}>
            <p>{d.name}</p>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-2 gap-2">
        <BtnBlurred
          disabled={dots?.length < 3}
          onClick={handleSave}
          className="disabled:bg-black/10">
          {dots?.length < 3 ? "Add more dots to save" : "Save"}
        </BtnBlurred>
        <BtnBlurred onClick={handleCancelClick} className="disabled:bg-black/10">
          Cancel
        </BtnBlurred>
      </div>
      <BtnBlurred onClick={handleDeleteClick}>Delete</BtnBlurred>
    </div>
  );
}
