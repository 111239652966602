import { ThermalSensorNotifications } from "../../types/typings";

export const getTemperatureTriggers = (notifications: ThermalSensorNotifications) => {
  const danger_temp = notifications?.danger_temperature?.trigger?.is_triggered;
  const high_temp = notifications?.high_temperature?.trigger?.is_triggered;
  const temperature_triggered = danger_temp
    ? notifications?.danger_temperature?.trigger?.last_triggered_value
    : notifications?.high_temperature?.trigger?.last_triggered_value;
  return { danger_temp, high_temp, temperature_triggered };
};
