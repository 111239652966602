import { useDroppable } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import Typography from "@mui/material/Typography";
import { Task } from "types/typings";
import SortableTaskItem from "../SortableTaskItem";

type BoardSectionProps = {
  id: string;
  title: string;
  tasks: Task[];
  selectedIds: string[];
  toggleSelect?: (task: Task) => void;
};

export default function TasksColumn({
  id,
  title,
  tasks,
  toggleSelect,
  selectedIds,
}: BoardSectionProps) {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    //@ts-ignore
    <div className="h-full w-full overflow-hidden">
      <Typography variant="h6" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <SortableContext id={id} items={tasks} strategy={verticalListSortingStrategy}>
        <div ref={setNodeRef} className="flex flex-col w-full overflow-y-auto h-full">
          {tasks.map((task) => (
            <SortableTaskItem
              key={task.id}
              id={task.id}
              task={task}
              selectedIds={selectedIds}
              toggleSelect={toggleSelect}
            />
          ))}
        </div>
      </SortableContext>
    </div>
  );
}
