import { XMarkIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Mesh, Raycaster, Shape, ShapeGeometry, Vector3 } from "three";
import BtnBlurred from "../components/UI/Buttons/ButtonBlurred";
import { useGetDevicesQuery } from "../features/api/deviceSlice";
import { useAddGroupMutation, useUpdateGroupMutation } from "../features/api/groupSlice";
import { saveArea, setDots, switchEditMode } from "../features/areaSlice";
import { Device } from "../types/typings";
type Props = {};
const isPointInsidePolygon = (point: any, shape: any) => {
  const geometry = new ShapeGeometry(shape);
  const raycaster = new Raycaster(new Vector3(point.x, -point.y, 0), new Vector3(0, 0, -1));
  const intersections = raycaster.intersectObject(new Mesh(geometry));
  return intersections.length % 2 === 1;
};
export default function CreateAreaPage({}: Props) {
  const [name, setName] = useState("");
  const [devicesInside, setDevicesInside] = useState<Device[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: devices, isLoading, isError } = useGetDevicesQuery("getDevices");
  const { dots } = useSelector((state: any) => state?.area);
  const [postAddGroup] = useAddGroupMutation();
  const [updateGroup] = useUpdateGroupMutation();
  useEffect(() => {
    dispatch(switchEditMode(true));
    return () => {
      dispatch(switchEditMode(false));
    };
  }, []);

  const handleSave = async () => {
    try {
      await postAddGroup({
        metadata: [],
        name: (name || devicesInside?.[0]?.name?.split(" ")?.[0]) ?? "New Area",
        map: {
          area: {
            dots: [...dots].map((d: any) => ({ x: d.x, y: d.y, z: d.z })),
            color: "",
          },
        },
        devices: [...devicesInside].map((d) => d.id),
      }).unwrap();

      dispatch(saveArea({ d: "n" }));
      setName("");
      navigate("/");
    } catch (err) {
      console.error("Failed to add group", err);
      alert("Failed to add the group");
    }
  };
  const checkForDevicesInside = () => {
    if (dots.length > 0) {
      const shape = new Shape();
      let devicesInsideTemp: Device[] = [];
      // Move to the first point
      const { x: firstX, y: firstY, z: firstZ } = dots[0];
      shape.moveTo(firstX, -firstZ); // Assuming y (z) is pointing downward

      // Line to all other points
      for (let i = 1; i < dots.length; i++) {
        const { x, y, z } = dots[i];
        shape.lineTo(x, -z); // Assuming y (z) is pointing downward
      }

      // Close the shape by connecting the last point to the first point
      shape.lineTo(firstX, -firstZ);

      // Check if each device position is inside the polygon
      devices.forEach((device: any) => {
        if (device.map.pinned) {
          const isInside = isPointInsidePolygon({ x: device.map.x, y: device.map.y }, shape);
          if (isInside) {
            devicesInsideTemp.push(device);
          }
        }
      });
      if (devicesInsideTemp?.length > 0 && !name) {
        console.log("Suggested name:", devicesInsideTemp?.[0]?.name?.split(" ")?.[0]);
        setName(devicesInsideTemp?.[0]?.name?.split(" ")?.[0]);
      }
      setDevicesInside(devicesInsideTemp);

      console.log("Devices Inside:", devicesInside);
    }
  };
  return (
    <div className="blurred-light w-1/3 min-w-[300px] max-w-[450px] pointer-events-auto h-full rounded-2xl p-4 flex flex-col gap-2">
      <p className="text-xl"> Name:</p>
      <div className=" blurred-light select rounded-2xl">
        <input
          className="w-full border-none  py-[14px] px-6 text-sm leading-5 text-whie focus:ring-0 outline-none bg-transparent"
          id="area-name"
          placeholder={"New Area"}
          type="text"
          value={name}
          onChange={(e: any) => {
            setName(e.target.value);
          }}
        />
      </div>
      <p className="text-xl"> Dots:</p>
      <div className="flex flex-col gap-1">
        {dots.map((dot: any, i: number) => (
          <div key={`${dot.id}`} className="flex items-center">
            <span className="font-medium">{i + 1}.</span>
            <p>
              x:{dot.x} - z:{dot.z}
            </p>
            <button
              onClick={() => {
                dispatch(setDots(dots.filter((d: any) => d.id !== dot.id)));
              }}>
              <XMarkIcon className="w-4  text-white stroke-slate-50" />
            </button>
          </div>
        ))}
      </div>
      <p className="text-xl"> Devices:</p>
      <div className="flex flex-col gap-1">
        {devicesInside.map((d: Device) => (
          <div key={d.id}>
            <p>{d.name}</p>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-2 gap-2">
        <BtnBlurred
          disabled={dots?.length < 3}
          onClick={handleSave}
          className="disabled:bg-black/10">
          {dots?.length < 3 ? "Add more dots to save" : "Save"}
        </BtnBlurred>
        <BtnBlurred
          onClick={() => {
            dispatch(saveArea(true));
            navigate("/");
          }}
          className="disabled:bg-black/10">
          Cancel
        </BtnBlurred>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <BtnBlurred
          disabled={dots?.length < 3}
          onClick={checkForDevicesInside}
          className="disabled:bg-black/10">
          {dots?.length < 3 ? "Add more dots to check" : "Check for devices inside"}
        </BtnBlurred>
      </div>
    </div>
  );
}
