import { taskData } from "../UI/Modals/TaskModal";

export default function TaskName() {
  return (
    <input
      value={taskData.value.title}
      onChange={(e) => {
        taskData.value = { ...taskData.value, title: e.target.value };
      }}
      placeholder="Task name"
      className="w-full bg-transparent caret-sakura-100 text-2xl ring-none outline-none focus:outline-none"
      autoComplete={"false"}
    />
  );
}
