import { HeaderTabType } from "../../types/ui-types";
import MenuItemsDnD from "./MenuItemsDnD";

type Props = {
  items: HeaderTabType[];
};

export default function OtherMenuItems({ items }: Props) {
  return (
    <MenuItemsDnD
      notDraggableId="create-menu-item"
      droppableId={"other_menu_items"}
      items={items}
    />
  );
}
