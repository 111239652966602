import { ReactElement, useEffect, useRef, useState } from "react";
import useCloseOnOutsideClick from "../../../hooks/useOutsideClick";
import styles from "./DropdownTemplate.module.scss";
type Props = {
  children: ReactElement[] | ReactElement;
  toggleMenu: (val: boolean) => void;
  direction?: string;
  triggerEl: ReactElement;
  collapse: boolean;
};

export default function Dropdown({
  children,
  collapse,
  toggleMenu = () => {},
  direction = "down",
  triggerEl,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    collapse && setIsOpen(false);
  }, [collapse]);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    toggleMenu(!isOpen);
  };

  useCloseOnOutsideClick(dropdownRef, isOpen, setIsOpen);

  return (
    <div className="w-full relative" ref={dropdownRef}>
      <div className="w-full" onClick={toggleDropdown}>
        {triggerEl}
      </div>

      <div
        className={`bg-gray-500/90 z-10 rounded-2xl absolute  w-full max-h-[320px] overflow-y-auto transition-all min-w-max ${
          styles?.[direction]
        } ${isOpen ? "" : "pointer-events-none opacity-0"}`}>
        {children}
      </div>
    </div>
  );
}
