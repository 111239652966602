import {
  DndContext,
  DragOverEvent,
  DragOverlay,
  DropAnimation,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  closestCorners,
  defaultDropAnimation,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { tasks } from "dummyData";
import { useState } from "react";
import { Task } from "types/typings";
import { extractDragDetails, sortTasksByPriority, sortTasksObjByPriority } from "utils/helpers";
import TaskDraggable from "./TaskDraggable";
import styles from "./TasksBoard.module.scss";
import TasksColumn from "./TasksColumn";

type Props = {};

export default function TasksBoard({}: Props) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
  const [draggedItem, setDraggedItem] = useState<Task | undefined>();

  // const [taskObj, setTaskObj] = useState<TasksObject>(sortTasksByPriority(tasks));
  // const { data: tasks, isLoading, isError } = useGetTasksQuery("get-task");
  // const [updateTasks] = useUpdateTasksMutation();

  const handleToggleSelectId = (task: Task) => {
    if (selectedTasks[0]?.task_status !== task.task_status) {
      setSelectedTasks([task]);
      return;
    }
    const filteredTasks: Task[] = selectedTasks.filter((currTask) => currTask.id !== task.id);
    if (filteredTasks.length === selectedTasks.length) {
      filteredTasks.push(task);
    }
    setSelectedTasks(filteredTasks);
  };
  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    const { sourceContainerId, destContainerId } = extractDragDetails(event);

    if (!sourceContainerId || !destContainerId || sourceContainerId === destContainerId) {
      return;
    }
    const overItems = tasks.value[destContainerId];
    const overIndex = overItems.findIndex((item: any) => item.id !== over?.id);
    const newSelected = Array.from(new Set([...selectedIds, active.id as string]));

    tasks.value = sortTasksObjByPriority({
      ...tasks.value,
      [sourceContainerId]: [
        ...tasks.value[sourceContainerId].filter((item: any) => !newSelected.includes(item.id)),
      ],
      [destContainerId]: [
        ...tasks.value[destContainerId].slice(0, overIndex),
        ...tasks.value[sourceContainerId]?.filter((item: any) => newSelected.includes(item.id)),
        ...tasks.value[destContainerId].slice(overIndex, tasks.value[destContainerId].length),
      ],
    });
  };
  const handleDragEnd = (event: DragOverEvent) => {
    const { sourceContainerId, destContainerId, activeIdx, overIdx } = extractDragDetails(event);
    const source: Task[] = tasks.value?.[sourceContainerId ?? ""];
    const destination: Task[] = tasks.value?.[destContainerId ?? ""];

    if (!source || !destination || sourceContainerId !== destContainerId) return;
    const newSelected = Array.from(new Set([...selectedIds, event.active.id as string]));

    // Identify the dragged items
    const draggedItems = source.filter((item) => newSelected.includes(item.id));

    // Create the updated list by inserting dragged items at the specified index
    const updatedList = [...source.slice(0, overIdx), ...draggedItems, ...source.slice(overIdx)];

    // Remove duplicates from the updated list
    const deduplicatedList = Array.from(new Set(updatedList));

    tasks.value = {
      ...tasks.value,
      [sourceContainerId]: deduplicatedList.sort(sortTasksByPriority),
    };

    setSelectedTasks([]);
    setDraggedItem(undefined);
  };
  const dropAnimation: DropAnimation = {
    ...defaultDropAnimation,
  };
  const selectedIds = selectedTasks.map((item) => item.id);
  return (
    <div className={`blurred-dark ${styles.board} pointer-events-auto z-10`}>
      <DndContext
        collisionDetection={closestCorners}
        sensors={sensors}
        onDragStart={({ active }) => {
          const containerId = active.data.current?.sortable?.containerId;
          setDraggedItem(tasks.value?.[containerId]?.find((item) => item.id === active.id));
        }}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}>
        <div className={`${styles.inner}`}>
          <TasksColumn
            title="Todo"
            id="todo"
            tasks={tasks.value["todo"]}
            selectedIds={selectedIds}
            toggleSelect={handleToggleSelectId}
          />
          <TasksColumn
            title="In progress"
            id="in_progress"
            tasks={tasks.value["in_progress"]}
            selectedIds={selectedIds}
            toggleSelect={handleToggleSelectId}
          />
          <TasksColumn
            title="Completed"
            id="completed"
            tasks={tasks.value["completed"]}
            selectedIds={selectedIds}
            toggleSelect={handleToggleSelectId}
          />
        </div>{" "}
        <DragOverlay dropAnimation={dropAnimation}>
          {draggedItem ? (
            <TaskDraggable
              isGhosting={false}
              task={draggedItem}
              selectedIds={selectedIds}
              isDragging={true}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
}
