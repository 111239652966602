import { DEVICE, GROUP, LOCATION } from "constants/api";
import { Location } from "../../types/typings";
import { changeHomeTabName } from "../uiSlice";
import { apiSlice } from "./apiSlice";
type LocationResponse = {
  data: Location;
  meta: any;
};
export const locationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query({
      query: (str) => {
        apiSlice.util.invalidateTags([
          { type: DEVICE, id: "list" },
          { type: GROUP, id: "list" },
        ]);
        return "/organization";
      },
      providesTags: (res, err, arg) => [
        { type: LOCATION, id: res?.id },
        ...(Array.isArray(res) ? res : []).map((r: Location) => ({
          type: LOCATION,
          id: r.id,
        })),
      ],

      transformResponse: (res: any) => {
        return res?.data ?? res;
      },
    }),
    getLocation: builder.query({
      query: (id) => {
        return ``;
      },
      providesTags: (res, err, arg) => [{ type: LOCATION, id: res?.id }],
      async onCacheEntryAdded(arg, api) {
        const { dispatch, cacheDataLoaded } = api || {};

        const res = await cacheDataLoaded;
        dispatch(changeHomeTabName(res.data.name));
      },
    }),
    updateLocation: builder.mutation({
      query: (location: Location) => ({
        url: `/organizations/${location.id}`,
        method: "PUT",
        body: {
          ...location,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: LOCATION, id: arg.id }],
    }),
  }),
});

export const { useGetLocationQuery, useGetLocationsQuery } = locationSlice;
