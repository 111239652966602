import plusIcon from "assets/icons/add.svg";
import batteryIcon from "assets/stats-icons/battery.svg";
import devicesIcon from "assets/stats-icons/devices.svg";
import fillRateIcon from "assets/stats-icons/fill-rate.svg";
import { DEVICE_CATALOGUE_PAGE } from "../../constants/routes";
import { WidgetType } from "../../types/ui-types";
export const initialStatBlocks: WidgetType[] = [
  {
    id: "widget-devices",
    name: "devices",
    label: "Devices",
    iconSrc: devicesIcon,
    data: "140",
    to: DEVICE_CATALOGUE_PAGE,
  },
  // {
  //   id: "widget-fill-rate",
  //   name: "fill-rate",
  //   label: "Fill Rate",
  //   iconSrc: fillRateIcon,
  //   data: "0.63",
  //   to: DEVICE_CATALOGUE_PAGE,
  // },
  // {
  //   id: "widget-battery",
  //   name: "battery",
  //   label: "Battery",
  //   iconSrc: batteryIcon,
  //   data: "<10%",
  //   to: DEVICE_CATALOGUE_PAGE,
  // },
];
export const all_widgets: WidgetType[] = [
  {
    id: "create-widget",
    name: "create-widget",
    label: "Create",
    iconSrc: plusIcon,
    to: "/",
    data: null,
  },
  { id: "widget-devices", name: "devices", label: "Devices", iconSrc: devicesIcon, data: "140" },
  {
    id: "widget-fill-rate",
    name: "fill-rate",
    label: "Fill Rate",
    iconSrc: fillRateIcon,
    data: "0.63",
  },
  { id: "widget-battery", name: "battery", label: "Battery", iconSrc: batteryIcon, data: "<10%" },
  {
    id: "widget-other-1",
    name: "other",
    label: "Other Widget",
    iconSrc: fillRateIcon,
    data: "0.63",
  },
  {
    id: "widget-other-2",
    name: "battery",
    label: "And Another ",
    iconSrc: fillRateIcon,
    data: "0.63",
  },
];
