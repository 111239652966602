import { taskData } from "components/UI/Modals/TaskModal";
import { tasks } from "dummyData";
import { Option } from "types/ui-types";
import { Task } from "../../types/typings";
import { sortTasksByPriority } from "../helpers";
export const toggleClosed = () => {
  taskData.value = {
    ...taskData.value,
    isClosed: !Boolean(taskData.value?.isClosed),
  };
};
export const onTaskStatusChange = (option: Option) => {
  taskData.value = { ...taskData.value, task_status: option.value as string };
};
export const onPriorityChange = (newPriority: string) => {
  taskData.value = { ...taskData.value, priority: newPriority };
};
export const handleSaveTaskChanges = () => {
  const { id, task_status, isClosed, ...restTaskData } = taskData.value;
  const columnsToUpdate = tasks.value;

  // Remove the task from its old status column
  const updatedOldColumns = Object.keys(columnsToUpdate).reduce((acc, columnKey) => {
    const tasksInColumn = columnsToUpdate[columnKey];

    const updatedColumn =
      columnKey === task_status
        ? isClosed
          ? tasksInColumn?.filter((task: Task) => task.id !== id).sort(sortTasksByPriority)
          : [
              ...tasksInColumn.filter((task: Task) => task.id !== id),
              { ...restTaskData, id, task_status },
            ].sort(sortTasksByPriority)
        : tasksInColumn?.filter((task: Task) => task.id !== id).sort(sortTasksByPriority);

    return { ...acc, [columnKey]: updatedColumn };
  }, {});

  tasks.value = updatedOldColumns;
};
