import { useState } from "react";
import { Cookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "../../features/api/apiAuthSlice";
import { setCredentials, setLocation } from "../../features/authSlice";
import Input from "../UI/Input";

export default function SigninForm({ styles }: { [key: string]: { [key: string]: string } }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const [login, { isLoading }] = useLoginUserMutation();
  const [email, setEmail] = useState<string>(""); // Specify the type as string
  const [password, setPassword] = useState<string>(""); // Specify the type as string
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);
  const location = useLocation();
  const picked_location = localStorage.getItem("picked_location")
    ? JSON.parse(localStorage.getItem("picked_location")!)
    : null;
  return (
    <form
      className={`${styles.form}`}
      onSubmit={async (e) => {
        e.preventDefault();
        if (e.currentTarget.checkValidity()) {
          try {
            const loginData = await login({ email, password }).unwrap();
            console.log(loginData);
            dispatch(setCredentials({ user: email, access_token: loginData?.access_token }));
            if (picked_location) {
              dispatch(setLocation(picked_location));
            }
            cookies.set("refresh_token", loginData.refresh_token, {
              httpOnly: false,
              maxAge: 60 * 60 * 24 * 14,
            });
            const navigate_to = picked_location ? location?.state?.from ?? "/" : "/pick-location";

            navigate(navigate_to, { replace: true });
          } catch (err) {
            console.log(err);
          }
        }
      }}>
      <Input
        type="email"
        required
        label={"Email Address"}
        id="email"
        placeholder={"Email"}
        onChange={handleEmailChange}
      />
      <Input
        type="password"
        required
        label={"Password"}
        id="password"
        placeholder={"pa55w0rd"}
        onChange={handlePasswordChange}
      />
      <button className="rounded-full bg-sakura-100 text-sakura-900 text-center h-14">
        Sign in
      </button>
    </form>
  );
}
