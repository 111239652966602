import closeIcon from "assets/icons/close.svg";
import searchIcon from "assets/icons/search-thin.svg";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
type Props = {
  value: string;
  setValue: Dispatch<SetStateAction<string>> | ((val: string) => void);
  placeholder?: string;
};
export default function SearchBar({ value, setValue, placeholder = "Search Devices" }: Props) {
  const handleSearchStr = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  const cleanSearchStr = () => {
    setValue("");
  };
  return (
    <div className="flex items-center gap-2 h-12 px-4 w-full">
      {/* <button>
    <img className="w-6 h-6 flex-shrink-0" src={burgerIcon} alt="Menu" />
  </button> */}
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleSearchStr}
        className="w-1/2 flex-grow caret-sakura-100 bg-transparent outline-none "
      />
      <button disabled={value?.length === 0} onClick={cleanSearchStr}>
        <img
          className="w-6 h-6  flex-shrink-0"
          src={value?.length > 0 ? closeIcon : searchIcon}
          alt="Search"
        />
      </button>
    </div>
  );
}
