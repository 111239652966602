type Props = {
  id: string | number;
  isChecked: boolean;
  toggleSelect: (e: any) => void;
  title?: string;
};

export default function RadioBtn({ id, isChecked, toggleSelect, title }: Props) {
  return (
    <label
      title={title}
      htmlFor={id.toString()}
      className={`w-5 h-5 rounded-full bg-sakura-100  relative flex justify-center items-center cursor-pointer p-1`}>
      <input
        checked={isChecked}
        className="hidden"
        id={id.toString()}
        type="checkbox"
        onChange={toggleSelect}
      />
      <div
        className={`w-full h-full bg-sakura-900 rounded-full transition-transform ${
          isChecked ? "scale-100" : "scale-0"
        }`}
      />
    </label>
  );
}
