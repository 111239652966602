import { NavLink } from "react-router-dom";
import { HeaderTabType } from "../../types/ui-types";
import styles from "./HeaderTab.module.scss";
export default function HeaderTab({
  to = "/",
  addClass = "",
  iconSrc,
  label = "",
  getAction,
  getActionId,
}: HeaderTabType) {
  const { data = [], isLoading = true } = getAction ? getAction(getActionId ?? undefined) : {};

  return (
    <NavLink
      className={`${styles.blurred} flex flex-grow w-full gap-4 items-center justify-center h-14 ${styles?.[addClass]}`}
      to={to as string}>
      {iconSrc && (
        <div className={`${styles.iconWrapper} relative`}>
          <img className="w-5 h-5" src={iconSrc as string} alt={label as string} />
          {/* <Badge
            count={isLoading ? 0 : data?.filter((item: any) => item.status === "active")?.length}
          /> */}
        </div>
      )}
      <span className="text-fuchsia-50 font-medium">{label}</span>
    </NavLink>
  );
}
