import { ReactElement, useEffect, useRef } from "react";

type Props = { children: ReactElement | ReactElement[]; show: boolean; closeModal: () => void };

export default function Backdrop({ children, show, closeModal }: Props) {
  const backdropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        typeof (event.target as HTMLElement)?.className === "string" &&
        (event.target as HTMLElement)?.className?.includes("backdrop")
      ) {
        closeModal();
      }
    };

    backdropRef.current?.addEventListener("click", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      backdropRef?.current?.removeEventListener("click", handleClickOutside);
    };
  }, [closeModal]);

  return (
    <div
      ref={backdropRef}
      className={`fixed z-30  top-0 left-0 w-full h-full overflow-hidden p-10 flex justify-center items-center backdrop bg-black/40 transition-all ${
        show ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none -z-10"
      }`}>
      {children}
    </div>
  );
}
