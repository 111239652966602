import { XMarkIcon } from "@heroicons/react/20/solid";
import { task_statuses } from "components/../dummyData";
import TaskAsigneeBlock from "components/Task/TaskAsigneeBlock";
import TaskCreatedAt from "components/Task/TaskCreatedAt";
import TaskDescription from "components/Task/TaskDescription";
import TaskName from "components/Task/TaskName";
import TaskPriority from "components/Task/TaskPriority";
import TaskTags from "components/Task/TaskTags";
import TaskUpdatesLog from "components/Task/TaskUpdatesLog";
import TaskStatusSelect from "components/UI//Selects/TaskStatusSelect";
import CheckBox from "components/UI/CheckBox";
import { Task } from "types/typings";
import { Option } from "../../types/ui-types";
type Props = {
  taskData: Task | null;
  handleClose: () => void;
  toggleClosed: () => void;
  onTaskStatusChange: (option: Option) => void;
  onTaskPriorityChange: (newPriority: string) => void;
};

export default function TaskDetailed({
  taskData,
  handleClose,
  toggleClosed,
  onTaskStatusChange,
  onTaskPriorityChange,
}: Props) {
  const corrTaskStatus = task_statuses.find((status) => status.value === taskData?.task_status);
  return (
    <div className="blurred-dark flex flex-col w-full max-w-3xl rounded-2xl">
      <div className="flex justify-between items-center px-3 py-2 border-b border-b-white gradient-highlighted rounded-t-2xl">
        <div className="border border-white rounded-md p-1 text-xs">
          Tasks {` > `} {corrTaskStatus?.label} {` > `} {taskData?.id}
        </div>
        <button
          onClick={handleClose}
          className="w-8 h-8 border border-white rounded-lg p-1 group transition-colors hover:bg-sakura-100 hover:border-sakura-100">
          <XMarkIcon className="w-full text-white transition-colors group-hover:text-sakura-900" />
        </button>
      </div>
      <div className="flex justify-between items-center p-6 border-b border-b-white  gap-4">
        <div className="flex gap-3 items-center">
          <TaskStatusSelect
            task_status={corrTaskStatus ?? task_statuses[0]}
            setTaskStatus={onTaskStatusChange}
          />
          <CheckBox
            title="Mark as Closed"
            isChecked={Boolean(taskData?.isClosed)}
            toggleSelect={toggleClosed}
            id={"isCompleted"}
          />
        </div>
        <div className="flex gap-5 items-center">
          <TaskAsigneeBlock />
          <div className="h-8 w-px flex-shrink-0 bg-gray-300"></div>
          {taskData?.priority && (
            <TaskPriority priority={taskData.priority} onChange={onTaskPriorityChange} />
          )}
        </div>
        <div className="ml-auto">
          <TaskCreatedAt />
        </div>
      </div>
      <div className="py-3 px-4 w-full">
        <TaskTags />
      </div>
      <div className="w-full px-5 py-3 pb-8 border-b border-b-white flex flex-col gap-5">
        <TaskName />
        <TaskDescription />
      </div>
      <TaskUpdatesLog />
    </div>
  );
}
