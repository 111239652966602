import { DEVICE } from "constants/api";
import { apiSlice } from "./apiSlice";

export const sensorSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    rebootDevice: builder.mutation({
      query: (id: string) => ({
        url: `/device/${id}/reboot`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [{ type: DEVICE, id: arg }],
    }),
    resetCounting: builder.mutation({
      query: (id: string) => ({
        url: `/device/${id}/counting/reset`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [{ type: DEVICE, id: arg }],
    }),
    calibrateDevice: builder.mutation({
      query: (id: string) => ({
        url: `/device/${id}/calibrate`,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [{ type: DEVICE, id: arg }],
    }),
  }),
});

export const { useRebootDeviceMutation, useResetCountingMutation, useCalibrateDeviceMutation } =
  sensorSlice;
