import { useGetLocationQuery } from "../../features/api/locationSlice";
// import HeaderMenu from "./HeaderMenu";
import HeaderTabs from "./HeaderTabs";

export default function Header() {
  const {
    data: location,
    isLoading,
    isError,
  } = useGetLocationQuery(undefined, {
    selectFromResult: ({ data, isLoading, isError }) => ({
      data: data?.name,
      isLoading,
      isError,
    }),
  });

  return (
    <div className="Header w-full flex justify-between gap-4 pointer-events-auto">
      <HeaderTabs />
      {/* <HeaderMenu /> */}
    </div>
  );
}
