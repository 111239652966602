import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { CREATE_OR_EDIT_AREA_PAGE } from "../../constants/routes";
import { device_statuses } from "../../dummyData";
import { useGetGroupQuery, useUpdateGroupMutation } from "../../features/api/groupSlice";
import { pickArea } from "../../features/uiSlice";
import { Device, GroupType } from "../../types/typings";
import { getGroupDevicesStats } from "../../utils/deviceHelpers";
import { getIcon, getIconByDeviceType } from "../../utils/iconGetter";
import BtnBlurred from "../UI/Buttons/ButtonBlurred";
import InputQuickEdit from "../UI/InputQuickEdit";
type Props = {
  area: GroupType | null;
};
const updates_msgs: { name: string; date: string; message: string }[] = [
  {
    name: "John Doe",
    date: "02.10.2023 12:45",
    message: "Dispenser is fixed.",
  },
  {
    name: "John Doe",
    date: "02.10.2023 12:45",
    message: "Dispenser is fixed.",
  },
  {
    name: "John Doe",
    date: "02.10.2023 12:45",
    message: "Dispenser is fixed.",
  },
];
export default function GroupInfoSidebar({ area }: Props) {
  const dispatch = useDispatch();
  const { data: pickedArea, isLoading, isError } = useGetGroupQuery(area?.id!);
  const [updateGroup] = useUpdateGroupMutation();
  const handleClose = () => {
    dispatch(pickArea(null));
  };
  const gatheredData = !isLoading && !isError ? getGroupDevicesStats(pickedArea.devices) : {};
  const handleChangeStatus = async (status: string) => {
    try {
      // await updateGroup({ ...area! }).unwrap();
    } catch (err) {
      console.error("Failed to change Status", err);
      alert("Failed to change Status");
    }
  };
  const handleChangeAreaName = async (name: string) => {
    try {
      // await updateGroup({ ...area!, name }).unwrap();
    } catch (err) {
      console.error("Failed to change Status", err);
      alert("Failed to change Status");
    }
  };

  return (
    <div className="w-full h-full flex flex-col flex-grow  gap-3 relative z-30 blurred-dark">
      {pickedArea && !isLoading && !isError && (
        <>
          <div className="w-full flex justify-between items-center px-4 pt-4">
            <BtnBlurred onClick={handleClose}>Close</BtnBlurred>
            {/* <StatusSelect
              status={
                area_statuses.find((option) => option.value === pickedArea?.status) ||
                area_statuses[0]
              }
              setStatus={handleChangeStatus}
              available_statuses={area_statuses}
            /> */}
          </div>
          <div className="px-4   border-white/50 ">
            <InputQuickEdit
              initialValue={pickedArea.name}
              handleUpdateValue={handleChangeAreaName}
            />
          </div>
          <div className="flex gap-4 pt-4 px-4 ">
            <div className="w-full text-white/60 flex flex-col gap-4 items-start">
              <div className="flex w-full justify-between items-center">
                <p>Updates</p> <BtnBlurred>See More</BtnBlurred>
              </div>
              {updates_msgs.slice(0, 2).map((msg, i) => (
                <div key={i}>
                  <p className="text-sm">
                    <span>{msg.name}</span> | <span>{msg.date}</span>
                  </p>
                  <p className="text-xs">{msg.message}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="px-4 pb-4 border-t border-b border-white/50 text-white/60 ">
            <p className="pt-4">Devices</p>
            {pickedArea.devices &&
              pickedArea.devices.map((device: Device) => {
                const current_status =
                  device_statuses.find((option) => option.value === device?.status) ||
                  device_statuses[0];
                return (
                  <div key={device.id}>
                    <div className="  flex-shrink-0 overflow-y-auto">
                      <div className=" grid grid-cols-[40px,1fr,max(72px,18%)] auto-rows-max gap-3 text-sm items-center place-center">
                        <div className="relative w-10 h-10 flex-shrink-0">
                          <img
                            className="w-full h-full absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 object-contain"
                            src={getIconByDeviceType(device.__model)}
                            alt={device.name}
                          />
                        </div>
                        <p className="text-lg ">{device.name}</p>
                        <div
                          className={` rounded-full text-sm px-2.5 py-0.5 flex items-center w-full ${
                            current_status.value === "active"
                              ? "bg-lime-400/25 text-lime-400"
                              : "bg-red-500/25 text-red-400"
                          }`}>
                          {current_status.label}
                          <div
                            className={`w-1.5 h-1.5 ml-auto rounded-full ${
                              current_status.value === "active" ? "bg-lime-400" : "bg-red-400"
                            }`}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="pt-4 flex flex-col h-1/3 flex-grow overflow-hidden relative z-30 p-4">
            <div className="h-1/2 flex-grow overflow-y-auto">
              {gatheredData?.last_occupied_on || gatheredData.total_occupancy ? (
                <div className="grid grid-cols-[1fr,max(120px,20%)] auto-rows-max gap-3 text-sm">
                  <p className="text-base pb-2 col-span-2">Occupacy</p>

                  {gatheredData.total_occupancy !== undefined &&
                  gatheredData.total_occupancy >= 0 ? (
                    <>
                      <p className="text-white/70">Total people</p>
                      <p>{gatheredData.total_occupancy!}</p>
                    </>
                  ) : (
                    <></>
                  )}

                  <p className="text-white/70">Last occupied</p>
                  {gatheredData?.last_occupied_on ? (
                    <p className="relative">
                      <img
                        className="absolute w-4 h-4 left-0 top-0 -translate-x-5"
                        src={getIcon("historyIcon")}
                        alt="history"
                      />
                      {format(new Date(gatheredData.last_occupied_on), "dd-MM-yyyy \n HH:mm")}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
              {/* <p className="text-white/50 pt-4">Description</p>
              <p className=" line-clamp-3">
                Whatever other data need to be shown here...Whatever other data need to be shown
                here. Whatever other data need to be shown here...Whatever other data need to be
                shown here. Whatever other data need to be shown here...Whatever other data need to
                be shown here. Whatever other data need to be shown here...
              </p> */}
            </div>

            <div className="grid grid-cols-2 h-max flex-shrink-0 z-10 relative gap-4 mt-auto pt-2 ">
              <Link
                to={`${CREATE_OR_EDIT_AREA_PAGE}/${pickedArea.id}`}
                onClick={() => {
                  dispatch(pickArea(null));
                }}
                className="blurred-light select rounded-xl py-2.5 px-3 w-max">
                Edit area
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
