import { DEVICE } from "constants/api";
import { Device, NewDevice } from "../../types/typings";
import { apiSlice } from "./apiSlice";

export const deviceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDevices: builder.query({
      query: (str) => {
        return "/device";
      },
      providesTags: (res, err, arg) => [
        { type: DEVICE, id: "list" },
        ...(Array.isArray(res) ? res : []).map((r: Device) => ({ type: DEVICE, id: r.id })),
      ],
    }),
    getDevice: builder.query({
      query: (str) => {
        return `/device/${str}`;
      },
      providesTags: (res, err, arg) => [{ type: DEVICE, id: res.id }],
      transformResponse(baseQueryReturnValue: any) {
        return baseQueryReturnValue?.data ?? baseQueryReturnValue;
      },
    }),
    updateDevice: builder.mutation({
      query: (device: any) => ({
        url: `/device/${device.id}`,
        method: "PATCH",
        body: {
          ...device,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: DEVICE, id: arg.id }],
    }),
    addDevice: builder.mutation({
      query: (initialDevice: NewDevice) => ({
        url: `/device`,
        method: "POST",
        body: {
          data: { ...initialDevice },
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: DEVICE, id: "list" }],
    }),
  }),
});

export const {
  useLazyGetDevicesQuery,
  useGetDevicesQuery,
  useLazyGetDeviceQuery,
  useUpdateDeviceMutation,
  useAddDeviceMutation,
} = deviceSlice;
