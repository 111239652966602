import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { UserType } from "../../types/typings";

type Props = {
  item: UserType;
  hideDrop?: boolean;
};

export default function SettingsAboutHeader({ item, hideDrop = false }: Props) {
  return (
    <div className="w-full flex items-center  py-3 px-4 gap-1">
      <div
        style={{ backgroundColor: `${item.bg ?? "#000"}` }}
        className="w-10 h-10  rounded-full grid place-content-center flex-shrink-0">
        {item.firstName.charAt(0)}
      </div>
      <div className="w-full flex flex-col gap-1 text-white/80 pl-3">
        <p className="font-medium">
          {item.firstName} {item.lastName}
        </p>
        <p className="text-sm">Company</p>
      </div>
      <div className="justify-self-end rounded-full py-2 px-3 text-xs bg-white/10 backdrop-blur-md mr-2 capitalize ml-auto">
        {item.role}
      </div>
      {!hideDrop && (
        <button className="w-8 h-8 hover:bg-white/10 rounded-md grid place-content-center flex-shrink-0">
          <EllipsisVerticalIcon className="w-6 h-6 text-white/50" />
        </button>
      )}
    </div>
  );
}
