import { useEffect } from "react";
import { Cookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useRefreshUserTokenMutation } from "../features/api/apiAuthSlice";
import { logOut, setCredentials } from "../features/authSlice";
export default function ReLoginPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const [refresh] = useRefreshUserTokenMutation();

  const refreshUserLogin = async () => {
    try {
      const res = await refresh("").unwrap();
      dispatch(setCredentials({ access_token: res.access_token }));
      cookies.set("refresh_token", res.refresh_token, { maxAge: 60 * 60 * 24 * 14 });
    } catch (err) {
      dispatch(logOut());
    }
    navigate(location?.state?.from ?? "/");
  };
  useEffect(() => {
    refreshUserLogin();
  }, []);
  return (
    <div className="w-full h-full fixed top-0 left-0 flex text-7xl text-sakura-100 justify-center items-center">
      Trying to Relogin You...
    </div>
  );
}
