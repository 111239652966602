export const ROUTES = {
  SIGNIN_PAGE: "/signin",
  RELOGIN_PAGE: "relogin",
  NOTIFICATIONS_PAGE: "/notifications",
  ALERTS_PAGE: "/alerts",
  TASKS_PAGE: "/tasks",
  ADD_NEW_DEVICE_PAGE: "/add-new-device",
  ADD_HEADER_TABS_PAGE: "/add-header-tabs",
  ADD_WIDGETS_PAGE: "/add-widgets",
  DEVICE_CATALOGUE_PAGE: "/device-catalogue",
  SETTINGS_PAGE: "/settings",
  CREATE_OR_EDIT_AREA_PAGE: "/area",
  PICK_LOCATION_PAGE: "/pick-location",
};

export const {
  SIGNIN_PAGE,
  RELOGIN_PAGE,
  NOTIFICATIONS_PAGE,
  ALERTS_PAGE,
  TASKS_PAGE,
  ADD_NEW_DEVICE_PAGE,
  ADD_HEADER_TABS_PAGE,
  ADD_WIDGETS_PAGE,
  DEVICE_CATALOGUE_PAGE,
  SETTINGS_PAGE,
  CREATE_OR_EDIT_AREA_PAGE,
  PICK_LOCATION_PAGE,
} = ROUTES;
export const SETTINGS_SUBROUTES = {
  SETTINGS_DEVICES_PAGE: `devices`,
  SETTINGS_USERS_PAGE: `users`,
};
export const { SETTINGS_USERS_PAGE, SETTINGS_DEVICES_PAGE } = SETTINGS_SUBROUTES;
