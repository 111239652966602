import { Signal, signal } from "@preact/signals-react";
import checkIcon from "assets/icons/check.svg";

import { CREATE_OR_EDIT_AREA_PAGE } from "./constants/routes";
import { Task } from "./types/typings";
import { Option } from "./types/ui-types";
import { sortTasksObjByPriority } from "./utils/helpers";
export const tabs = [
  { label: "Active", value: "active", iconSrc: null },
  { label: "Solved", value: "solved", iconSrc: checkIcon },
];

export const task_statuses = [
  {
    label: "To do",
    value: "todo",
  },
  {
    label: "In progress",
    value: "in_progress",
  },
  {
    label: "Completed",
    value: "completed",
  },
];

export const categories = [
  {
    label: "Show All",
    value: "",
  },
  {
    label: "1st Floor",
    value: "floor-1",
  },
  {
    label: "2nd Floor",
    value: "floor-2",
  },
  {
    label: "3rd Floor",
    value: "floor-3",
  },
];
export const bigAddItems = [
  {
    label: "Logout",
    value: "log-out",
    to: "/logout",
  },
  {
    label: "Add New Device",
    value: "add-device",
    to: "/add-new-device",
  },
  {
    label: "Create Area",
    value: "create-area",
    to: CREATE_OR_EDIT_AREA_PAGE,
  },
  // {
  //   label: "Send Notification",
  //   value: "send-notification",
  // },
  // {
  //   label: "Add New Employee",
  //   value: "and-new-employee",
  // },
  // {
  //   label: "Create New Task",
  //   value: "create-new-task",
  //   to: "/tasks/new",
  // },
];
export const device_statuses: Option[] = [
  { value: "active", label: "Active" },
  { value: "not active", label: "Not Active" },
];
export const area_statuses: Option[] = [
  { value: "free", label: "Free", color: "green" },
  { value: "needs-cleaning", label: "Needs Cleaning", color: "yellow" },
  { value: "occupied", label: "Occupied", color: "red" },
];
export const statuses: Option[] = [
  { value: "active", label: "Active" },
  { value: "solved", label: "Solved" },
];

// add new device comboboxes options

export const device_types = [
  { value: "temp-sensor", label: "Temperat. Sensor" },
  { value: "battery", label: "Battery" },
  { value: "soap-dispenser", label: "Soap Dispenser" },
  { value: "toilet-paper-dispenser", label: "Toilet Paper Dispenser" },
  { value: "towel-dispenser", label: "Towel Dispenser" },
];
export const device_locations = [
  { value: "floor-1", label: "1st Floor" },
  { value: "floor-2", label: "2nd Floor" },
  { value: "floor-3", label: "3rd Floor" },
];
export const device_groups = [
  { value: "group-1", label: "Group 1" },
  { value: "group-2", label: "Group 2" },
  { value: "group-3", label: "Group 3" },
];

export const taskPriorities: { [key: string]: string } = {
  high: "text-red-500",
  medium: "text-orange-500",
  low: "text-green-500",
};

export const tasks: Signal<{ [key: string]: Task[] }> = signal(
  sortTasksObjByPriority({
    todo: [
      {
        id: "1111",
        title: "do something",
        priority: "medium",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "todo",
      },
      {
        id: "1222",
        title: "do something 2",
        priority: "high",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "todo",
      },
      {
        id: "1223",
        title: "do something 3",
        priority: "medium",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "todo",
      },
      {
        id: "1225",
        title: "do something 4",
        priority: "medium",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "todo",
      },
      {
        id: "1226",
        title: "do something 5",
        priority: "high",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "todo",
      },
      {
        id: "1228",
        title: "do something 6",
        priority: "low",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "todo",
      },
    ],
    in_progress: [
      {
        id: "2111",
        title: "in progress something",
        priority: "high",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "in_progress",
      },
      {
        id: "2222",
        title: "progress something 2",
        priority: "medium",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "in_progress",
      },
    ],
    completed: [
      {
        id: "3111",
        title: "completed something",
        priority: "medium",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "completed",
      },
      {
        id: "3222",
        title: "completed something 2",
        priority: "high",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "completed",
      },
      {
        id: "3223",
        title: "completed something 3",
        priority: "medium",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "completed",
      },
      {
        id: "3225",
        title: "completed something 4",
        priority: "medium",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "completed",
      },
      {
        id: "3226",
        title: "completed something 5",
        priority: "high",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "completed",
      },
      {
        id: "3228",
        title: "completed something 6",
        priority: "low",
        description: "some descr",
        tags: [],
        asigneeList: [1],
        task_status: "completed",
      },
    ],
  })
);

export const users = [
  { userId: 1, firstName: "John", lastName: "Doe", bg: "rgb(135, 95, 137)", role: "supervisor" },
  { userId: 2, firstName: "Rodger", lastName: "Smith", bg: "rgb(51, 75, 44)", role: "admin" },
  { userId: 3, firstName: "Jim", lastName: "Jones", bg: "rgb(126, 196, 99)", role: "employee" },
];
export const show_all = {
  label: "Show All",
  value: "",
};

export const users_roles: Option[] = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Supervisor",
    value: "supervisor",
  },
  {
    label: "Employee",
    value: "employee",
  },
];

export const users_roles_filter = [show_all, ...users_roles];

export const appTreeInitial = [
  {
    name: "Building",
    type: "building",
    items: [
      { value: "floor-1", label: "1st Floor", device_types: [] },
      { value: "floor-2", label: "2nd Floor", device_types: [] },
      { value: "floor-3", label: "3rd Floor", device_types: [] },
    ],
  },
];
