import AboutDevice from "components/AboutDevice/AboutDevice";
import SearchList from "components/SearchList/SearchList";
import { useState } from "react";

import {
  useGetNotificationsQuery,
  useUpdateNotificationMutation,
} from "../features/api/notificationsSlice";
import { Notification } from "../types/typings";

export default function NotificationsPage() {
  const { data: notifications, isLoading, error } = useGetNotificationsQuery("getNotifications");
  const [pickedDevice, setPickedDevice] = useState<Notification | null>(null);
  const pickDevice = (device: Notification) => {
    setPickedDevice({ ...device });
  };
  const [updatePost] = useUpdateNotificationMutation();
  const handleSave = async (deviceData: Notification) => {
    try {
      await updatePost({ ...deviceData }).unwrap();
      setPickedDevice(null);
    } catch (err) {
      console.error("Failed to save the post", err);
      alert("Failed to save the post");
    }
  };
  return (
    <div className="grid grid-cols-[minmax(max-content,1fr),1.37fr] gap-5 w-full h-full overflow-hidden">
      {!isLoading && !error && (
        <SearchList type="notifications" data={notifications} pickItem={pickDevice} />
      )}
      {pickedDevice && (
        <AboutDevice
          pickedDevice={pickedDevice}
          clearDevice={() => {
            setPickedDevice(null);
          }}
          handleSave={handleSave}
        />
      )}
    </div>
  );
}
