import { Outlet } from "react-router-dom";
import TasksBoard from "../components/TasksPageDnD/TasksBoard";

export default function TasksPage() {
  return (
    <>
      <TasksBoard />

      <Outlet></Outlet>
    </>
  );
}
