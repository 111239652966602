import { getIconByDeviceType } from "../../utils/iconGetter";

type Props = {
  icon: string;
  name: string;
};

export default function DeviceIcon({ icon, name }: Props) {
  return (
    <div className="blurred-dark select rounded-2xl p-4 relative h-full">
      <img
        className="w-11/12 h-5/6 absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 object-contain"
        src={getIconByDeviceType(icon)}
        alt={name}
      />
    </div>
  );
}
