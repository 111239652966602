import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetLocationsQuery } from "../features/api/locationSlice";
import { getStateAccessToken, setLocations } from "../features/authSlice";
import { Organization } from "../types/typings";
import styles from "./SigninPage.module.scss";
type Props = {};
export default function PickLocationPage({}: Props) {
  const navigate = useNavigate();
  const access_token = useSelector(getStateAccessToken);
  const { isLoading, isError, data } = useGetLocationsQuery(access_token);
  const dispatch = useDispatch();
  const onLocationPick = (location: Organization) => {
    dispatch(setLocations({ location_list: data, picked_location: location }));
    console.log(location.name, location.id);
    navigate("/", { replace: true });
  };
  return (
    <>
      <div className="flex justify-center items-center"></div>
      <div className={styles.form_section}>
        <h3 className="text-4xl text-[#2F2F2F] mb-6 xl:mb-10 2xl:mb-16">Pick Location</h3>
        <div className=" bg-asphalt-100 p-5 justify-center items-center rounded-2xl overflow-hidden w-full max-w-xs mx-auto gap-4 flex flex-col">
          {!isLoading &&
            !isError &&
            data.map((location: Organization) => (
              <button
                onClick={() => {
                  onLocationPick(location);
                }}
                key={location.id}
                className="w-full text-center p-4 rounded-xl text-white bg-black/10 hover:bg-white/5 text-2xl ">
                {location.name}
              </button>
            ))}
        </div>
      </div>
    </>
  );
}
