import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { pickDeviceFromSearch } from "../../features/uiSlice";
import { CounterSensor, Device as DeviceType, RaySensor, ThermalSensor } from "../../types/typings";
import { getIconByDeviceType } from "../../utils/iconGetter";
import BtnBlurred from "../UI/Buttons/ButtonBlurred";
import CounterStats from "./CounterStats";
import RayStats from "./RayStats";
import ThermalStats from "./ThermalStats";
type Props = {
  device: DeviceType | null;
};
const updates_msgs: { name: string; date: string; message: string }[] = [
  {
    name: "John Doe",
    date: "02.10.2023 12:45",
    message: "Dispenser is fixed.",
  },
  {
    name: "John Doe",
    date: "02.10.2023 12:45",
    message: "Dispenser is fixed.",
  },
  {
    name: "John Doe",
    date: "02.10.2023 12:45",
    message: "Dispenser is fixed.",
  },
];
export default function DeviceInfoSideBar({ device }: Props) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(pickDeviceFromSearch(null));
  };

  return (
    <div className="w-full h-full flex flex-col flex-grow  gap-3 relative z-30 blurred-dark">
      {device && (
        <>
          <div className="w-full flex justify-between items-center px-4 pt-4">
            <BtnBlurred onClick={handleClose}>Close</BtnBlurred>
            {/* <StatusSelect status={current_status} setStatus={(status) => {}} /> */}
          </div>
          <div className="flex gap-4 pt-4 px-4 pb-4 border-b border-white/50">
            <div className="w-full text-white/60 flex flex-col gap-4 items-start">
              <p>Updates</p>
              {updates_msgs.slice(0, 2).map((msg, i) => (
                <div key={i}>
                  <p className="text-sm">
                    <span>{msg.name}</span> | <span>{msg.date}</span>
                  </p>
                  <p className="text-xs">{msg.message}</p>
                </div>
              ))}
              <BtnBlurred>See More</BtnBlurred>
            </div>
            <div className="relative w-40 h-40 flex-shrink-0">
              <img
                className="w-11/12 h-full absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 object-contain"
                src={getIconByDeviceType(device?.__model)}
                alt={device.name}
              />
            </div>
          </div>
          {(
            {
              counter: <CounterStats device={device as CounterSensor} />,
              thermal: <ThermalStats device={device as ThermalSensor} />,
              ray: <RayStats device={device as RaySensor} />,
            } as Record<string, JSX.Element>
          )[device.__model] || <Fragment />}
        </>
      )}
    </div>
  );
}
