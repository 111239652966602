import showMoreIcon from "assets/icons/expand-more.svg";
import SearchBar from "components/UI/SearchBar";
import SearchTabs from "components/UI/SearchTabs";
import CategoryDropDown from "components/UI/Selects/CategorySelect";
import { format } from "date-fns";
import { categories, tabs } from "dummyData";
import { useEffect, useState } from "react";
import { Device, Notification } from "../../types/typings";
import { Option } from "../../types/ui-types";
import { distanceToToday, filterByKeys, splitArrayByDate } from "../../utils/helpers";
import { getIcon } from "../../utils/iconGetter";
import styles from "./SearchList.module.scss";
export default function SearchList({
  type = "notifications",
  data,
  pickItem,
}: {
  data: any;
  pickItem: (item: any) => void;
  type: string;
}) {
  const [category, setCategory] = useState<Option>(categories[0]);
  const [searchStr, setSearchStr] = useState("");
  const [tab, setTab] = useState(tabs[0]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    const filterData = () => {
      let filteredData = data;
      if (searchStr.length > 2) {
        filteredData = filterByKeys(searchStr, ["name", "location_organization"], data as Device[]);
      }
      if (category.value) {
        filteredData = filteredData.filter((res: any) => res.location === category.value);
      }
      filteredData = filteredData.filter((res: any) => res.status === tab.value);
      setResults(filteredData);
    };

    filterData();
  }, [category, searchStr, tab, data]);

  return (
    <div className="w-full h-full min-w-[440px] blurred-dark rounded-3xl flex flex-col gap-4 max-h-full overflow-hidden pointer-events-auto z-10">
      <div className="pt-4 px-4 flex flex-col gap-4  flex-shrink-0">
        <h1 className="text-lg text-asphalt-100">Notifications</h1>
        <div className="w-full grid grid-cols-[1fr,max-content] gap-4">
          <div className="blurred-light select w-full rounded-2xl">
            <SearchBar value={searchStr} setValue={setSearchStr} />
          </div>
          <CategoryDropDown category={category} setCategory={setCategory} />
        </div>
        <SearchTabs value={tab?.value} setTab={setTab} tabs={tabs} />
        <div className="w-[calc(100%)] h-px bg-[#49454F] flex-shrink-0" />
      </div>
      <div className="pl-4 pr-1.5 w-full h-3/4 flex-grow overflow-y-scroll gutter flex flex-col gap-4 pb-4">
        {results?.length > 0 &&
          splitArrayByDate(results).map((day) => {
            return (
              <div key={day.date} className="">
                <p className="text-lg text-asphalt-100 pb-2">{distanceToToday(day.date)}</p>
                <div className="flex flex-col gap-4">
                  {day.arr.map((res: Notification, i: number) => (
                    <button
                      className={`${styles.card} blurred-dark select rounded-2xl ${styles?.[type]}`}
                      key={`${res.id}_${i}`}
                      onClick={() => {
                        pickItem(res);
                      }}>
                      <img className={styles.icon} src={getIcon(res.icon)} alt={res.name} />
                      <div>
                        <p className={styles.cardStatus}>{res.status_message}</p>
                        <p className={styles.cardName}>
                          {res.name} {res?.location ? `- ${res.location}` : ""}
                        </p>
                      </div>
                      <p className="text-xs">{format(new Date(res.time), "HH:mm")}</p>
                      <img
                        className={`opacity-50 -rotate-90 w-7 h-7 justify-self-end`}
                        src={showMoreIcon}
                        alt={res.name}
                      />
                    </button>
                  ))}
                  {data <= 0 && <div>List is Clear!:)</div>}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
