import addIcon from "assets/icons/add.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ADD_WIDGETS_PAGE } from "../../constants/routes";
import { WidgetType } from "../../types/ui-types";
import WidgetBlock from "./WidgetBlock";

export default function WidgetsHome() {
  const current_widgets = useSelector((state: any) => state.ui.widgets);
  return (
    <div className="grid lg:grid-cols-2 gap-4 flex-shrink-0 relative z-10 pointer-events-auto">
      {current_widgets.map((widget: WidgetType, i: number) => {
        if (widget?.to) {
          return (
            <Link key={`link ${widget.to}_${i}`} to={widget.to}>
              <WidgetBlock key={widget.name} widget={widget} />
            </Link>
          );
        }
        return <WidgetBlock key={widget.name} widget={widget} />;
      })}
      <Link
        to={ADD_WIDGETS_PAGE}
        className="border-dashed border border-gray-500 flex gap-2 items-center justify-center p-4 rounded-2xl transition-colors hover:bg-black/20">
        <img className="w-6 h-6" src={addIcon} alt="Add Stat Block" />
        <span className="text-sakura-100">Add</span>
      </Link>
    </div>
  );
}
