import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";

import { useDroppable } from "@dnd-kit/core";
import { HeaderTabType } from "types/ui-types";
import MenuSortableItem from "./MenuSortableItem";
type Props = {
  items: HeaderTabType[];
  droppableId: string;
  notDraggableId?: string;
};

export default function MenuItemsDnD({ items, droppableId, notDraggableId }: Props) {
  const { setNodeRef } = useDroppable({
    id: droppableId,
  });
  return (
    <SortableContext id={droppableId} items={items} strategy={rectSortingStrategy}>
      <div ref={setNodeRef} className="w-full grid grid-cols-4 gap-4 ">
        {items?.length &&
          items.map((tab, i) => (
            <MenuSortableItem notDraggable={tab.id === notDraggableId} key={tab.id} tab={tab} />
          ))}
      </div>
    </SortableContext>
  );
}
