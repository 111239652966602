import { format } from "date-fns";

type Props = {};

export default function TaskUpdatesLog({}: Props) {
  return (
    <div className="w-full p-6 grid grid-cols-[1fr,max-content] gap-5 text-xs">
      <p className="text-white/40">
        <span className="text-white">You</span> created this task
      </p>
      <p>{format(new Date(), "MMM dd, hh:mm")}</p>
      <p className="text-white/40">
        <span className="text-white">You</span> assigned to <span className="text-white">You</span>
      </p>
      <p>{format(new Date(), "MMM dd, hh:mm")}</p>
    </div>
  );
}
