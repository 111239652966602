import { WidgetType } from "../../types/ui-types";
import WidgetItemsDnd from "./WidgetItemsDnd";

type Props = {
  items: WidgetType[];
};

export default function ExistingWidgets({ items }: Props) {
  return (
    <WidgetItemsDnd notDraggableId={"home"} droppableId={"existing_menu_items"} items={items} />
  );
}
