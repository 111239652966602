import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { useEffect } from "react";
import { useGetGroupsQuery } from "../../features/api/groupSlice";
import { groupId } from "./DeviceCatalogue";
type Props = {};

export default function DeviceTree({}: Props) {
  const { data: locations, isLoading, isError } = useGetGroupsQuery("getGroups");

  useEffect(() => {
    if (!isLoading && !isError) {
      // const newTree = organizeDevicesByTypeAndLocation(appTreeInitial, devices);
      // devicesList.value = devices;
      // appTree.value = [...newTree];
      // appTree.value = locations;
      groupId.value = locations?.[1]?.id;
    }
  }, [locations, isLoading, isError]);
  return (
    <div className="w-full h-full overflow-hidden flex flex-col gap-4 ">
      <p className="text-white/50">Groups</p>
      <div className="h-1/2 max-h-[75%] flex flex-col flex-grow overflow-y-auto  overflow-x-auto">
        {!isLoading && !isError && locations && locations?.length > 0 && (
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ChevronDownIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ height: "50%", flexGrow: 1, overflowY: "auto", overflowX: "auto" }}>
            {locations.map((group: any) => (
              <TreeItem
                key={group.id}
                nodeId={group.id}
                label={group.name}
                onClick={() => {
                  // updateFilter(building.name);

                  groupId.value = group.id;
                }}
              />
            ))}
          </TreeView>
        )}
      </div>
    </div>
  );
}
