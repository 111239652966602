import { API } from "constants/api";
import { Notification } from "../../types/typings";
import { apiSlice } from "./apiSlice";
const { ALERT } = API;
export const notificationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAlerts: builder.query({
      query: () => "/alerts",

      providesTags: (res, err, arg) => [
        { type: ALERT, id: "list" },
        ...(Array.isArray(res) ? res : []).map((r: Notification) => ({ type: ALERT, id: r.id })),
      ],
    }),
    updateAlerts: builder.mutation({
      query: (initialDevice: Notification) => ({
        url: `/alerts/${initialDevice.id}`,
        method: "PUT",
        body: {
          ...initialDevice,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: ALERT, id: arg.id }],
    }),
  }),
});

export const { useGetAlertsQuery, useUpdateAlertsMutation } = notificationsApiSlice;
