import { useThree } from "@react-three/fiber";
import { useDrag } from "@use-gesture/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { DoubleSide, Mesh, MeshBasicMaterial, Shape, ShapeGeometry } from "three";
import { useGetGroupQuery } from "../../features/api/groupSlice";
import { pickArea } from "../../features/uiSlice";
import useTabFocus from "../../hooks/useTabFocus";
import { Device, GroupType, ThermalSensor } from "../../types/typings";
import { getTemperatureTriggers } from "../../utils/functions/notifications";
type Props = {
  area: GroupType;
};

export default function DevicesArea({ area }: Props) {
  const location = useLocation();
  const windowFocus = useTabFocus();
  const { data } = useGetGroupQuery(area.id, {
    pollingInterval: 5000,
    skip: location.pathname.includes("device-catalogue") || !windowFocus,
    selectFromResult: ({ data }) => ({
      data,
    }),
  });
  const filledAreaRef = useRef<Mesh>(null);
  const [
    {
      color,
      opacity,
      // hasDangerTempTriggered,
      // hasHighTempTriggered,
      // isThermalAndLowOccupancy,
      // hasOccupiedDevices,
    },
    setInnerStats,
  ] = useState({
    color: "green",
    opacity: 0.3,
  });

  useEffect(() => {
    if (data) {
      const hasOccupiedDevices = data?.devices?.some(
        (d: Device) => d?.statistics?.count ?? d?.statistics?.occupied
      );
      const isThermalAndLowOccupancy = data?.devices?.some(
        (d: Device) =>
          d?.__model === "thermal" &&
          (d?.statistics?.total_occupancy as number) <
            (["Gaustatoppen", "Galdhøpiggen"].includes(d.name?.split(" ")?.[0]) ? 8 : 4)
      );
      const tempSensorTriggers = data.devices
        .filter((d: Device | ThermalSensor) => "notifications" in d)
        .map((d: ThermalSensor) => getTemperatureTriggers(d.notifications));
      const hasDangerTempTriggered = tempSensorTriggers.some(
        ({ danger_temp }: { [key: string]: boolean }) => danger_temp
      );
      const hasHighTempTriggered =
        !hasDangerTempTriggered &&
        tempSensorTriggers.some(({ high_temp }: { [key: string]: boolean }) => high_temp);
      let color;
      let opacity = 0.3;
      if (hasDangerTempTriggered) {
        color = "red";
        opacity = 0.6;
      } else if (hasHighTempTriggered) {
        color = "#ffff00";
        opacity = 0.6;
      } else if (hasOccupiedDevices) {
        color = isThermalAndLowOccupancy ? "orange" : "#FF5733";
      } else {
        color = "green";
      }
      setInnerStats({
        color,
        opacity,
      });
    }

    console.log("Area Data has changed", data?.name);
  }, [data]);

  const filledAreaMaterial = new MeshBasicMaterial({
    color,
    transparent: true,
    opacity,
    side: DoubleSide,
    depthTest: false,
  });
  const bind = useDrag(
    async ({ active, timeStamp, tap }) => {
      if (tap) {
        dispatch(pickArea(data));

        //@ts-ignore
        const { x, y, z } = area.map.area.dots[0] || { x: 0, y: 0, z: 0 };
        //@ts-ignore
        controls.target.set(x, y, z);
        camera.position.set(x, 1, z);
        //@ts-ignore

        return;
      }
      if (active) {
      }

      return timeStamp;
    },
    { delay: false, filterTaps: true }
  );

  const dispatch = useDispatch();
  const { camera, controls } = useThree();
  const {
    map: {
      area: { dots },
    },
  } = data || area || {};
  const handleChangeArea = () => {
    if (filledAreaRef.current && dots?.length > 2) {
      const shape = new Shape();

      // Move to the first point
      const { x: firstX, y: firstY, z: firstZ } = dots[0];
      shape.moveTo(firstX, -firstZ);

      // Line to all other points
      for (let i = 1; i < dots.length; i++) {
        const { x, y, z } = dots[i];
        shape.lineTo(x, -z);
      }

      // Close the shape by connecting the last point to the first point
      shape.lineTo(firstX, -firstZ);

      // Generate a 3D polygon geometry from the shape

      const filledAreaGeometry = new ShapeGeometry(shape);
      filledAreaGeometry.rotateX(-Math.PI / 2);
      filledAreaRef.current.geometry.dispose();

      filledAreaRef.current.geometry = filledAreaGeometry;
    }
  };
  useEffect(() => {
    handleChangeArea();
  }, [dots]);

  return (
    data && (
      //@ts-ignore
      <mesh
        ref={filledAreaRef}
        material={filledAreaMaterial}
        position={[0, 0.001, 0]}
        {...bind()}></mesh>
    )
  );
}
