import expandIcon from "assets/icons/expand-more.svg";
import { useState } from "react";
import { Option } from "types/ui-types";
import { categories } from "../../../dummyData";
import Select from "./SelectTemplate";

type Props = {
  category: Option;
  setCategory: React.Dispatch<React.SetStateAction<Option>>;
  options?: Option[];
};
export default function CategorySelect({ category, setCategory, options = categories }: Props) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Select
      options={options}
      onSelect={(cat) => {
        setCategory(cat);
        setExpanded(false);
      }}
      toggleMenu={(val) => {
        setExpanded(val);
      }}>
      <div className="blurred-light select w-full flex items-center justify-between gap-2 h-12 rounded-2xl px-2.5 ">
        <span className={`whitespace-nowrap overflow-ellipsis overflow-hidden inline-block`}>
          {category.label}
        </span>
        <img
          className={`w-6 h-6 flex-shrink-0 transition-transform duration-500 ${
            expanded ? "rotate-180" : ""
          }`}
          src={expandIcon}
          alt="Expand"
        />
      </div>
    </Select>
  );
}
