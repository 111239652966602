import { useEffect } from "react";
import { device_types } from "../../dummyData";
import { useGetGroupQuery } from "../../features/api/groupSlice";
import { Device } from "../../types/typings";
import RadioBtn from "../UI/RadioBtn";
import {
  aboutDevice,
  devicesList,
  filteredDevicesList,
  groupId,
  selectedDevicesIds,
} from "./DeviceCatalogue";

type Props = {};

export default function DevicesList({}: Props) {
  const { data, isLoading, isError, refetch } = useGetGroupQuery(groupId.value);
  useEffect(() => {
    refetch();
  }, [groupId.value]);
  useEffect(() => {
    if (data?.devices) {
      devicesList.value = data.devices;
    }
  }, [data?.devices]);
  return (
    !isLoading &&
    !isError &&
    data && (
      <div className="flex flex-col gap-3 h-1/2 flex-grow overflow-y-auto pr-1">
        {filteredDevicesList.value.map((device: Device) => (
          <div
            className={`w-full blurred-dark  select p-4 rounded-xl flex items-center gap-4  border ${
              aboutDevice.value.id === device.id ? "border-white/50" : "border-transparent"
            }`}
            key={device.id}
            onClick={() => {
              aboutDevice.value = device;
            }}>
            <RadioBtn
              id={device.id}
              title="select"
              isChecked={selectedDevicesIds.value.includes(device.id)}
              toggleSelect={(e: any) => {
                e.stopPropagation();
                selectedDevicesIds.value.includes(device.id)
                  ? (selectedDevicesIds.value = selectedDevicesIds.value.filter(
                      (item) => item !== device.id
                    ))
                  : (selectedDevicesIds.value = [...selectedDevicesIds.value, device.id]);
              }}
            />
            <div className="">
              <p>{device.name}</p>
              <p className="text-sm">
                {device_types.find((option) => option.value === device.category)?.label}
              </p>
            </div>
          </div>
        ))}
      </div>
    )
  );
}
