import { format } from "date-fns";

type Props = {};

export default function TaskCreatedAt({}: Props) {
  return (
    <div className="flex flex-col">
      <p>Create at:</p>
      <p>{format(new Date(), "MMM dd, hh:mm")}</p>
    </div>
  );
}
