import { ExclamationTriangleIcon, UserIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { ThermalSensor } from "../../types/typings";
import { getTemperatureTriggers } from "../../utils/functions/notifications";
import { getIconByDeviceType } from "../../utils/iconGetter";
import DeviceBadge from "./DeviceBadge";

type Props = {
  device: ThermalSensor;
};

export default function DeviceThermalSensor({ device }: Props) {
  const [{ danger_temp, high_temp, temperature_triggered }, setTempTriggers] = useState(
    getTemperatureTriggers(device.notifications)
  );
  useEffect(() => {
    // console.log(device.name, "changes");
    setTempTriggers(getTemperatureTriggers(device.notifications));
  }, [device]);

  return (
    <DeviceBadge model={device.__model} caution={high_temp} alert={danger_temp}>
      {danger_temp || high_temp ? (
        <ExclamationTriangleIcon
          className={`w-full h-auto  ${
            danger_temp ? "text-red-500  " : high_temp ? "text-yellow-300 " : ""
          }`}
        />
      ) : (
        <img
          className="w-full h-auto object-contain pointer-events-none "
          src={getIconByDeviceType(device?.__model)}
          alt={device.name}
        />
      )}

      <div className=" flex flex-col justify-center items-center w-full text-black">
        {danger_temp || high_temp ? (
          <>
            <p className="text-xs text-center">{temperature_triggered}°C</p>
          </>
        ) : (
          <></>
        )}
        <div className="flex justify-center items-center ">
          <UserIcon className="w-3 " />
          <span className="text-xxs">{device.statistics.total_occupancy}</span>
          <span className="text-xxs">
            /{["Gaustatoppen", "Galdhøpiggen"].includes(device.name?.split(" ")?.[0]) ? 8 : 4}
          </span>
        </div>
      </div>
    </DeviceBadge>
  );
}
