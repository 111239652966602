import { useDispatch, useSelector } from "react-redux";
import { useGetDevicesQuery } from "../features/api/deviceSlice";
import { getStateLocationID } from "../features/authSlice";
import { pickDeviceFromSearch } from "../features/uiSlice";
import { CounterSensor, Device, RaySensor, ThermalSensor } from "../types/typings";
import DeviceCounterSensor from "./DeviceInfoBadge/DeviceCounterSensor";
import DeviceRaySensor from "./DeviceInfoBadge/DeviceRaySensor";
import DeviceThermalSensor from "./DeviceInfoBadge/DeviceThermalSensor";
import DraggableDeviceWrapperV2 from "./Models/DraggableDeviceWrapperV2";

type Props = {
  id: string;
};

const notPinnedPos = [0.5 + Math.random() * 1.55, 0.09, 0.2 + Math.random() * 1.155];
export default function DeviceInstance({ id }: Props) {
  const dispatch = useDispatch();
  const locationID = useSelector(getStateLocationID);
  const { pickedDevice } = useSelector((state: any) => state.ui);
  const setPicked = (device: Device | null) => {
    dispatch(pickDeviceFromSearch(device));
  };
  const { device } = useGetDevicesQuery(`${locationID}_getDevices`, {
    selectFromResult: ({ data }) => ({
      device: data?.find((d: any) => d.id === id),
    }),
  });

  return device ? (
    <DraggableDeviceWrapperV2
      key={device.id}
      position={
        device.map.pinned
          ? [device.map.x, 0.001, device.map.y]
          : [0.5 + Math.random() * 0.55, 0.09, 0.2 + Math.random() * 0.155]
      }
      device={device}
      picked={pickedDevice}
      setPicked={setPicked}>
      {(
        {
          counter: <DeviceCounterSensor device={device as CounterSensor} />,
          thermal: <DeviceThermalSensor device={device as ThermalSensor} />,
          ray: <DeviceRaySensor device={device as RaySensor} />,
        } as Record<string, JSX.Element>
      )[device.__model] || <DeviceCounterSensor device={device as CounterSensor} />}
    </DraggableDeviceWrapperV2>
  ) : (
    <></>
  );
}
