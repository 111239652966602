import { Fragment } from "react";
import { CounterSensor, RaySensor, ThermalSensor } from "../../types/typings";
import DeviceIcon from "../AboutDevice/DeviceIcon";
import CounterStats from "../DeviceInfoSidebar/CounterStats";
import RayStats from "../DeviceInfoSidebar/RayStats";
import ThermalStats from "../DeviceInfoSidebar/ThermalStats";

type Props = {
  device: any;
};

export default function DeviceAbout({ device }: Props) {
  return (
    <div className="w-full h-full grid flex-col grid-rows-[1fr,3fr] gap-3 relative z-30">
      {device && (
        <>
          <DeviceIcon icon={device.__model} name={device.name} />
          <div className="blurred-dark select flex flex-col rounded-2xl p-4">
            {(
              {
                counter: <CounterStats device={device as CounterSensor} />,
                thermal: <ThermalStats device={device as ThermalSensor} />,
                ray: <RayStats device={device as RaySensor} />,
              } as Record<string, JSX.Element>
            )[device.__model] || <Fragment />}
          </div>
        </>
      )}
    </div>
  );
}
