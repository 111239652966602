import { useThree } from "@react-three/fiber";
import { useDrag } from "@use-gesture/react";
import { ReactElement, useRef, useState } from "react";
import * as THREE from "three";
import { BufferGeometry, Material, Mesh, NormalBufferAttributes, Object3DEventMap } from "three";

import { Html } from "@react-three/drei";
import { useSelector } from "react-redux";
import { degToRad } from "three/src/math/MathUtils";
import { useUpdateDeviceMutation } from "../../features/api/deviceSlice";
import { Device as DeviceType } from "../../types/typings";

type Props = {
  device: DeviceType;
  position: number[];
  setPicked: (device: DeviceType | null) => void;
  picked: any;
  children: ReactElement | ReactElement[];
};
const ndcPosition = new THREE.Vector2();
const deltaNdc = new THREE.Vector2();
export default function DraggableDeviceWrapperV2({
  device,
  position,
  setPicked,
  picked,
  children,
}: Props) {
  const { camera, raycaster, scene, setFrameloop, controls } = useThree();
  const [pos] = useState(new THREE.Vector3(...position));
  const editMode = useSelector((state: any) => state.area.editMode);
  const [updateDevice] = useUpdateDeviceMutation();
  const handelUpdate = async () => {
    try {
      await updateDevice({
        ...device,
        map: {
          ...device.map,
          pinned: true,
          x: meshRef.current?.position.x ?? 0,
          y: meshRef.current?.position.z ?? 0,
        },
      }).unwrap();
    } catch (err) {
      console.error("Failed to update the device", err);
      alert("Failed to update the device");
    }
  };
  const meshRef =
    useRef<Mesh<BufferGeometry<NormalBufferAttributes>, Material | Material[], Object3DEventMap>>(
      null
    );

  const bind = useDrag(
    async ({ event, active, delta, dragging, tap }) => {
      if (tap) {
        console.log("Tapped");
        setPicked(device);
      }
      if (!("clientX" in event)) return;

      if (active && delta) {
        setFrameloop("always");
        const { clientX, clientY } = event as MouseEvent | PointerEvent;
        ndcPosition.set(
          (clientX / window.innerWidth) * 2 - 1,
          -(clientY / window.innerHeight) * 2 + 1
        );

        //@ts-ignore
        controls.enablePan = false;
        // Get the world coordinates of the initial cursor position
        const initialWorldPosition = getWorldPosition(ndcPosition);

        // Adjust the mesh position based on the difference between the initial and current cursor positions
        if (initialWorldPosition) {
          deltaNdc.set((delta[0] / window.innerWidth) * 2, (-delta[1] / window.innerHeight) * 2);
          const currentCursorPosition = ndcPosition.clone().add(deltaNdc);
          const currentWorldPosition = getWorldPosition(currentCursorPosition);

          if (currentWorldPosition) {
            //@ts-ignore
            meshRef.current.position.copy(
              //@ts-ignore
              currentWorldPosition.sub(initialWorldPosition).add(meshRef.current.position)
            );
          }
        } else {
        }
      }
      if (!dragging && !tap) {
        //@ts-ignore
        controls.enablePan = true;
        setFrameloop("demand");
        handelUpdate();
      }
    },
    { delay: false, filterTaps: true }
  );

  function getWorldPosition(cursorPosition: THREE.Vector2): THREE.Vector3 | null {
    // Cast a ray from the cursor position into the scene
    raycaster.setFromCamera(cursorPosition, camera);
    const intersects = raycaster.intersectObjects(scene.children);

    // If there are intersections, return the position of the first intersection
    if (intersects.length > 0) {
      return intersects[0].point;
    }

    return null;
  }

  return (
    <mesh
      //@ts-ignore
      ref={meshRef}
      position={pos}>
      <Html
        center
        position={[0, 0.01, 0]}
        transform
        rotation={[degToRad(-90), 0, degToRad(0)]}
        scale={[0.02, 0.02, 0.02]}>
        <div className="touch-action-none " {...bind()}>
          {children}
        </div>
      </Html>
    </mesh>
  );
}
