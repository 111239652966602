import { ButtonHTMLAttributes, ReactElement } from "react";
type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactElement | ReactElement[] | string;
  className?: string;
};
export default function BtnOutlined({ children, className, ...rest }: Props) {
  return (
    <button
      className={`border border-gray-400 px-6 py-2.5 flex items-center justify-center text-sm rounded-full text-sakura-100 hover:bg-white/10 ${className}`}
      {...rest}>
      {children}
    </button>
  );
}
