import AboutDevice from "components/AboutDevice/AboutDevice";
import SearchList from "components/SearchList/SearchList";
import { useState } from "react";
import { useGetAlertsQuery, useUpdateAlertsMutation } from "../features/api/alertsSlice";
import { Notification } from "../types/typings";

export default function AlertsPage() {
  const { data: notifications, isLoading, error } = useGetAlertsQuery("notification");
  const [pickedDevice, setPickedDevice] = useState<Notification | null>(null);
  const pickDevice = (device: Notification) => {
    setPickedDevice(device);
  };
  const [updatePost] = useUpdateAlertsMutation();
  const handleSave = async (deviceData: Notification) => {
    try {
      await updatePost({ ...deviceData }).unwrap();
      setPickedDevice(null);
    } catch (err) {
      console.error("Failed to save the post", err);
      alert("Failed to save the post");
    }
  };
  return (
    <div className="grid grid-cols-[minmax(max-content,1fr),1.37fr] gap-5 w-full h-full overflow-hidden pointer-events-auto z-10">
      {!isLoading && !error && (
        <SearchList type="alerts" data={notifications} pickItem={pickDevice} />
      )}
      {pickedDevice && (
        <AboutDevice
          pickedDevice={pickedDevice}
          clearDevice={() => {
            setPickedDevice(null);
          }}
          handleSave={handleSave}
        />
      )}
    </div>
  );
}
