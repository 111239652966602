import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { UserType } from "../../types/typings";
import RadioBtn from "../UI/RadioBtn";

type Props = {
  item: UserType;
  onItemClick: () => void;
  isSelected?: boolean;
};

export default function SettingsListItem({ item, onItemClick, isSelected }: Props) {
  return (
    <div
      onClick={onItemClick}
      className={`w-full p-4 pr-7 blurred-light select rounded-xl  grid grid-cols-[1fr,repeat(3,max-content)] gap-2 items-center border border-transparent group cursor-pointer ${
        isSelected ? "border-white/50" : ""
      }`}>
      <div className="w-full flex flex-col gap-1 text-white/80">
        <p className="font-medium">
          {item.firstName} {item.lastName}
        </p>
        <p className="text-sm">Company</p>
      </div>
      <div className="justify-self-end rounded-full py-2 px-3 text-xs bg-white/10 backdrop-blur-md mr-2 capitalize">
        {item.role}
      </div>
      <RadioBtn id="selected" isChecked={false} toggleSelect={() => {}} />
      <ChevronRightIcon className="w-6 text-gray-500 transition-transform group-hover:translate-x-1" />
    </div>
  );
}
