import { NavLink } from "react-router-dom";
import { SettingsTabType } from "./settings";

type Props = {
  tab: SettingsTabType;
};

export default function SettingsTab({ tab }: Props) {
  return (
    <NavLink
      to={tab.to}
      className={({ isActive }) =>
        `w-full flex items-center gap-1 p-4 pr-6 rounded-full transition-colors  backdrop-blur-3xl active:bg-black/30 ${
          isActive ? "bg-sunset/40 " : "hover:bg-sunset/10"
        }`
      }>
      <div className={`w-6 h-6 bg-gray-300 ${tab.icon}`}></div>
      <p className="px-2">{tab.label}</p>
      <span className="ml-auto">{tab?.count}</span>
    </NavLink>
  );
}
