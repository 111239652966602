import { BaseQueryApi, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { Cookies } from "react-cookie";
import { API, GROUP, LOCATION } from "../../constants/api";
import { Organization } from "../../types/typings";
import { logOut, setCredentials } from "../authSlice";
//${"649a9ad5210aa0308465f689"}
const { ALERT, TASK, NOTIFICATION, DEVICE, USER } = API;
const mutex = new Mutex();
type BaseUrlProps = {
  isLoggedIn: boolean;
  refresh_token?: string;
  picked_location?: Organization;
  with_organization?: boolean;
};
const dynamicBaseURL = ({
  isLoggedIn,
  refresh_token,
  picked_location,
  with_organization = true,
}: BaseUrlProps) =>
  fetchBaseQuery({
    baseUrl: isLoggedIn
      ? `${process.env.REACT_APP_RESOURCE_SERVER_URL}/v1${
          with_organization ? "/organization" : ""
        }${with_organization && picked_location ? `/${picked_location.id}` : ""}`
      : process.env.REACT_APP_AUTH_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      //@ts-ignore
      const { access_token } = getState().auth;

      if (access_token) {
        headers.set("Authorization", `Bearer ${refresh_token ?? access_token}`);
      }
      return headers;
    },
  });

export const baseQueryWithReAuth = async (
  args: any,
  api: BaseQueryApi,
  options: { [key: string]: any }
) => {
  //@ts-ignore

  const { access_token, picked_location } = api.getState().auth;
  console.log("ACCESS_TOKEN CHECK");

  await mutex.waitForUnlock();

  const endpoint = typeof args === "string" ? args : args?.url ?? null;

  const query = dynamicBaseURL({
    isLoggedIn: !!access_token,
    picked_location,
    with_organization: picked_location ? !endpoint.includes("organization") : false,
  });
  await mutex.waitForUnlock();
  let res = await query(args, api, options);

  if (res?.error && res.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      const cookies = new Cookies();
      try {
        console.log("ACCESS_TOKEN CHECK FAILED");
        const refresh_token = cookies.get("refresh_token");
        const refreshed = await dynamicBaseURL({
          isLoggedIn: false,
          refresh_token,
          picked_location,
        })(
          {
            url: "/refresh",
            method: "POST",
            headers: {
              Authorization: `Bearer ${refresh_token}`,
            },
          },
          api,
          options
        );
        if (refreshed?.data) {
          const { access_token, refresh_token } = refreshed?.data as {
            access_token: string;
            refresh_token: string;
          };
          console.log("ACCESS_TOKEN refreshed");
          api.dispatch(setCredentials({ access_token }));
          cookies.set("refresh_token", refresh_token, { maxAge: 60 * 60 * 24 * 7 });
          const query = dynamicBaseURL({
            isLoggedIn: !!access_token,
            picked_location,
            with_organization: picked_location ? !endpoint.includes("organization") : false,
          });
          res = await query(args, api, options);
        } else {
          api.dispatch(logOut());
        }
      } finally {
        release();
      }
    } else {
      console.log("ACCESS_TOKEN CHECK Passed");
      //@ts-ignore
      console.log(api.getState().auth.access_token);
      const query = dynamicBaseURL({
        isLoggedIn: !!access_token,
        picked_location,
        with_organization: picked_location ? !endpoint.includes("organization") : false,
      });
      await mutex.waitForUnlock();
      res = await query(args, api, options);
    }
  }

  return res;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReAuth,

  tagTypes: [ALERT, TASK, NOTIFICATION, DEVICE, USER, GROUP, LOCATION],
  endpoints: (builder) => ({}),
});
