import { CheckIcon } from "@heroicons/react/20/solid";

type Props = {
  id: string | number;
  isChecked: boolean;
  toggleSelect: () => void;
  title?: string;
};

export default function CheckBox({ id, isChecked, toggleSelect, title }: Props) {
  return (
    <label
      title={title}
      htmlFor={id.toString()}
      className={`w-6 h-6 rounded-md border border-gray-600 relative flex justify-center items-center cursor-pointer ${
        isChecked ? "bg-sakura-100" : "bg-gray-300"
      }`}>
      <input
        checked={isChecked}
        className="hidden"
        id={id.toString()}
        type="checkbox"
        onChange={toggleSelect}
      />
      {isChecked && <CheckIcon stroke="2" className={`w-4 stroke-sakura-900 text-sakura-900 `} />}
    </label>
  );
}
