import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import ComboboxTemplate from "../components/UI/Combobox/ComboboxTemplate";
import { device_groups, device_locations, device_types } from "../dummyData";
import { useAddDeviceMutation } from "../features/api/deviceSlice";
import { Option } from "../types/ui-types";

export default function AddNewDevicePage() {
  const navigate = useNavigate();
  const [deviceId] = useState<string>(Math.random().toString().replace(".", ""));
  const [deviceMac] = useState<string>(`${Math.random().toFixed(6).toString().replace(".", "")}DE`);
  const [deviceName, setDeviceName] = useState<string>("");
  const [deviceType, setDeviceType] = useState<Option>(device_types[0]);
  const [location, setLocation] = useState<Option>(device_locations[0]);
  const [group, setGroup] = useState<Option>(device_groups[0]);
  const [postAddDevice, { isLoading }] = useAddDeviceMutation();

  const handelSubmit = async () => {
    try {
      await postAddDevice({
        model: "thermal",
        category: "sensor",
        name: deviceName,
        location_organization: {
          id: 1,
          name: "Some Location",
          map: {
            center: {
              pitch: 0,
              zoom: 12,
              latitude: 0,
              longitude: 0,
              bearing: 0,
            },
          },
        },
        map: {
          pinned: false,
          lat: 0,
          lng: 0,
          x: 0,
          y: 0,
        },
        status: "active",
      }).unwrap();

      navigate("/", { replace: true });
    } catch (err) {
      console.error("Failed to add the device", err);
      alert("Failed to add the device");
    }
  };
  const comboboxes = [
    {
      id: 1,
      options: device_types,
      option: deviceType,
      placeholder: "Choose Device Type",
      label: "Device Type",
      onOptionChange: setDeviceType,
    },
    {
      id: 2,
      options: device_locations,
      option: location,
      placeholder: "Choose Device Location",
      label: "Device Location",
      onOptionChange: setLocation,
    },
    {
      id: 3,
      options: device_groups,
      option: group,
      placeholder: "Choose Device Group",
      label: "Device Group",
      onOptionChange: setGroup,
    },
  ];
  return (
    <div className="w-full h-full overflow-hidden flex  flex-col items-end pointer-events-auto">
      <div className="w-1/2 max-w-[820px] h-full blurred-dark  rounded-3xl overflow-hidden p-4 relative grid grid-cols-2 gap-9">
        <button
          onClick={() => {
            navigate("/", { replace: true });
          }}
          className="rounded-full text-sm py-2.5 px-4 bg-plum min-w-max w-20 absolute right-4 top-4">
          Close
        </button>
        <div className="w-full blurred-dark relative z-[0] rounded-2xl px-5 py-4 flex flex-col gap-5 h-full">
          {comboboxes.map((box) => (
            <div key={box.id} className="">
              <span className="text-white/50">{box.label}</span>
              <ComboboxTemplate
                options={box.options}
                option={box.option}
                placeholder={box.placeholder}
                onOptionChange={box.onOptionChange}
              />
            </div>
          ))}
          <label className="text-white/50">Device Name</label>
          <div className=" blurred-light select rounded-2xl">
            <input
              className="w-full border-none  py-[14px] px-6 text-sm leading-5 text-whie focus:ring-0 outline-none bg-transparent"
              value={deviceName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setDeviceName(e.target.value);
              }}
            />
          </div>
          <button
            onClick={handelSubmit}
            disabled={[!deviceName, !deviceType?.value, isLoading].includes(true)}
            className="rounded-full bg-sakura-100 text-sakura-900 w-full py-2.5 px-4 mt-auto disabled:bg-gray-300 flex items-center justify-center gap-2">
            <span className="relative inline-block">
              Add Device
              <ArrowPathIcon
                className={`absolute left-full top-1/2 -translate-y-1/2  ml-2 w-4 h-4 transition-transform ${
                  isLoading ? "animate-spin" : "opacity-0"
                }`}
              />
            </span>
          </button>
        </div>
        <div className="flex flex-col text-lg gap-3">
          <p className="font-semibold">Device ID</p>
          <p>{deviceId}</p>
          <p className="font-semibold">Device MAC</p>
          <p>{deviceMac}</p>
          <p className="font-semibold">Device Name</p>
          <p>{deviceName}</p>
        </div>
      </div>
    </div>
  );
}
