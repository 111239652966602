import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Task } from "../types/typings";
import TaskDraggable from "./TasksPageDnD/TaskDraggable";

type SortableTaskItemProps = {
  id: string;
  task: Task;
  selectedIds: string[];
  toggleSelect?: (id: Task) => void;
};

const SortableTaskItem = ({ id, task, selectedIds, toggleSelect }: SortableTaskItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging, active } =
    useSortable({
      id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="pointer-events-auto">
      <TaskDraggable
        isGhosting={!isDragging && !!active?.id && selectedIds.includes(task.id)}
        isDragging={isDragging}
        task={task}
        selectedIds={selectedIds}
        toggleSelect={toggleSelect}
      />
    </div>
  );
};

export default SortableTaskItem;
