import { FlagIcon } from "@heroicons/react/20/solid";
import { taskPriorities } from "dummyData";
import { taskData } from "../UI/Modals/TaskModal";

type Props = {
  priority: string;
  onChange: (newPriority: string) => void;
};
const prioritiesOrder = ["low", "medium", "high"];
export default function TaskPriority({ priority = "low", onChange }: Props) {
  const switchPriority = () => {
    onChange(
      prioritiesOrder?.find(
        (_, index) => index > prioritiesOrder.indexOf(taskData.value.priority)
      ) || "low"
    );
  };
  return (
    <button
      onClick={switchPriority}
      className="w-10 h-10 rounded-full flex border border-gray-500 border-dashed hover:bg-white/10">
      <FlagIcon
        className={`w-5 m-auto ${taskPriorities?.[taskData.value.priority] ?? "text-green-400"}`}
      />
    </button>
  );
}
