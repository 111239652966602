import { HeaderTabType } from "../../types/ui-types";
import MenuItemsDnD from "./MenuItemsDnD";

type Props = {
  items: HeaderTabType[];
};

export default function ExistingMenuItems({ items }: Props) {
  return <MenuItemsDnD notDraggableId={"home"} droppableId={"existing_menu_items"} items={items} />;
}
