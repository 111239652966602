import { taskData } from "components/UI/Modals/TaskModal";
import AsigneeSelect from "components/UI/Selects/AsigneeSelect";

type Props = {};

export default function TaskAsigneeBlock({}: Props) {
  return (
    <div className="">
      <AsigneeSelect
        asigneeList={taskData.value.asigneeList}
        setCategory={(id) => {
          const filtered = taskData.value.asigneeList.filter((userId) => userId !== id);
          if (filtered?.length !== taskData.value.asigneeList?.length) {
            taskData.value = { ...taskData.value, asigneeList: [...filtered] };
          } else {
            taskData.value = { ...taskData.value, asigneeList: [id, ...filtered] };
          }
        }}
      />
    </div>
  );
}
