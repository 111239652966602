import { WidgetType } from "../../types/ui-types";
import WidgetItemsDnd from "./WidgetItemsDnd";

type Props = {
  items: WidgetType[];
};

export default function OtherWidgets({ items }: Props) {
  return (
    <WidgetItemsDnd notDraggableId="create-widget" droppableId={"other_menu_items"} items={items} />
  );
}
