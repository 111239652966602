import { ButtonHTMLAttributes, ReactElement } from "react";
type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactElement | ReactElement[] | string;
  className?: string;
};
export default function BtnPrimary({ children, className, ...rest }: Props) {
  return (
    <button
      className={`border border-transparent px-6 py-2.5 flex items-center justify-center text-sm font-medium rounded-full text-sakura-900 bg-sakura-100 hover:bg-sakura-100/90 ${className}`}
      {...rest}>
      {children}
    </button>
  );
}
