import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logOut } from "../features/authSlice";
export default function LogOutPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOutUser = async () => {
    dispatch(logOut());
    navigate("/");
  };
  useEffect(() => {
    logOutUser();
  }, []);
  return (
    <div className="w-full h-full fixed top-0 left-0 flex text-7xl text-sakura-100 justify-center items-center">
      Loging You Out...
    </div>
  );
}
