import { Signal, signal } from "@preact/signals-react";
import { tasks } from "components/../dummyData";
import { Task, TasksObject } from "components/../types/typings";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  handleSaveTaskChanges,
  onPriorityChange,
  onTaskStatusChange,
  toggleClosed,
} from "utils/functions/taskDetailedHandlers";
import TaskDetailed from "../../Task/TaskDetailed";
import Backdrop from "./Backdrop";
export const initialData: Task = {
  title: "",
  id: Math.random().toString().substring(4, 9),
  description: "",
  priority: "low",
  task_status: "todo",
  asigneeList: [],
  tags: [],
};
export const taskData: Signal<Task> = signal(initialData);
export default function TaskModal() {
  const { taskId } = useParams();

  // const { data: task, isLoading, isError } = useGetTaskQuery(taskId);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    handleSaveTaskChanges();

    navigate("/tasks", { replace: true });
  };

  useEffect(() => {
    const task: Task | undefined = Object.values(tasks.value as TasksObject)
      .flat()
      .find((task: any) => {
        return task.id.toString() === taskId;
      });
    if (task) {
      taskData.value = task;
    } else {
      taskData.value = initialData;
    }
  }, [taskId]);

  return (
    <Backdrop show={true} closeModal={handleCloseModal}>
      <TaskDetailed
        taskData={taskData.value}
        handleClose={handleCloseModal}
        toggleClosed={toggleClosed}
        onTaskStatusChange={onTaskStatusChange}
        onTaskPriorityChange={onPriorityChange}
      />
    </Backdrop>
  );
}
