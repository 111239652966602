import { CheckIcon } from "@heroicons/react/20/solid";
import { UserIcon } from "@heroicons/react/24/outline";
import { Key, useRef, useState } from "react";
import { users } from "../../../dummyData";
import useCloseOnOutsideClick from "../../../hooks/useOutsideClick";
import styles from "../Dropdowns/DropdownTemplate.module.scss";

type Props = {
  asigneeList: any[];
  setCategory: (userId: number) => void;
};
export default function AsigneeSelect({ asigneeList, setCategory }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useCloseOnOutsideClick(dropdownRef, isOpen, setIsOpen);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (userId: number) => {
    setCategory(userId);
    // setIsOpen(false);
  };

  return (
    <div className="">
      <div className="w-full relative" ref={dropdownRef}>
        <button className="w-full" onClick={toggleDropdown}>
          <div className="flex mr-5">
            {asigneeList.map((userId, i) => {
              return (
                <div
                  key={userId}
                  style={{
                    zIndex: asigneeList?.length + 1 - i,
                  }}
                  className="w-5 h-10">
                  <div
                    style={{
                      backgroundColor: users.find((user) => user.userId === userId)?.bg ?? "#000",
                    }}
                    className={`w-10 h-10 rounded-full  flex border-1 ${
                      i !== 0 ? "border-white transition-transform hover:translate-x-1/4" : ""
                    }`}>
                    <span className="m-auto text-white text-sm">
                      {users
                        .filter((user) => user.userId === userId)
                        ?.map(
                          (user: any) => `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
                        )}
                    </span>
                  </div>
                </div>
              );
            })}

            <div style={{ zIndex: 1 }} className="w-5 h-10">
              <div className="w-10 h-10 rounded-full border border-dashed border-gray-500 flex  cursor-pointer transition-transform hover:translate-x-1/4 ">
                <UserIcon className="w-5 m-auto text-gray-500" />
              </div>
            </div>
          </div>
          {/* <img
              className={`w-6 h-6 flex-shrink-0 transition-transform duration-500 ${
                expanded ? "rotate-180" : ""
              }`}
              src={expandIcon}
              alt="Expand"
            /> */}
        </button>

        <ul
          className={`bg-gray-500 z-10 rounded-2xl absolute w-full max-h-[220px] overflow-y-auto transition-all min-w-max mt-2 ${
            styles?.["down"]
          } ${isOpen ? "" : "pointer-events-none opacity-0"}`}>
          {users.map((user) => (
            <li
              className="pl-6 py-2.5 px-2.5 cursor-pointer hover:bg-white/5 relative"
              key={user?.userId as Key}
              onClick={() => handleOptionClick(user.userId)}>
              {user.firstName} {user.lastName}
              {asigneeList.includes(user.userId) && (
                <CheckIcon className="w-3 h-3 absolute top-1/2 left-2  -translate-y-1/2 text-green-400" />
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
