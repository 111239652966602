import { useEffect, useState } from "react";
import { RaySensor } from "../../types/typings";
import { getFillLevel } from "../../utils/deviceHelpers";
import { getIconByDeviceType } from "../../utils/iconGetter";
import DeviceBadge from "./DeviceBadge";

type Props = {
  device: RaySensor;
};

export default function DeviceRaySensor({ device }: Props) {
  const [fillLevel, setFillLevel] = useState(
    getFillLevel({
      min: device.settings.level.min,
      max: device.settings.level.max,
      measurment: device.statistics.measurement,
    })
  );
  useEffect(() => {
    // console.log(
    //   device.name,
    //   {
    //     min: device.settings.level.min,
    //     max: device.settings.level.max,
    //     measurment: device.statistics.measurement,
    //   },
    //   fillLevel
    // );
    const newFillLevel = getFillLevel({
      min: device.settings.level.min,
      max: device.settings.level.max,
      measurment: device.statistics.measurement,
    });
    newFillLevel !== fillLevel && setFillLevel(newFillLevel);
  }, [device]);
  return (
    <DeviceBadge
      caution={
        (device?.type === "waste_bin" ? 100 - parseInt(fillLevel) : parseInt(fillLevel)) < 25
      }
      alert={(device?.type === "waste_bin" ? 100 - parseInt(fillLevel) : parseInt(fillLevel)) < 1}
      model={device.__model}>
      <img
        className="w-full h-auto object-contain "
        src={getIconByDeviceType(device?.__model)}
        alt={device.name}
      />

      <p
        style={{
          color: `hsl(${
            (device?.type === "waste_bin" ? 120 : 0) +
            (device?.type === "waste_bin" ? -1.2 : 1.2) * parseInt(fillLevel)
          }deg, 100%, 60%)`,
        }}
        className={`font-bold leading-none text-stroke`}>
        {fillLevel}%
      </p>
    </DeviceBadge>
  );
}
