import { ButtonHTMLAttributes, ReactElement } from "react";
type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactElement | ReactElement[] | string;
  className?: string;
};
export default function BtnBlurred({ children, className, ...rest }: Props) {
  return (
    <button
      className={` px-6 py-2.5 flex items-center justify-center text-sm font-medium rounded-full blurred-light select hover:bg-white/10 transition-all ${className}`}
      {...rest}>
      {children}
    </button>
  );
}
