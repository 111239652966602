import { Environment, Html, OrbitControls, PerformanceMonitor, Stats } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Classroom from "./Classroom";
import CreateArea from "./CreateArea";
import DevicesLayer from "./DevicesLayer";
import GroupsLayer from "./GroupsLayer";

const CamerControlPosition = () => {
  const { camera, controls, setFrameloop } = useThree();
  const { pickedDevice: pickedFromSearch } = useSelector((state: any) => state.ui);
  const { picked_location } = useSelector((state: any) => state.auth);
  const updateCameraPosition = useCallback(() => {
    console.log("Reset Position fired");
    if (controls) {
      setFrameloop("always");
      //@ts-ignore
      controls.target.set(0, 0, 0);
      camera.position.set(0, 2, 0);
      setFrameloop("demand");
    }
  }, [controls, camera]);

  useEffect(() => {
    console.log("PICKED FROM SEARCH", pickedFromSearch);
    if (pickedFromSearch) {
      //@ts-ignore
      const { x, y: z } = pickedFromSearch.map;
      //@ts-ignore
      controls.target.set(x, 0, z);
      camera.position.set(x, 2, z);
    }
  }, [pickedFromSearch]);
  useEffect(() => {
    console.log("picked location changed");
    if (controls) {
      setFrameloop("always");
      //@ts-ignore
      controls.target.set(0, 0, 0);
      camera.position.set(0, 2, 0);
      setFrameloop("demand");
    }
  }, [picked_location?.id, camera, controls]);
  return (
    <>
      <Html className="fixed top-0 z-20 right-0 ">
        <button
          id="reset-position"
          className="min-w-max bg-black rounded text-lg hidden"
          onClick={updateCameraPosition}>
          Reset Position
        </button>
      </Html>
    </>
  );
};

const Lights = () => {
  return (
    <>
      <ambientLight intensity={0.1} castShadow />
    </>
  );
};

const CustomOrbitControls = () => {
  const { pickedDevice } = useSelector((state: any) => state.ui);
  return (
    <OrbitControls
      makeDefault
      enablePan={!pickedDevice}
      enableRotate={false}
      minDistance={0}
      maxDistance={5}
    />
  );
};
const maps: { [key: string]: string } = {
  "663c9a6b65f2c09fd8821176": "/models/the-edge.glb",
  // "64886e63984188bea7de4c82": "/models/the-edge.glb",
  "649a9ad5210aa0308465f689": "/models/classroom/map3.glb",
};
const MainView = () => {
  const location = useLocation();
  const { picked_location } = useSelector((state: any) => state.auth);
  const currentMapSrc: string | undefined = maps?.[picked_location?.id] || "/models/plain.glb"; // Ensure currentMapSrc is undefined if no map available

  return (
    <>
      <PerformanceMonitor />
      <CustomOrbitControls />
      <Lights />
      <CamerControlPosition />
      {false && !location.pathname?.includes("https") && <axesHelper />}

      {false && <Stats showPanel={2} />}

      {currentMapSrc !== undefined && <GroupsLayer />}
      <DevicesLayer />

      <CreateArea>
        {currentMapSrc !== undefined ? (
          <Classroom mapSrc={currentMapSrc} />
        ) : (
          <Html>No Map Provided</Html>
        )}
      </CreateArea>

      <Environment preset="warehouse" />
    </>
  );
};

export default MainView;
