import StatusSelect from "components/UI/Selects/StatusSelect";
import { statuses } from "dummyData";
import { useEffect, useState } from "react";
import { Device, Notification } from "types/typings";
import DeviceIcon from "./DeviceIcon";
import DeviceStats from "./DeviceStats";

type Props = {
  pickedDevice: Notification;
  clearDevice: () => void;
  handleSave: (deviceData: Notification) => void;
};

export default function AboutDevice({ pickedDevice, clearDevice, handleSave }: Props) {
  const [deviceData, setDeviceData] = useState<Notification>(pickedDevice);
  useEffect(() => {
    setDeviceData(pickedDevice);
  }, [pickedDevice]);
  const handleBackClick = () => {
    clearDevice();
  };
  return (
    <div className="AboutDevice blurred-dark  rounded-3xl p-3 flex flex-col gap-4 h-full w-full overflow-hidden pointer-events-auto z-10">
      <div className="flex items-start justify-between">
        <button
          className="rounded-full text-sm py-2.5 px-4 bg-plum min-w-max w-20"
          onClick={handleBackClick}>
          Close
        </button>
        <StatusSelect
          status={statuses.find((option) => option.value === deviceData.status) || statuses[0]}
          setStatus={(status) => {
            setDeviceData({ ...pickedDevice, status });
          }}
        />
      </div>
      <div className="grid grid-cols-[1.75fr,1fr] gap-4 h-1/2 flex-grow flex-shrink grid-rows-[max-content,1fr] auto-rows-min">
        <div className="blurred-dark select rounded-2xl p-4">
          <DeviceStats device={deviceData as unknown as Device} />
        </div>
        <DeviceIcon icon={deviceData.icon} name={deviceData.name} />
        <div className="blurred-dark select rounded-2xl p-4 h-full">
          <p>Some description about this device...</p>
        </div>
        <div className="blurred-dark select rounded-2xl p-4 w-full flex flex-col gap-4 h-full overflow-hidden">
          <p>Updates:</p>
          <div className="flex flex-col h-1/2 flex-grow gap-4 overflow-y-scroll">
            <div className="">
              <p className="text-lg">John Doe | 02.10.2023 12:45</p>
              <p className="text-sm">Dispenser is fixed.</p>
            </div>
            <div className="">
              <p className="text-lg">John Doe | 02.10.2023 12:45</p>
              <p className="text-sm">Dispenser is fixed.</p>
            </div>
            <div className="">
              <p className="text-lg">John Doe | 02.10.2023 12:45</p>
              <p className="text-sm">Dispenser is fixed.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 w-max max-w-[80%] grid grid-cols-2 gap-4 h-max text-sm">
        <button className="blurred-dark select rounded-xl py-2.5 px-3 w-full">
          Battery Changed
        </button>{" "}
        <button className="blurred-dark select rounded-xl py-2.5 px-3 w-full">Refilled</button>{" "}
        <button className="blurred-dark select rounded-xl py-2.5 px-3 w-full">
          Device Replaced
        </button>
        <button
          className="rounded-full bg-sakura-100 text-sakura-900 col-span-2 text-center py-2.5 px-4"
          onClick={() => {
            handleSave(deviceData);
          }}>
          Save Changes
        </button>
      </div>
    </div>
  );
}
