import DevicesSearch from "../components/DevicesSearch/DevicesSearch";
import WidgetsHome from "../components/Widgets/WidgetsHome";

export default function HomePage() {
  return (
    <div className="Home flex gap-4 xl:gap-8 ">
      <WidgetsHome />
      {/* <Floors /> */}
      <div className="w-[39.5%] h-max flex-shrink-0 ">
        <DevicesSearch />
      </div>
    </div>
  );
}
