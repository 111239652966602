import expandIcon from "assets/icons/expand-more.svg";
import { useState } from "react";
import { statuses } from "../../../dummyData";
import { Option } from "../../../types/ui-types";
import Select from "./SelectTemplate";

type Props = {
  status: Option;
  setStatus: (option: string) => void;
  available_statuses?: Option[];
};
export default function StatusSelect({ status, setStatus, available_statuses }: Props) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="w-full max-w-[126px]">
      <Select
        options={available_statuses ?? statuses}
        onSelect={(status) => {
          setStatus(status.value as string);
          setExpanded(false);
        }}
        toggleMenu={(val) => {
          setExpanded(val);
        }}>
        <div className="blurred-light select  w-full flex items-center justify-between gap-2 h-12 rounded-2xl px-2.5 ">
          <span className={`whitespace-nowrap overflow-ellipsis overflow-hidden inline-block`}>
            {status.label}
          </span>
          <img
            className={`w-6 h-6 flex-shrink-0 transition-transform duration-500 ${
              expanded ? "rotate-180" : ""
            }`}
            src={expandIcon}
            alt="Expand"
          />
        </div>
      </Select>
    </div>
  );
}
