import { GROUP } from "constants/api";
import { GroupType, NewGroupType, UpdateGroupRequestType } from "../../types/typings";
import { apiSlice } from "./apiSlice";

export const groupSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: (str) => {
        return "/group";
      },
      providesTags: (res, err, arg) => [
        { type: GROUP, id: "list" },
        ...(Array.isArray(res) ? res : []).map((r: GroupType) => ({ type: GROUP, id: r.id })),
      ],
    }),
    getGroup: builder.query({
      query: (id) => {
        return `/group/${id}`;
      },
      providesTags: (res, err, arg) => [{ type: GROUP, id: res.id }],
    }),
    updateGroup: builder.mutation({
      query: (initialDevice: UpdateGroupRequestType) => ({
        url: `/group/${initialDevice.id}`,
        method: "PATCH",
        body: {
          ...initialDevice,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: GROUP, id: arg.id }],
    }),
    addGroup: builder.mutation({
      query: (newGroup: NewGroupType) => ({
        url: `/group`,
        method: "POST",
        body: {
          ...newGroup,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: GROUP, id: "list" }],
    }),
    deleteGroup: builder.mutation({
      query: (groupId: string) => ({
        url: `/group/${groupId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: GROUP, id: "list" }],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useGetGroupQuery,
  useUpdateGroupMutation,
  useAddGroupMutation,
  useDeleteGroupMutation,
} = groupSlice;
