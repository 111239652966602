export const API = {
  TASK: "task",
  NOTIFICATION: "notification",
  DEVICE: "device",
  GROUP: "group",
  ALERT: "alert",
  USER: "user",
  LOCATION: "location",
};

export const { USER, ALERT, TASK, NOTIFICATION, DEVICE, LOCATION, GROUP } = API;
