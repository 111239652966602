import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { HTMLAttributes } from "react";
import { WidgetType } from "../../types/ui-types";
import StatBlock from "../Widgets/WidgetBlock";

type Props = {
  widget: WidgetType;
  notDraggable: boolean;
};

export default function WidgetSortableItem({ widget, notDraggable }: Props) {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: widget.id,
    transition: {
      duration: 250, // milliseconds
      easing: "cubic-bezier(0.25, 1, 0.5, 1)",
    },
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
    cursor: isDragging ? "grabbing" : "pointer",
  };
  const dragAttributes = !notDraggable ? attributes : {};
  const dragListeners = !notDraggable ? listeners : {};

  return (
    <div
      key={widget.id}
      id={widget.id}
      ref={notDraggable ? null : setNodeRef}
      style={style}
      {...dragAttributes}
      {...dragListeners}>
      <StatBlock widget={widget} isDragging={isDragging} />
    </div>
  );
}

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  withOpacity?: boolean;
  isDragging?: boolean;
};
