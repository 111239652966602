import { Signal, signal } from "@preact/signals-react";
import { useEffect } from "react";
import SettingsAbout from "../../components/SettingsPage/SettingsAbout";
import SettingsSearchList from "../../components/SettingsPage/SettingsSearchList";
import { useGetUsersQuery } from "../../features/api/usersSlice";
import { UserType } from "../../types/typings";
export const selectedUser: Signal<UserType | null> = signal(null);
export default function SettingsUsersPage() {
  const { data: users, isLoading, isError, isSuccess } = useGetUsersQuery("users-list");
  const onUserClick = (item: UserType) => {
    selectedUser.value = item;
  };
  const onUserUpdate = () => {};
  useEffect(() => {
    selectedUser.value = isSuccess ? users?.[0] : null;
  }, [isSuccess, users]);
  return (
    <div className="w-full h-full overflow-hidden grid grid-cols-[1.2fr,1fr] gap-6">
      <SettingsSearchList
        isLoading={isLoading}
        isError={isError}
        list={users}
        onItemClick={onUserClick}
        selectedItemId={selectedUser.value?.id ?? users?.[0]?.id}
      />
      <SettingsAbout item={selectedUser.value} onUserUpdate={onUserUpdate} />
    </div>
  );
}
