import { Text } from "@react-three/drei";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DoubleSide, Mesh, MeshBasicMaterial, Shape, ShapeGeometry } from "three";
import { degToRad } from "three/src/math/MathUtils";
import { setDots, switchIsDotDragging } from "../../features/areaSlice";
import DraggableInstance from "./Draggableinstance";

interface Props {}

export const CustomPlane = ({}: Props) => {
  const { dots } = useSelector((state: any) => state.area);

  const filledAreaRef = useRef<Mesh>(null);
  const filledAreaMaterial = new MeshBasicMaterial({
    color: "green",
    transparent: true,
    opacity: 0.2,
    side: DoubleSide,
    depthTest: false,
  });
  const dispatch = useDispatch();
  const handleChangeArea = () => {
    if (filledAreaRef.current && dots.length > 0) {
      const shape = new Shape();

      // Move to the first point
      const { x: firstX, y: firstY, z: firstZ } = dots[0];
      shape.moveTo(firstX, -firstZ);

      // Line to all other points
      for (let i = 1; i < dots.length; i++) {
        const { x, y, z } = dots[i];
        shape.lineTo(x, -z);
      }

      // Close the shape by connecting the last point to the first point
      shape.lineTo(firstX, -firstZ);

      // Generate a 3D polygon geometry from the shape

      const filledAreaGeometry = new ShapeGeometry(shape);
      filledAreaGeometry.rotateX(-Math.PI / 2);
      filledAreaRef.current.geometry.dispose();

      filledAreaRef.current.geometry = filledAreaGeometry;
    }
  };
  useEffect(() => {
    handleChangeArea();
  }, [dots]);

  const handleUpdate = (id: string, pos: any) => {
    dispatch(setDots([...dots].map((dot, i) => (id === dot.id ? { id, ...pos } : dot))));
  };
  return (
    <>
      {/* Filled area mesh */}
      <mesh ref={filledAreaRef} material={filledAreaMaterial} position={[0, 0, 0]} />

      {dots.map((dot: any, index: number) => {
        return (
          <DraggableInstance
            key={dot.id}
            item={dot}
            position={[dot.x, dot.y, dot.z]}
            handleDragStart={(boolean) => {
              dispatch(switchIsDotDragging(boolean));
            }}
            handleUpdate={(pos) => {
              handleUpdate(dot.id, pos);
            }}>
            <Text
              outlineWidth={0.06}
              outlineOffsetX={0.01}
              outlineColor={"black"}
              color="green"
              anchorX="center"
              anchorY="middle"
              rotation={[degToRad(-90), 0, degToRad(0)]}
              position={[0, 0.05, -0.01]}
              scale={0.015}>
              {index + 1}
            </Text>

            <sphereGeometry args={[0.005, 4, 4]} />
            <meshBasicMaterial color="green" />
          </DraggableInstance>
        );
      })}
    </>
  );
};
