import { USER } from "constants/api";
import { UserType } from "../../types/typings";
import { apiSlice } from "./apiSlice";

export const usersSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => "/users",

      providesTags: (res, err, arg) => [
        { type: USER, id: "list" },
        ...res.map((r: UserType) => ({ type: USER, id: r.id })),
      ],
    }),
    updateUser: builder.mutation({
      query: (userData: UserType) => ({
        url: `/users/${userData.id}`,
        method: "PUT",
        body: {
          ...userData,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: USER, id: arg.id }],
    }),
    addUser: builder.mutation({
      query: (initialDevice: UserType) => ({
        url: `/users`,
        method: "POST",
        body: {
          ...initialDevice,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: USER, id: "list" }],
    }),
  }),
});

export const { useGetUsersQuery, useUpdateUserMutation, useAddUserMutation } = usersSlice;
