import { Signal, computed, effect, signal } from "@preact/signals-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { categories } from "../../dummyData";
import { Device } from "../../types/typings";
import { filterByKeys } from "../../utils/helpers";
import SearchBar from "../UI/SearchBar";
import CategorySelect from "../UI/Selects/CategorySelect";
import DeviceAbout from "./DeviceAbout";
import DeviceListActions from "./DeviceListActions";
import DevicesList from "./DevicesList";
import DeviceTree from "./DeviceTree";
// import SearchBar from "../UI/SearchBar";
// import CategorySelect from "../UI/Selects/CategorySelect";
// import DeviceAbout from "./DeviceAbout";
// import DeviceListActions from "./DeviceListActions";
// import DeviceTree from "./DeviceTree";
// import DevicesList from "./DevicesList";

//Signals to temporary store devices and handle inner state
export const appTree = signal([]);
export const groupId = signal(undefined);
export const devicesList = signal([]);
export const devicesFilter = signal({
  filter_building: "",
  filter_location: "",
  filter_type: "",
  searchStr: "",
});
export const selectedDevicesIds: Signal<string[]> = signal([]);
export const filteredDevicesList: Signal<Device[]> = computed(() => {
  const { filter_building, filter_location, filter_type, searchStr } = devicesFilter.value;
  const preFiltered = filterByKeys(searchStr, ["name"], devicesList.value);
  const postFiltered = preFiltered.filter((device: Device) => {
    const { location_organization, category } = device;
    //@ts-ignore
    const locationMatch = filter_location ? location_organization === filter_location : true;
    const typeMatch = filter_type ? category === filter_type : true;
    return true;
    //return [locationMatch, typeMatch].every(Boolean);
  });

  return postFiltered;
});
export const aboutDevice: Signal<Device> = signal(devicesList.value[0]);
effect(() => {
  aboutDevice.value = filteredDevicesList.value[0];
});

export default function DeviceCatalogue() {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const closeDropdownOnOutsideClick = (e: MouseEvent | TouchEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      const isDeviceCatClicked = e.target instanceof Element && e.target.closest("a");
      console.log("outside click");
      // If the button is clicked, cancel the scheduled outside click logic
      if (isDeviceCatClicked) {
        return;
      }

      // setIsOpen(false);
      navigate("/");
    }
  };

  const addOutsideClickListeners = () => {
    document.addEventListener("mousedown", closeDropdownOnOutsideClick);
    document.addEventListener("touchstart", closeDropdownOnOutsideClick);
  };

  const removeOutsideClickListeners = () => {
    document.removeEventListener("mousedown", closeDropdownOnOutsideClick);
    document.removeEventListener("touchstart", closeDropdownOnOutsideClick);
  };

  useEffect(() => {
    if (isOpen) {
      addOutsideClickListeners();
    } else {
      removeOutsideClickListeners();
    }

    return () => {
      removeOutsideClickListeners();
    };
  }, []);

  return false ? (
    <></>
  ) : (
    <div className="w-full h-full  z-20" ref={dropdownRef}>
      <div
        className={`blurred-dark z-10 rounded-3xl  w-full h-full  ${
          isOpen ? "pointer-events-auto " : "pointer-events-none opacity-0"
        }`}>
        <div className="grid grid-cols-[minmax(200px,0.5fr),2fr,1.5fr] gap-3 w-full h-full overflow-hidden p-5 ">
          <DeviceTree />

          <div className="w-full h-full overflow-hidden flex flex-col gap-4">
            <div className="flex gap-4">
              <div className="blurred-light select rounded-xl flex-grow w-1/2">
                <SearchBar
                  value={devicesFilter.value.searchStr}
                  setValue={(str: string) => {
                    devicesFilter.value = { ...devicesFilter.value, searchStr: str };
                  }}
                />
              </div>
              <div className="w-max flex-shrink-0">
                <CategorySelect category={categories[0]} setCategory={() => {}} />
              </div>
            </div>
            <DeviceListActions />
            {groupId.value && <DevicesList />}
          </div>
          <div className="">
            <DeviceAbout device={aboutDevice.value} />
          </div>
        </div>
      </div>
    </div>
  );
}
