import { FlagIcon } from "@heroicons/react/20/solid";

import dragIcon from "assets/icons/drag-indicator.svg";
import CheckBox from "components/UI/CheckBox";
import { taskPriorities } from "dummyData";
import { Link } from "react-router-dom";
import { Task } from "types/typings";
import styles from "./TaskDraggable.module.scss";
type Props = {
  task: Task;
  selectedIds: string[];
  toggleSelect?: (id: Task) => void;
  isDragging: boolean;
  isGhosting: boolean;
};

export default function TaskDraggable({
  task,
  isDragging,
  isGhosting,
  toggleSelect = () => {},
  selectedIds,
}: Props) {
  const isSelected = selectedIds.some((id) => task.id === id);
  const isMultipleSelected = selectedIds?.length > 1;

  const handleCheckBoxToggle = () => {
    toggleSelect(task);
  };
  return (
    <div
      key={task.id}
      className={`${styles.task} blurred-dark task rounded-xl mb-3 ${
        !isDragging && isGhosting ? styles.isGhosting : ""
      } ${isDragging ? styles.isDragged : ""} `}>
      <CheckBox
        title={"Select Task"}
        id={task.id}
        isChecked={isSelected}
        toggleSelect={handleCheckBoxToggle}
      />
      <Link to={`${task.id}`} className="ml-1 whitespace-nowrap text-ellipsis overflow-hidden">
        {task.title}
      </Link>
      <button>
        <FlagIcon
          className={`w-4 self-center ${taskPriorities?.[task.priority] ?? "text-green-400"}`}
        />
      </button>
      <div className="w-full">
        <img src={dragIcon} alt="Drag Task" />
      </div>
      <div
        className={`${styles.badge} ${isDragging && isMultipleSelected ? styles.showBadge : ""}`}>
        {selectedIds?.length}
      </div>
    </div>
  );
}
