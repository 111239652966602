import plusIcon from "assets/icons/add.svg";
import alertsIcon from "assets/icons/alerts.svg";
import homeIcon from "assets/icons/home.svg";
import notifyIcon from "assets/icons/notifications.svg";
import tasksIcon from "assets/icons/tasks.svg";
import { useGetAlertsQuery } from "features/api/alertsSlice";
import { useGetNotificationsQuery } from "features/api/notificationsSlice";
import { HeaderTabType } from "types/ui-types";

export const initial_header_tabs: HeaderTabType[] = [
  { id: "home", label: "Home", iconSrc: homeIcon, to: "/", addClass: "" },
  // {
  //   id: "notifications",
  //   label: "Notifications",
  //   iconSrc: notifyIcon,
  //   to: "/notifications",
  //   getAction: useGetNotificationsQuery,
  //   getActionId: "getNotifications",
  //   addClass: "",
  // },
  // {
  //   id: "alerts",
  //   label: "Alerts",
  //   iconSrc: alertsIcon,
  //   to: "/alerts",
  //   addClass: "alerts",
  //   getAction: useGetAlertsQuery,
  //   getActionId: "getAlerts",
  // },
  //   { label: "Tasks", iconSrc: tasksIcon, to: "/tasks", addClass: "" },
];
export const all_header_tabs: HeaderTabType[] = [
  {
    id: "create-menu-item",
    label: "Create",
    iconSrc: plusIcon,
    to: "/",
    addClass: "button_create",
  },
  { id: "home", label: "Home", iconSrc: homeIcon, to: "/", addClass: "" },
  {
    id: "notifications",
    label: "Notifications",
    iconSrc: notifyIcon,
    to: "/notifications",
    getAction: useGetNotificationsQuery,
    getActionId: "getNotifications",
    addClass: "",
  },
  {
    id: "alerts",
    label: "Alerts",
    iconSrc: alertsIcon,
    to: "/alerts",
    addClass: "alerts",
    getAction: useGetAlertsQuery,
    getActionId: "getAlerts",
  },
  { id: "tasks", label: "Tasks", iconSrc: tasksIcon, to: "/tasks", addClass: "" },
  { id: "devices", label: "devices", iconSrc: tasksIcon, to: "/tasks", addClass: "" },
];
