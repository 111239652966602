import { UserIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { UserType } from "../../types/typings";
import BtnOutlined from "../UI/Buttons/BtnOutlined";
import BtnPrimary from "../UI/Buttons/BtnPrimary";
import SettingsAboutHeader from "./SettingsAboutHeader";
import UserSettingsEditModal from "./UserSettingsEditModal";

type Props = {
  item: UserType | null;
  onUserUpdate: () => void;
};

export default function SettingsAbout({ item, onUserUpdate }: Props) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleSaveClick = () => {
    onUserUpdate();
  };
  return item ? (
    <div className="blurred-light z-[1] rounded-2xl w-full h-max max-h-full overflow-hidden flex flex-col">
      <SettingsAboutHeader item={item} />
      <div className="w-full h-44 bg-gray-400/10">
        <UserIcon className="h-full w-auto text-gray-400/30 mx-auto" />
      </div>

      <p className="p-4">Some random info about location</p>
      <p className="capitalize p-4 text-white/60">{item.role} @ Some location</p>

      <div className="w-full flex justify-end gap-2 mt-auto p-4">
        <BtnOutlined
          onClick={() => {
            setShowModal(true);
          }}>
          Edit
        </BtnOutlined>
        <BtnPrimary>Save</BtnPrimary>
      </div>
      <UserSettingsEditModal show={showModal} userData={item} closeModal={handleCloseModal} />
    </div>
  ) : (
    <></>
  );
}
