import { XMarkIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./SnackBar.module.scss";
type Props = {};

export default function SnackBar({}: Props) {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const dispatch = useDispatch();
  const { snackbar } = useSelector((state: any) => state.ui);
  let timeout: any;

  useEffect(() => {
    if (snackbar) {
      setShowSnackBar(true);
      timeout = setTimeout(() => {
        setShowSnackBar(false);
      }, 7000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [snackbar]);
  return (
    <div
      className={`${styles.snackbar} ${
        snackbar?.type ? styles?.[snackbar?.type] ?? "blurred-light" : "blurred-light"
      }  ${showSnackBar ? styles.show : styles.hide}`}>
      <p className="text-lg leading-none">{snackbar?.message}</p>{" "}
      <XMarkIcon
        onClick={() => {
          setShowSnackBar(false);
        }}
        className="w-6 text-inherit pointer-events-auto"
      />
    </div>
  );
}
