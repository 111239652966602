import Logo from "assets/ismart-logo.svg";
import SigninForm from "../components/Forms/SigninForm";
import SignInLayout from "../layouts/SignInLayout";
import styles from "./SigninPage.module.scss";
export default function SigninPage() {
  return (
    <SignInLayout>
      <div className={`${styles.hero_section}`}>
        <div className="">
          {/* <h1 className="mb-4 text-2xl xl:text-5xl 2xl:text-7xl leading-none">
            Lorem Ipsum is simply dummy text of the.{" "}
          </h1>
          <h3 className="text-2xl">
            Scrambled it to make a type specimen book. It has survived not only five centuries, but
            also the leap
          </h3> */}
          <img className="w-full grayscale invert justify-center" src={Logo} alt="ISmart" />
        </div>
      </div>
      <div className={`${styles.form_section}`}>
        <h3 className="text-4xl text-[#2F2F2F] mb-6 xl:mb-10 2xl:mb-16">Sign in</h3>
        <SigninForm styles={styles} />
      </div>
    </SignInLayout>
  );
}
