import { ReactElement } from "react";

type Props = {
  children: ReactElement | ReactElement[] | string | false;
  caution?: boolean;
  alert?: boolean;
  model?: string;
};

export default function DeviceBadge({ children, caution, alert, model }: Props) {
  return (
    <div className={`cursor-pointer  w-2  h-2 rounded-full relative ${model} `}>
      <div
        className={`relative w-10 min-h-[40px] p-1 pb-3 flex flex-col gap-1 left-1/2 top-1/2 -translate-x-1/2 -translate-y-full items-center justify-center device ${
          alert ? "alert" : caution ? "caution" : ""
        }`}>
        <div className="inner-bg bg"></div>
        {children}
      </div>
    </div>
  );
}
