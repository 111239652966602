//import expandIcon from "assets/icons/expand.svg";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { categories } from "../../dummyData";
import { useGetDevicesQuery } from "../../features/api/deviceSlice";
import { pickDeviceFromSearch } from "../../features/uiSlice";
import { Device } from "../../types/typings";
import { Option } from "../../types/ui-types";
import { filterByKeys } from "../../utils/helpers";
import { getIconByDeviceType } from "../../utils/iconGetter";
import SearchBar from "../UI/SearchBar";
import CategorySelect from "../UI/Selects/CategorySelect";
import styles from "./DevicesSearch.module.scss";
export default function DevicesSearch() {
  const [category, setCategory] = useState<Option>(categories[0]);
  const [searchStr, setSearchStr] = useState<string>("");
  const [results, setResults] = useState<Device[]>([]);
  const { data: devices, isLoading, isError } = useGetDevicesQuery("getDevices");
  const dispatch = useDispatch();
  useEffect(() => {
    !isLoading && !isError && searchStr?.length > 2
      ? setResults(
          filterByKeys(searchStr, ["name", "category"], devices as Device[]).filter((res: any) =>
            category?.value ? res?.location === category.value : true
          )
        )
      : setResults([]);
  }, [category, searchStr, isError, isLoading, devices]);

  return (
    <div className="flex gap-2 lg:gap-4 pointer-events-auto relative z-10">
      <div className="w-[126px]">
        <CategorySelect category={category} setCategory={setCategory} />
      </div>
      <div className="blurred-light rounded-2xl w-1/2 flex-grow justify-center h-max">
        <SearchBar value={searchStr} setValue={setSearchStr} />

        <div
          className={`${styles.resultsWrapper} ${
            searchStr?.length > 2 ? styles.expand : ""
          } border-t-2 border-t-asphalt-100`}>
          <div className="w-full overflow-y-auto">
            {results?.length > 0 ? (
              results.map((res) => (
                <button
                  className={styles.card}
                  key={res?.id}
                  onClick={() => {
                    dispatch(pickDeviceFromSearch(res));
                  }}>
                  <img
                    className={styles.icon}
                    src={getIconByDeviceType(res.__model)}
                    alt={res.name}
                  />
                  <p className={styles.cardStatus}>{res.name}</p>
                  <p title={`${res.location_organization?.name}`} className={styles.cardName}>
                    {`${res?.type}`}
                  </p>
                </button>
              ))
            ) : (
              <p className="h-20 text-center align-middle px-4 py-3">
                no results match your search
              </p>
            )}
          </div>
        </div>
      </div>
      {/* <button className="blurred-light h-12 rounded-2xl px-3 text-sm flex justify-center items-center gap-2 flex-shrink-0 ">
        <img className="w-6 h-6" src={expandIcon} alt="Expand" />
        Expand
      </button> */}
    </div>
  );
}
