import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";

import { useDroppable } from "@dnd-kit/core";
import { WidgetType } from "types/ui-types";
import WidgetSortableItem from "./WidgetSortableItem";
type Props = {
  items: WidgetType[];
  droppableId: string;
  notDraggableId?: string;
};

export default function WidgetItemsDnd({ items, droppableId, notDraggableId }: Props) {
  const { setNodeRef } = useDroppable({
    id: droppableId,
  });
  return (
    <SortableContext id={droppableId} items={items} strategy={rectSortingStrategy}>
      <div ref={setNodeRef} className="w-full flex gap-4 ">
        {items?.length &&
          items.map((tab, i) => (
            <WidgetSortableItem
              notDraggable={tab.id === notDraggableId}
              key={tab.id}
              widget={tab}
            />
          ))}
      </div>
    </SortableContext>
  );
}
