import { DragEndEvent, DragOverEvent } from "@dnd-kit/core";
import formatRelative from "date-fns/formatRelative";
import enGB from "date-fns/locale/en-GB";
import { Device, Notification, Task, TasksObject } from "../types/typings";

export function filterByKeys<T, K extends keyof T>(
  searchStr: string,
  keysToSearch: K[],
  data: T[]
): T[] {
  const searchString = searchStr.toLowerCase();

  return data.filter((item) => {
    return keysToSearch.some((key) => {
      const propValue = item[key] as string;
      if (typeof propValue === "string") {
        return propValue.toLowerCase().includes(searchString);
      }
      return false;
    });
  });
}
export function filterByCategory<T, K extends keyof T>(value: string, category: K, data: T[]): T[] {
  return value ? data.filter((item) => item?.[category] === value) : data;
}

// Utility function to sort an array of objects by the "time" field
export function sortItemsByDate(items: Notification[], asc = true) {
  const compareFunction = (a: Notification, b: Notification) => {
    const dateA = new Date(a.time);
    const dateB = new Date(b.time);

    if (asc) {
      return dateA.getTime() - dateB.getTime();
    } else {
      return dateB.getTime() - dateA.getTime();
    }
  };

  return items.sort(compareFunction);
}
interface GroupedNotification {
  date: string;
  arr: Notification[];
}
// Utility function to split an array into objects with "date" and "arr" fields
export function splitArrayByDate(items: Notification[]): GroupedNotification[] {
  const result: GroupedNotification[] = [];
  let currentDate: string | null = null;
  let currentGroup: GroupedNotification | null = null;

  sortItemsByDate(items).forEach((item) => {
    const itemDate = item.time.split("T")[0] as string; // Type assertion
    if (itemDate !== currentDate) {
      if (currentGroup) {
        result.push(currentGroup);
      }
      currentDate = itemDate;
      currentGroup = { date: currentDate, arr: [] };
    }
    currentGroup!.arr.push(item);
  });

  if (currentGroup) {
    result.push(currentGroup);
  }

  return result
    .sort((a, b) => {
      return a.date.localeCompare(b.date);
    })
    .reverse();
}

// Date related helpers
export function distanceToToday(timestamp: string) {
  const formatRelativeLocale = {
    lastWeek: "'Last' eeee",
    yesterday: "'Yesterday'",
    today: "'Today'",
    tomorrow: "'Tomorrow'",
    nextWeek: "'Next' eeee",
    other: "dd.MM.yyyy",
  };

  const locale = {
    ...enGB,
    formatRelative: (token: string) =>
      formatRelativeLocale[token as keyof typeof formatRelativeLocale],
  };

  return formatRelative(new Date(timestamp), new Date(), { locale });
}

//Tasks Page sorter for api call result
// Utility function to sort an object of arrays by the "priority" field
const priorityOrder: { [key: string]: number } = { low: 0, medium: 1, high: 2 };

export const sortTasksByPriority = (a: Task, b: Task) => {
  const priorityA = priorityOrder[a.priority];
  const priorityB = priorityOrder[b.priority];

  return priorityB - priorityA;
};
export function sortTasksObjByPriority(tasksObject: TasksObject, asc = false) {
  const sortedTasksObject: TasksObject = {};

  for (const listKey in tasksObject) {
    if (tasksObject.hasOwnProperty(listKey)) {
      const unsortedList = tasksObject[listKey];
      const sortedList = unsortedList.map((task) => ({ ...task }));

      sortedList.sort(sortTasksByPriority);

      sortedTasksObject[listKey] = sortedList;
    }
  }

  return sortedTasksObject;
}
export function generateRandomGrayHexCode() {
  const darkGray = 32;
  const lightGray = 200;

  const red = Math.floor(Math.random() * (lightGray - darkGray + 1) + darkGray);
  const green = Math.floor(Math.random() * (lightGray - darkGray + 1) + darkGray);
  const blue = Math.floor(Math.random() * (lightGray - darkGray + 1) + darkGray);

  const hexCode = `#${red.toString(16).padStart(2, "0")}${green.toString(16).padStart(2, "0")}${blue
    .toString(16)
    .padStart(2, "0")}`;

  return hexCode;
}

export const organizeDevicesByTypeAndLocation = (buildings: any[], devices: Device[]) => {
  const organizedData: any[] = [];

  buildings.forEach((building: any) => {
    const organizedBuilding = { ...building };
    organizedBuilding.items.forEach((floor: any) => {
      const deviceTypes: { type: string; items: Device[] }[] = [];

      devices.forEach((device: Device) => {
        if (device.location_organization.name === floor.value) {
          const deviceTypeIndex = deviceTypes.findIndex((dt) => dt.type === device.category);

          if (deviceTypeIndex === -1) {
            // Device type not found, create a new entry
            deviceTypes.push({
              type: device.category,
              items: [device],
            });
          } else {
            // Device type found, add the device to the existing entry
            deviceTypes[deviceTypeIndex].items.push(device);
          }
        }
      });

      floor.device_types = deviceTypes;
    });

    organizedData.push(organizedBuilding);
  });

  return organizedData;
};
export const extractDragDetails = (
  event: DragOverEvent | DragEndEvent
): {
  sourceContainerId: string;
  destContainerId: string;
  activeIdx: number;
  overIdx: number;
} => {
  const sourceContainerId = event.active?.data?.current?.sortable?.containerId;
  const destContainerId = event.over?.data?.current?.sortable?.containerId || event.over?.id;
  const activeIdx = event.active?.data?.current?.sortable?.index;
  const overIdx = event.over?.data?.current?.sortable?.index ?? 1;

  return {
    sourceContainerId,
    destContainerId,
    activeIdx,
    overIdx,
  };
};

// Helper function to move selected items between containers
export const moveSelectedItems = (
  tasksValue: TasksObject,
  sourceContainerId: string,
  destContainerId: string,
  selectedIds: string[],
  overId: string
) => {
  const sourceItems = tasksValue[sourceContainerId] || [];
  const destinationItems = tasksValue[destContainerId] || [];
  const selectedItems = sourceItems.filter((item: any) => selectedIds.includes(item.id));

  const updatedSourceList = sourceItems.filter((item: any) => !selectedIds.includes(item.id));
  const overIndex = destinationItems.findIndex((item: any) => item.id !== overId);

  return {
    ...tasksValue,
    [sourceContainerId]: [...updatedSourceList],
    [destContainerId]: [
      ...destinationItems.slice(0, overIndex),
      ...selectedItems,
      ...destinationItems.slice(overIndex),
    ],
  };
};

// Helper function to move a single item between containers
export const moveItemBetweenContainers = (
  tasksValue: any,
  sourceContainerId: string,
  destContainerId: string,
  activeIndex: number,
  overIndex: number
) => {
  const sourceItems = tasksValue[sourceContainerId] || [];
  const destinationItems = tasksValue[destContainerId] || [];
  const draggedItem = sourceItems[activeIndex];

  return {
    ...tasksValue,
    [sourceContainerId]: [
      ...sourceItems.slice(0, activeIndex),
      ...sourceItems.slice(activeIndex + 1),
    ],
    [destContainerId]: [
      ...destinationItems.slice(0, overIndex),
      draggedItem,
      ...destinationItems.slice(overIndex),
    ],
  };
};

// Import the Device type from your file

const generateUniqueId = (): string => {
  // Implement logic to generate a unique id, e.g., using a library or other method
  return "unique-id"; // Replace with actual implementation
};
