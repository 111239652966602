import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import tagsIcon from "assets/icons/tags.svg";
import { taskData } from "components/UI/Modals/TaskModal";
import { useState } from "react";
type Props = {
  tagsValues?: string[];
};

export default function TaskTags({ tagsValues = [] }: Props) {
  const [tagValue, setTagValue] = useState("");
  const [openInput, setOpenInput] = useState(false);
  const handleSave = () => {
    taskData.value = { ...taskData.value, tags: [...taskData.value.tags, tagValue] };
    setOpenInput(false);
    setTagValue("");
  };
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  return (
    <div className="flex items-center gap-2 flex-wrap">
      <button
        onClick={() => {
          if (!openInput) {
            setOpenInput(true);
          } else {
            handleSave();
          }
        }}
        className="w-8 h-8 flex border border-dashed border-gray-500 rounded-full relative">
        {openInput ? (
          <PlusIcon className="w-5   text-white/50 m-auto" />
        ) : (
          <img className="w-4 m-auto" src={tagsIcon} alt="Add Tags" />
        )}
      </button>
      <div
        className={`transition-all overflow-hidden flex items-center bg-white/10 rounded-md gap-2 ${
          openInput ? "w-24 p-1" : "w-0"
        }`}>
        <input
          onKeyDown={handleKeyPress}
          className={` rounded-md  bg-transparent focus-within:outline-none w-full  `}
          value={tagValue}
          onChange={(e) => {
            setTagValue(e.target.value);
          }}
        />
        <XMarkIcon
          onClick={() => {
            setOpenInput(false);
            setTagValue("");
          }}
          className="w-4 text-white flex-shrink-0"
        />
      </div>
      {taskData.value.tags.map((tagVal) => (
        <div
          key={tagVal}
          className="px-3 py-1.5 bg-gray-500 text-xs rounded-full flex items-center">
          <span className="leading-none">{tagVal}</span>{" "}
          <XMarkIcon
            onClick={() => {
              taskData.value = {
                ...taskData.value,
                tags: [...taskData.value.tags.filter((oltag) => oltag !== tagVal)],
              };
            }}
            className="w-4 text-white flex-shrink-0"
          />
        </div>
      ))}
    </div>
  );
}
