import { Cookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RELOGIN_PAGE, SIGNIN_PAGE } from "../constants/routes";
import { getStateAccessToken } from "../features/authSlice";

export default function PrivateRoutes() {
  const location = useLocation();
  const cookies = new Cookies();
  const access_token = useSelector(getStateAccessToken);
  const refresh_token = cookies.get("refresh_token");

  return access_token ? (
    <Outlet />
  ) : (
    <Navigate to={refresh_token ? RELOGIN_PAGE : SIGNIN_PAGE} replace state={{ from: location }} />
  );
}
