import { useThree } from "@react-three/fiber";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { setDots } from "../../features/areaSlice";
import { CustomPlane } from "./CustomPlane";
type Props = {
  children?: any;
};

export default function CreateArea({ children }: Props) {
  const { camera, mouse, scene, raycaster } = useThree();
  const { dots, editMode, isDotDragging } = useSelector((state: any) => state.area);
  const dispatch = useDispatch();
  const handleMouseDown = (event: any) => {
    if (editMode && !isDotDragging) {
      console.log("NEW DOT");
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children);
      //@ts-ignore
      if (intersects.length > 0 && !intersects[0]?.object?.userData?.id) {
        const intersectionPoint = intersects[0].point;

        const newDot = {
          id: uuidv4(),
          x: parseFloat(intersectionPoint.x.toFixed(6)),
          y: parseFloat(intersectionPoint.y.toFixed(6)),
          z: parseFloat(intersectionPoint.z.toFixed(6)),
        };
        dispatch(setDots([...dots, newDot]));
      }
    }
  };

  return (
    <mesh onClick={handleMouseDown}>
      {dots?.length > 0 && <CustomPlane />}
      {children}
    </mesh>
  );
}
