import { Device } from "../../types/typings";

type Props = {
  device: Device;
};

export default function DeviceStats({ device }: Props) {
  return (
    <div className=" grid grid-cols-[1fr,minmax(max-content,0.4fr)] auto-rows-max gap-4">
      <p className="text-3xl pb-2 col-span-2 ">{device.name}</p>
      <p>Category</p>
      <p className="capitalize">{device.category}</p>
      <p>Type</p>
      <p className="capitalize">{device.model}</p>
      {device?.serial_number && (
        <>
          <p>Serial Number</p>
          <p>{device.serial_number}</p>
        </>
      )}
    </div>
  );
}
