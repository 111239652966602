import { useGLTF } from "@react-three/drei";
import { dispose, useThree } from "@react-three/fiber";
import { useEffect } from "react";

type Props = {
  mapSrc: string;
};

export default function Classroom({ mapSrc }: Props) {
  // const { picked_location } = useSelector((state: any) => state.auth);
  // const currentMapSrc: string = maps?.[picked_location?.id];
  // console.log("currentMapSrc", currentMapSrc);

  return <Map url={mapSrc} />;
}
const Map = ({ url }: { url: string }) => {
  const {} = useThree();
  const { scene } = useGLTF(url);
  useEffect(() => {
    return () => {
      dispose(scene);
      useGLTF.clear(url);
    };
  }, [url, scene]);

  return <primitive object={scene} scale={[1, 0.05, 1]} position={[0, 0, 0]} />;
};
