import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useEffect, useRef, useState } from "react";

type Props = {
  initialValue: string;
  handleUpdateValue: (newVal: string) => void;
};

export default function InputQuickEdit({ initialValue, handleUpdateValue }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [editMode, setEditMode] = useState(false);
  const [inputVal, setInputVal] = useState(initialValue);
  const handleEditModeSwitch = (val: boolean) => {
    setEditMode(val);
  };
  const handleConfirmClick = async () => {
    await handleUpdateValue(inputVal);
    setEditMode(false);
  };
  useEffect(() => {
    setInputVal(initialValue);
  }, [initialValue]);
  return (
    <div className="w-full flex items-center gap-2 relative">
      {!editMode ? (
        <>
          <span className="text-2xl border-b border-b-transparent">{initialValue}</span>
          <PencilIcon
            onClick={() => {
              handleEditModeSwitch(true);
              setTimeout(() => {
                if (inputRef && inputRef?.current) {
                  inputRef.current.focus();
                }
              }, 100);
            }}
            className="w-6 h-5 text-fuchsia-200 cursor-pointer"
          />
        </>
      ) : (
        <>
          <div className="w-1/2 flex-grow border-b">
            <input
              ref={inputRef}
              className="text-2xl bg-white/0 outline-none  w-full max-w-[calc(100%-60px)] overflow-hidden overflow-ellipsis"
              value={inputVal}
              onChange={(e) => {
                setInputVal(e.target.value);
              }}
            />
          </div>
          <div className="absolute top-1/2 right-0 -translate-y-1/2 flex gap-2 w-max items-center">
            <CheckIcon
              onClick={handleConfirmClick}
              className="ml-auto w-6 h-5 text-green-300 cursor-pointer flex-shrink-0"
            />
            <XMarkIcon
              onClick={() => {
                handleEditModeSwitch(false);
              }}
              className="w-6 h-6 text-red-400 cursor-pointer flex-shrink-0"
            />
          </div>
        </>
      )}
    </div>
  );
}
