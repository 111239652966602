import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { categories } from "../../dummyData";
import { getIcon } from "../../utils/iconGetter";
import BtnOutlined from "../UI/Buttons/BtnOutlined";
import BtnPrimary from "../UI/Buttons/BtnPrimary";
import CategorySelect from "../UI/Selects/CategorySelect";

type Props = {};

export default function WidgetEdit({}: Props) {
  return (
    <div className="blurred-dark relative z-0 w-full h-1/2 flex-grow rounded-lg p-4 flex flex-col gap-4  overflow-hidden">
      <div className="header grid grid-cols-[1fr,max-content] grid-rows-2">
        <p className="font-medium">Soap Dispenser </p>
        <button className="w-7 h-7 flex row-span-2  self-center rounded-md hover:bg-white/10">
          <EllipsisVerticalIcon className="w-6 h-6 m-auto  text-white/90" />
        </button>
        <p className="text-sm">Device</p>
      </div>
      <div className="h-[200px] w-full relative">
        <img
          className="w-full h-full absolute top-1/2 left-1/2  -translate-x-1/2 -translate-y-1/2 object-contain"
          src={getIcon("soapIcon")}
          alt={"Soap Dispensers"}
        />
      </div>
      <p className="text-white/50">All soap dispensers from R-Kiosk @ Oslo Airport</p>
      <div className="w-full h-1/5 flex-grow flex-shrink-0 overflow-y-auto flex flex-col gap-3">
        <label>Someting Else</label>
        <CategorySelect category={categories[0]} setCategory={() => {}} />
        <label>Someting Else</label>
        <CategorySelect category={categories[0]} setCategory={() => {}} />
        <label>Someting Else</label>
        <CategorySelect category={categories[0]} setCategory={() => {}} />
      </div>
      <div className="footer flex items-center justify-end gap-4">
        <BtnOutlined>Edit</BtnOutlined>
        <BtnPrimary>Add Menu Element</BtnPrimary>
      </div>
    </div>
  );
}
