import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  PointerSensor,
  TouchSensor,
  defaultDropAnimation,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import ExistingWidgets from "components/AddWidgetsPage/ExistingWidgets";
import OtherWidgets from "components/AddWidgetsPage/OtherWidgets";
import { all_widgets } from "components/Widgets/widgets";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import WidgetEdit from "../components/AddWidgetsPage/WidgetEdit";
import BtnPrimary from "../components/UI/Buttons/BtnPrimary";
import StatBlock from "../components/Widgets/WidgetBlock";
import { changeWidgets } from "../features/uiSlice";
import { WidgetType } from "../types/ui-types";
import { extractDragDetails } from "../utils/helpers";

export default function AddWidgetPage() {
  const widgets: WidgetType[] = useSelector((state: any) => state.ui.widgets);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );
  const [draggedItem, setDraggedItem] = useState<WidgetType | null>(null);

  const [existingTabs, setExistingTabs] = useState<WidgetType[]>(widgets);
  const widgets_ids = [...existingTabs].map((tab) => tab.id);
  const [otherTabs, setOtherTabs] = useState<WidgetType[]>(
    all_widgets.filter((tab) => !widgets_ids.includes(tab.id))
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lists = [
    {
      id: "existing_menu_items",
      list: existingTabs,
      setState: setExistingTabs,
    },
    {
      id: "other_menu_items",
      list: otherTabs,
      setState: setOtherTabs,
    },
  ];
  const handleDragOver = (event: DragOverEvent) => {
    const { sourceContainerId, destContainerId, activeIdx, overIdx } = extractDragDetails(event);
    const source = lists.find((list) => list.id === sourceContainerId);
    const destination = lists.find((list) => list.id === destContainerId);
    if (!source || !destination || sourceContainerId === destContainerId) return;

    const updatedSourceList = [...source.list];
    const draggedItem = updatedSourceList.splice(activeIdx, 1)[0];

    destination.setState((prevList) => {
      const updatedDestList = [...prevList];
      updatedDestList.splice(overIdx, 0, draggedItem);
      return updatedDestList;
    });

    source.setState(updatedSourceList);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { sourceContainerId, destContainerId, activeIdx, overIdx } = extractDragDetails(event);
    const source = lists.find((list) => list.id === sourceContainerId);
    const destination = lists.find((list) => list.id === destContainerId);
    if (!source || !destination || sourceContainerId !== destContainerId) return;

    const updatedSourceList = [...(source?.list ?? [])];
    const updatedList = arrayMove(updatedSourceList, activeIdx, overIdx);
    source?.setState([...updatedList]);
  };
  const handleSave = () => {
    dispatch(changeWidgets(existingTabs));
    navigate("/", { replace: true });
  };

  const dropAnimation: DropAnimation = {
    ...defaultDropAnimation,
  };
  return (
    <div className="w-full h-full p-3 py-4 rounded-2xl blurred-dark  gap-x-6 grid grid-cols-[2.5fr,1fr] pointer-events-auto z-10 overflow-hidden">
      <div className="w-full h-full overflow-hidden flex flex-col gap-3">
        <DndContext
          sensors={sensors}
          onDragStart={({ active }: DragStartEvent) => {
            const item = all_widgets.find((item) => item.id === (active.id as string)) ?? null;
            setDraggedItem(item);
          }}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}>
          <p className="text-white/50 text-lg ">Widgets</p>
          <ExistingWidgets items={existingTabs} />
          <p className="text-white/50 text-lg ">Custom Widgets </p>
          <div className="">
            <OtherWidgets items={otherTabs} />
          </div>
          <DragOverlay dropAnimation={dropAnimation}>
            {draggedItem ? <StatBlock widget={draggedItem} isDragging={true} /> : null}
          </DragOverlay>
        </DndContext>
      </div>
      <div className="w-full h-full flex flex-col items-end gap-4 overflow-hidden">
        <BtnPrimary onClick={handleSave}>Save and Exit</BtnPrimary>
        <WidgetEdit />
      </div>
    </div>
  );
}
