import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
  BaseQueryApi,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/query";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useRebootDeviceMutation, useResetCountingMutation } from "../../features/api/sensorSlice";
import { showSnackBar } from "../../features/uiSlice";
import { CounterSensor } from "../../types/typings";
import { checkInstallQualityRange } from "../../utils/deviceHelpers";
import DeviceCatAndType from "./DeviceCatAndType";
import DeviceNameAndStatus from "./DeviceNameAndStatus";
import styles from "./stats.module.scss";

type Props = { device: CounterSensor };

export default function CounterStats({ device }: Props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rebootDevice] = useRebootDeviceMutation();
  const [resetDevice] = useResetCountingMutation();
  const handleAction = async (
    action: MutationTrigger<
      MutationDefinition<
        string,
        (
          args: any,
          api: BaseQueryApi,
          options: {
            [key: string]: any;
          }
        ) => Promise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>,
        string,
        any,
        "api"
      >
    >,
    actionName: string
  ) => {
    try {
      setIsLoading(true);
      const res = await action(device.id).unwrap();
      console.log("SSSs");
      dispatch(
        showSnackBar({
          type: "success",
          message: res?.message ?? `Successfully ${actionName}ed device`,
        })
      );
    } catch (err) {
      console.error(`Failed to ${actionName} the device`, err);
      dispatch(showSnackBar({ type: "error", message: `Failed to ${actionName} the device` }));
    }
    setIsLoading(false);
  };

  const handleReboot = async () => {
    await handleAction(rebootDevice, "reboot");
  };

  const handleReset = async () => {
    await handleAction(resetDevice, "reset");
  };
  return (
    <>
      <div className="pt-4 px-4 pb-4 border-b border-white/50 flex-shrink-0 overflow-y-auto">
        <div className=" grid grid-cols-[1fr,max(120px,20%)] auto-rows-max gap-3 text-sm ">
          <DeviceNameAndStatus name={device.name} status={device?.status} />
          <p className="text-white/70">Signal Quality</p>
          <p
            data-quality={checkInstallQualityRange(device.diagnostics.installation_quality)}
            className={styles.signalQuality}></p>
          <p className="text-white/70">Battery Level</p>
          <p className="capitalize">
            {device.statistics?.battery?.voltage
              ? (device.statistics?.battery?.voltage / 1000).toFixed(2) + "V"
              : "Wired"}
          </p>
          <DeviceCatAndType category={device.category} model={device?.__model} />
          {device?.serial_number && (
            <>
              <p className="text-white/70">Serial Number</p>
              <p>{device.serial_number}</p>
            </>
          )}
        </div>
      </div>
      <div className="pt-4 flex flex-col h-1/3 flex-grow overflow-hidden relative z-30 p-4">
        <div className="h-1/2 flex-grow overflow-y-auto">
          <div className="grid grid-cols-[1fr,max(120px,20%)] auto-rows-max gap-3 text-sm">
            <p className="text-base pb-2 col-span-2">Occupacy</p>

            <p className="text-white/70">Entries</p>
            <p>{device.statistics.entries}</p>
            <p className="text-white/70">Exits</p>
            <p>{device.statistics.exits}</p>
          </div>
        </div>

        <div className="grid grid-cols-2 h-max flex-shrink-0 z-10 relative gap-4 mt-auto pt-2 ">
          <button
            disabled={isLoading}
            onClick={handleReset}
            className="blurred-light select rounded-xl py-2.5 px-3 w-full disabled:cursor-not-allowed hover:bg-black/10 relative">
            {isLoading ? <ArrowPathIcon className="w-6 animate-spin mx-auto" /> : " Reset Device"}
          </button>
          {/* <button
            disabled={isLoading}
            onClick={handleReboot}
            className="blurred-light select rounded-xl py-2.5 px-3 w-full disabled:cursor-progress hover:bg-black/10">
            {isLoading ? <ArrowPathIcon className="w-6 animate-spin mx-auto" /> : "Reboot Device"}
          </button> */}
        </div>
      </div>
    </>
  );
}
