import { Key, ReactElement, useRef, useState } from "react";
import { Option } from "types/ui-types";
import useCloseOnOutsideClick from "../../../hooks/useOutsideClick";

import { usePopper } from "react-popper";
type Props = {
  children: ReactElement[] | ReactElement;
  options: Option[];
  onSelect: (option: Option) => void;
  toggleMenu: (val: boolean) => void;
  direction?: string;
};

export default function Select({ children, options, onSelect, toggleMenu = () => {} }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const referenceElement = useRef<HTMLButtonElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update } = usePopper(referenceElement.current, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
    placement: "bottom-start",
    strategy: "fixed",
  });

  useCloseOnOutsideClick(dropdownRef, isOpen, (val) => {
    setIsOpen(val);
    toggleMenu(val as boolean);
  });
  const toggleDropdown = () => {
    //@ts-ignore
    update();
    setIsOpen(!isOpen);
    toggleMenu(!isOpen);
  };
  const handleOptionClick = (option: Option) => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className="w-full relative" ref={dropdownRef}>
      <button ref={referenceElement} className="w-full" onClick={toggleDropdown}>
        {children}
      </button>

      <div
        ref={setPopperElement}
        style={{ ...styles.popper, width: referenceElement?.current?.clientWidth }}
        {...attributes.popper}
        className={` absolute z-10   transition-opacity min-w-max  ${
          isOpen ? "" : " pointer-events-none opacity-0"
        }`}>
        <ul className=" bg-gray-500  rounded-2xl max-h-[220px] overflow-y-auto ">
          {options.map((option) => (
            <li
              className="py-2.5 px-2.5  cursor-pointer hover:bg-white/5"
              key={option?.value as Key}
              onClick={() => {
                handleOptionClick(option);
              }}>
              {option.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
