import { createSlice } from "@reduxjs/toolkit";
import { Cookies } from "react-cookie";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    access_token: null,
    user: null,
    user_locations: [],
    picked_location: localStorage.getItem("picked_location")
      ? JSON.parse(localStorage.getItem("picked_location")!)
      : null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const { user, access_token } = action.payload || {};
      return { ...state, user, access_token };
    },
    logOut: (state) => {
      const cookies = new Cookies();
      cookies.remove("refresh_token");
      localStorage.removeItem("picked_location");
      return {
        ...state,
        user: null,
        access_token: null,
        user_locations: [],
        picked_location: null,
      };
    },
    setLocations: (state, action) => {
      const { location_list, picked_location } = action.payload;
      localStorage.setItem("picked_location", JSON.stringify(picked_location));
      return {
        ...state,
        user_locations: location_list,
        ...(!state.picked_location ? { picked_location } : {}),
      };
    },
    setLocation: (state, action) => {
      localStorage.setItem("picked_location", JSON.stringify(action.payload));
      return {
        ...state,
        picked_location: action.payload,
      };
    },
  },
});

export const { setCredentials, logOut, setLocations, setLocation } = authSlice.actions;
export const getStateCurrentUser = (state: any) => state.auth.user;
export const getStateAccessToken = (state: any) => state.auth.access_token;
export const getStateLocationID = (state: any) => state.auth.picked_location?.id ?? null;
