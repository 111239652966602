import { API } from "constants/api";
import { Notification } from "../../types/typings";
import { apiSlice } from "./apiSlice";
const { NOTIFICATION } = API;
export const notificationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: () => "/notifications",
      providesTags: (result, error, arg) => {
        if (Array.isArray(result)) {
          return [
            { type: NOTIFICATION, id: "list" },
            ...result.map((r: Notification) => ({ type: NOTIFICATION, id: r.id })),
          ];
        }
        return [{ type: NOTIFICATION, id: "list" }];
      },
    }),

    updateNotification: builder.mutation({
      query: (initialDevice: Notification) => ({
        url: `/notifications/${initialDevice.id}`,
        method: "PUT",
        body: {
          ...initialDevice,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: NOTIFICATION, id: "list" },
        { type: NOTIFICATION, id: result.id },
      ],
    }),
  }),
});

export const { useGetNotificationsQuery, useUpdateNotificationMutation } = notificationsApiSlice;
