import expandIcon from "assets/icons/expand-more.svg";
import { task_statuses } from "dummyData";
import { useState } from "react";
import { Option } from "types/ui-types";
import Dropdown from "./SelectTemplate";

type Props = {
  task_status: Option;
  setTaskStatus: (option: Option) => void;
};
export default function TaskStatusSelect({ task_status, setTaskStatus }: Props) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="">
      <Dropdown
        options={task_statuses}
        onSelect={(cat) => {
          setTaskStatus(cat);
          setExpanded(false);
        }}
        toggleMenu={(val) => {
          setExpanded(val);
        }}>
        <div className="blurred-light select rounded-full w-full flex items-center justify-between gap-2 py-2 px-4 ">
          <span className={`whitespace-nowrap overflow-ellipsis overflow-hidden inline-block`}>
            {task_status.label}
          </span>
          <img
            className={`w-6 h-6 flex-shrink-0 transition-transform duration-500 ${
              expanded ? "rotate-180" : ""
            }`}
            src={expandIcon}
            alt="Expand"
          />
        </div>
      </Dropdown>
    </div>
  );
}
