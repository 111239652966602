import { device_statuses } from "../../dummyData";
type Props = {
  name: string;
  status: string;
};

export default function DeviceNameAndStatus({ name, status }: Props) {
  const current_status =
    device_statuses.find((option) => option.value === status) || device_statuses[0];
  return (
    <>
      <p className="text-3xl pb-2  ">{name}</p>
      <div
        className={` rounded-full h-10 text-sm px-2.5 py-0.5 flex items-center w-full ${
          current_status.value === "active"
            ? "bg-lime-400/25 text-lime-400"
            : "bg-red-500/25 text-red-400"
        }`}>
        {current_status.label}
        <div
          className={`w-1.5 h-1.5 ml-auto rounded-full ${
            current_status.value === "active" ? "bg-lime-400" : "bg-red-400"
          }`}></div>
      </div>
    </>
  );
}
