import { Cookies } from "react-cookie";
import { API } from "../../constants/api";
import { apiSlice } from "./apiSlice";
const { ALERT, TASK, NOTIFICATION, DEVICE, USER } = API;
export const apiAuthSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        "Access-Control-Allow-Origin": "*",
        body: { ...credentials },
      }),
    }),
    refreshUserToken: builder.mutation({
      query: () => {
        const cookies = new Cookies();
        return {
          url: "/refresh",
          method: "POST",
          headers: {
            Authorization: `Bearer ${cookies.get("refresh_token")}`,
          },
          "Access-Control-Allow-Origin": "*",
        };
      },
    }),
  }),
});

export const { useLoginUserMutation, useRefreshUserTokenMutation } = apiAuthSlice;
