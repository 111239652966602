import { Key, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Option } from "types/ui-types";
import { useGetLocationsQuery } from "../../../features/api/locationSlice";
import { getStateAccessToken, setLocation } from "../../../features/authSlice";
import { Organization } from "../../../types/typings";
import Dropdown from "./DropdownTemplate";

type Props = {
  category?: Option;
  setCategory?: React.Dispatch<React.SetStateAction<Option>>;
};
function TriggerButton() {
  return (
    <button className=" p-4 w-max  gap-2 flex justify-center items-center transition-all  rounded-2xl blurred-light hover:bg-white/10 group">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 4L5 7L12 10L19 7L12 4Z"
          stroke="#E8DEF8"
          strokeWidth="1.2"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.54899 9.82629L4.76404 11.4484C4.54343 11.543 4.40039 11.7599 4.40039 11.9999C4.40039 12.2399 4.54343 12.4568 4.76404 12.5514L11.764 15.5514C11.915 15.6161 12.0858 15.6161 12.2367 15.5514L19.2367 12.5514C19.4574 12.4568 19.6004 12.2399 19.6004 11.9999C19.6004 11.7599 19.4574 11.543 19.2367 11.4484L15.4518 9.82629L13.9286 10.4791L17.4772 11.9999L12.0004 14.3471L6.52355 11.9999L10.0721 10.4791L8.54899 9.82629ZM11.5953 8.52073L11.764 8.44842C11.915 8.38373 12.0858 8.38373 12.2367 8.44842L12.4055 8.52073L12.0004 8.69434L11.5953 8.52073Z"
          fill="#E8DEF8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.54899 14.8263L4.76404 16.4484C4.54343 16.543 4.40039 16.7599 4.40039 16.9999C4.40039 17.2399 4.54343 17.4568 4.76404 17.5514L11.764 20.5514C11.915 20.6161 12.0858 20.6161 12.2367 20.5514L19.2367 17.5514C19.4574 17.4568 19.6004 17.2399 19.6004 16.9999C19.6004 16.7599 19.4574 16.543 19.2367 16.4484L15.4518 14.8263L13.9286 15.4791L17.4772 16.9999L12.0004 19.3471L6.52355 16.9999L10.0721 15.4791L8.54899 14.8263ZM11.5953 13.5207L11.764 13.4484C11.915 13.3837 12.0858 13.3837 12.2367 13.4484L12.4055 13.5207L12.0004 13.6943L11.5953 13.5207Z"
          fill="#E8DEF8"
        />
      </svg>
      <span>Locations</span>
    </button>
  );
}
export default function LocationsDropdown({ category, setCategory }: Props) {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const access_token = useSelector(getStateAccessToken);
  const { isLoading, isError, data: locations } = useGetLocationsQuery(access_token);
  const { picked_location } = useSelector((state: any) => state.auth);
  return (
    <div className="w-max">
      <Dropdown
        collapse={!expanded}
        toggleMenu={(val: boolean) => {
          setExpanded(val);
        }}
        triggerEl={<TriggerButton />}
        direction="up">
        <div className="flex flex-col">
          {!isLoading &&
            !isError &&
            locations.map((option: Organization) => (
              <button
                className="py-2.5 px-2.5 cursor-pointer hover:bg-white/5"
                key={option?.id as Key}
                onClick={() => {
                  dispatch(setLocation(option));
                }}>
                {option.name}
              </button>
            ))}
        </div>
      </Dropdown>
    </div>
  );
}
