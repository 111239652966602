import { SETTINGS_USERS_PAGE } from "../../constants/routes";
import { users } from "../../dummyData";

export type SettingsTabType = {
  label: string;
  to: string;
  count?: number;
  icon: string;
};
type SettingsCategoryType = {
  label: string;
  type: string;
  items: SettingsTabType[];
};
export const settings: SettingsCategoryType[] = [
  {
    label: "General",
    type: "general",
    items: [
      { label: "Users", to: SETTINGS_USERS_PAGE, count: users?.length, icon: "circle" },
      // { label: "Devices", to: SETTINGS_DEVICES_PAGE, count: users?.length, icon: "triangle" },
      // { label: "Locations", to: "/settings/locations", count: users?.length, icon: "square" },
      // { label: "Groups", to: "/settings/groups", count: users?.length, icon: "polygon" },
    ],
  },
];
